import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { addSeconds, format } from 'date-fns';
import useSWR from 'swr';
import { apiPrisma } from '@config/api';

/**
 * Guarda las tasas de cambio.
 * @param {boolean} checked Indica si la tasa de cambio se ha programado para una fecha futura.
 * @param {string} scheduledDay La fecha en formato ISO 8601 si la tasa de cambio está programada para una fecha futura.
 * @param {number} exchangeValue El valor de la tasa de cambio.
 * @returns {Promise<void>} Una promesa que se resuelve cuando la tasa de cambio se ha guardado correctamente.
 */
function useSaveExchangeRates() {
  const [updateData, setUpdateData] = useState(false);
  const [exchangeValue, setExchangeValue] = useState(null);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [checked, setChecked] = useState(false);
  const [scheduledDay, setScheduledDay] = useState(null);
  const [openAlert, setOpenAlert] = useState({
    show: false,
    message: '',
    status: '',
    title: '',
  });
  const { t } = useTranslation();

  /**
   */
  const saveExchangeRates = async () => {
    const parseDate = "yyyy-MM-dd'T'HH:mm:ss-03"; //eslint-disable-line quotes
    const currentDate = addSeconds(new Date(), 3);
    const exchangeDate = checked ? format(scheduledDay, parseDate) : format(currentDate, parseDate);

    let data = JSON.stringify({
      base: 'USD',
      target: 'ARS',
      rate: exchangeValue,
      starts: exchangeDate,
    });

    const url = '/prisma-proxy/exchange-rate';
    try {
      await apiPrisma.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setUpdateData(true);
      setOpenAlert({
        show: true,
        message: checked ? t('EXCHANGE:SAVE_MESSAGE_SCHEDULED') : t('EXCHANGE:SAVE_MESSAGE'),
        status: 'success',
        title: t('EXCHANGE:SAVE_TITLE'),
      });
    } catch (error) {
      console.log('ERROR', error);
      setUpdateData(false);
      setOpenAlert({
        show: true,
        message: `${error.code} ${t('EXCHANGE:ERRORS:MESSAGE_POST')}`,
        status: 'error',
        title: t('EXCHANGE:ERRORS:TITLE_POST'),
      });
    } finally {
      setTimeout(() => setUpdateData(false), 5001);
    }
  };

  /**
   * Maneja el cambio del valor del dólar en pesos argentinos.
   * @description
   * @param {Event} e
   */
  const handleChange = (e) => {
    setExchangeValue(e.target.value);
  };

  /**
   * Maneja el cambio del estado del checkbox del calendario.
   * @description
   * @param {Event} event
   */
  const handleChangeCalendar = (event) => {
    setChecked(event.target.checked);
  };
  /**
   * Valida si el botón de envío está deshabilitado.
   * @description Valida si el botón de envío está deshabilitado.
   * @returns {boolean} `true` si el botón está deshabilitado, `false` si no lo está.
   */
  const isValidateSubmit = () => {
    if (!checked && exchangeValue) return false;
    if (checked && exchangeValue && scheduledDay) return false;
    return true;
  };

  /**
   * Cierra el alerta.
   *
   * @param {Event} event El evento que se ha disparado.
   * @param {string} reason La razón por la que se ha cerrado el alerta.
   */
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert({
      show: false,
      message: '',
      status: '',
      title: '',
    });
  };

  /**
   */
  const fetcher = () => {
    return apiPrisma.get('/prisma-proxy/exchange-rate/?size=10&page=1', {
      headers: {
        Accept: '*/*',
      },
    });
  };

  const { data } = useSWR('/prisma-proxy/exchange-rate/?size=10&page=1', fetcher, {
    shouldRetryOnError: true,
    onError: (error) => {
      console.log('ERROR', error);
      setOpenAlert({
        show: true,
        message: `${error.code} ${t('EXCHANGE:ERRORS:MESSAGE_GET')}`,
        status: 'error',
        title: t('EXCHANGE:ERRORS:TITLE_GET'),
      });
    },
    refreshInterval: updateData ? 4000 : null,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
  });

  let rates = data?.data.rates ? data.data.rates : undefined;
  useEffect(() => {
    if (rates) setExchangeRate(rates);
  }, [rates, exchangeRate, setExchangeRate]);

  return {
    exchangeRate,
    checked,
    scheduledDay,
    setScheduledDay,
    openAlert,
    saveExchangeRates,
    handleChange,
    handleChangeCalendar,
    isValidateSubmit,
    handleCloseAlert,
  };
}

export default useSaveExchangeRates;
