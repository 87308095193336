import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  AlertTitle,
  Typography,
  Grid,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Snackbar,
  Select,
  Modal,
  Paper,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import { IconSearch, IconUserPlus } from '@tabler/icons-react';
import { Button, Input, IconButton as CustomIconButton } from '@atoms';
import { useCommerces, useURLparams } from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { Table, Toolbar } from '@molecules';
import Rubros from './rubros';
import styles from './styles';

/**
 * Commerce screen
 */
const CommercesAdminScreen = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  const { params, setURLparams } = useURLparams();
  const { data, deleteCommerce, createCommerce, isLoading, isValidating, mutate } = useCommerces({
    params,
    setURLparams,
    onError: handlerSearchError,
  });

  const [deleteCommerceModalOpen, setDeleteCommerceModalOpen] = useState({
    show: false,
    data: [],
  });
  const [openAlert, setOpenAlert] = useState({
    show: false,
    message: '',
    status: '',
    title: '',
  });

  const newCommerceInitialValues = {
    commerceName: '',
    mcc: '',
    categoryName: '',
  };

  const [newCommerce, setNewCommerce] = useState(newCommerceInitialValues);

  /**
   * Handles search errors
   */
  function handlerSearchError(error) {
    if (error) {
      setOpenAlert({
        show: true,
        message: 'COMMERCES:ERRORS:INVALID_SEARCH',
        status: 'error',
        title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
      });
    }
  }
  /**
   * Handles the input change event.
   * @param {SyntheticEvent} event
   * @returns {void}
   */
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  /**
   * Handles the input clear.
   */
  const handleInputClear = () => {
    setSearchTerm('');
  };
  /**
   * Handles the search event.
   * @param {string} searchTerm
   * @returns {void}
   */
  const handleSearch = () => {
    if (params.commerceName !== searchTerm) {
      setURLparams({ commerceName: searchTerm });
    } else {
      mutate();
    }
  };

  /**
   * Handles the delete button click event.
   * @param {object} result
   * @returns {void}
   */
  const handleDeleteCommerce = (result) => {
    setDeleteCommerceModalOpen({
      show: true,
      data: result,
    });
  };

  /**
   * Handles the add commerce button click event.
   * @returns {void}
   */
  const handleAddCommerce = async () => {
    let stateReq = true;
    if (
      newCommerce.categoryName.length > 0 &&
      newCommerce.mcc.length > 3 &&
      newCommerce.commerceName.length > 0
    ) {
      try {
        await createCommerce(newCommerce);
      } catch (error) {
        stateReq = false;
      }
      setModalOpen(false);
      setOpenAlert({
        show: true,
        message: stateReq ? 'COMMERCES:COMMERCE_SAVED_MESSAGE' : 'COMMERCES:ERRORS:UNABLE_TO_SAVE',
        status: stateReq ? 'success' : 'error',
        title: stateReq ? 'COMMERCES:COMMERCE_SAVED_TITLE' : 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
      });
      if (stateReq) {
        setURLparams({ commerceName: newCommerce.commerceName });
      }
      setNewCommerce([]);
    } else {
      setOpenAlert({
        show: true,
        message: 'COMMERCES:ERRORS:REQUIRED_FIELDS_TO_SAVE',
        status: 'error',
        title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
      });
    }
  };

  /**
   * Handles the add commerce button click event.
   * @returns {void}
   */
  const handleClose = () => {
    setNewCommerce(newCommerceInitialValues);
    setModalOpen(false);
  };

  /**
   * Handles the add commerce button click event.
   * @returns {void}
   */
  const handleDeleteConfirmCommerce = async (data) => {
    let stateReq = true;
    try {
      await deleteCommerce(data._id);
    } catch (error) {
      stateReq = false;
    }
    setDeleteCommerceModalOpen({
      show: false,
      data: [],
    });
    setOpenAlert({
      show: true,
      message: stateReq
        ? 'COMMERCES:COMMERCE_DELETED_MESSAGE'
        : 'COMMERCES:ERRORS:UNABLE_TO_DELETE',
      status: stateReq ? 'success' : 'error',
      title: stateReq ? 'COMMERCES:COMMERCE_DELETED_TITLE' : 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
    });
    setURLparams({});
    setSearchTerm('');
  };

  /**
   * Handles the add commerce button click event.
   * @returns {void}
   */
  const handleDeleteCancelCommerce = () => {
    setDeleteCommerceModalOpen({
      show: false,
      data: [],
    });
  };

  /**
   * Closes the alert
   * @param {Event} event The triggered event
   * @param {string} reason The reason why the alert is being closed.
   */
  const handleCloseAlert = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert({
      show: false,
      message: '',
      status: '',
      title: '',
    });
  };

  const resultsLoading = isLoading || isValidating;
  const buttonDisabled = resultsLoading || searchTerm.length === 0;

  const COLUMNS = [
    {
      title: t('COMMERCES:NAME'),
      content: (commerce) => {
        const isMaxCharacter = commerce.commerceName > 25;
        return (
          <Tooltip title={isMaxCharacter && commerce.commerceName} arrow>
            <Box sx={[styles.rowEllipsis, styles.inputMaxWidth]}>{commerce.commerceName}</Box>
          </Tooltip>
        );
      },
    },
    {
      title: t('COMMERCES:MCC'),
      content: (commerce) => <Box>{commerce.mcc}</Box>,
    },
    {
      title: t('COMMERCES:BENEFIT'),
      content: (commerce) => {
        const isMaxCharacter = commerce.categoryName > 25;
        return (
          <Tooltip title={isMaxCharacter && commerce.categoryName} arrow>
            <Box sx={[styles.rowEllipsis, styles.inputMaxWidth]}>{commerce.categoryName}</Box>
          </Tooltip>
        );
      },
    },
    {
      content: (result) => (
        <Box style={styles.deleteContent}>
          <IconButton
            aria-label={t('COMMERCES:DELETE_COMMERCE')}
            onClick={() => handleDeleteCommerce(result)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const addCommerce = [
    {
      onClick: () => setModalOpen(true),
      text: t('COMMERCES:ADD_COMMERCE'),
      icon: <IconUserPlus />,
    },
  ];

  return (
    <MainMenuLayout component='main'>
      <FullWidthContent>
        <Box sx={styles.searchContainer}>
          <Box sx={styles.header}>
            <Typography variant='h4'>{t('COMMERCES:SECTION_TITLE')}</Typography>
          </Box>
          <Box style={styles.content}>
            <TextField
              label={t('COMMERCES:NAME')}
              id='searchInput'
              value={searchTerm}
              onChange={handleInputChange}
              variant='outlined'
              fullWidth
            />
            <Box style={styles.actionsContainer}>
              <Button
                customStyle={styles.clearButton}
                text={t('COMMON:CLEAR_FILTER')}
                color='primary'
                variant='link'
                onClick={handleInputClear}
              />
              <CustomIconButton
                aria-label={t('COMMON:SEARCH')}
                tooltip='COMMON:SEARCH'
                onClick={handleSearch}
                IconComponent={IconSearch}
                isLoading={resultsLoading}
                disabled={buttonDisabled}
                variant='contained'
              />
            </Box>
          </Box>
        </Box>
        <Toolbar buttons={addCommerce} />
        <Table items={data} columns={COLUMNS} isLoading={isLoading} />
        <Modal open={modalOpen}>
          <Paper sx={styles.modalStyle}>
            <Stack spacing={2}>
              <Typography variant='h5'>{t('COMMERCES:ADD_COMMERCE')}</Typography>
              <Stack spacing={2}>
                <Input
                  label={t('COMMERCES:NAME')}
                  id='commerceName'
                  value={newCommerce.commerceName}
                  onChange={(e) =>
                    setNewCommerce({
                      ...newCommerce,
                      commerceName: e.target?.value.toLocaleUpperCase(),
                    })
                  }
                />
                <Input
                  label={t('COMMERCES:MCC')}
                  id='mcc'
                  inputProps={{ maxLength: 4 }}
                  value={newCommerce.mcc}
                  onChange={(e) =>
                    setNewCommerce({
                      ...newCommerce,
                      mcc: e.target?.value.replace(/[^0-9]/g, ''),
                    })
                  }
                />
                <FormControl fullWidth>
                  <InputLabel id='categoryName'>{t('COMMERCES:BENEFIT')}</InputLabel>
                  <Select
                    labelId='categoryName'
                    id='categoryName-select'
                    value={newCommerce.categoryName}
                    label={t('COMMERCES:BENEFIT')}
                    onChange={(e) =>
                      setNewCommerce({
                        ...newCommerce,
                        categoryName: e.target?.value,
                      })
                    }
                  >
                    {Rubros.rubros &&
                      Rubros.rubros.map((e, key) => {
                        return (
                          <MenuItem key={key} value={e.Value}>
                            {e.Value}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Stack>
              <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                <Button
                  variant='outlined'
                  text='Cancelar'
                  onClick={handleClose}
                  customStyle={styles.buttonModalCancel}
                ></Button>
                <Button variant='contained' text='Guardar' onClick={handleAddCommerce}></Button>
              </Grid>
            </Stack>
          </Paper>
        </Modal>
        <Modal open={deleteCommerceModalOpen.show}>
          <Paper sx={styles.modalStyle}>
            <Stack spacing={2}>
              <Typography variant='h5'>{t('COMMON:ARE_YOU_SURE')}</Typography>
              <Stack spacing={2}>
                <Typography>
                  {t('COMMERCES:DELETE_WARNING')}
                  <strong> {deleteCommerceModalOpen?.data?.commerceName}</strong>.
                </Typography>
              </Stack>
              <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                <Button
                  variant='outlined'
                  onClick={handleDeleteCancelCommerce}
                  customStyle={styles.buttonModalCancel}
                  text={t('COMMON:CANCEL')}
                ></Button>
                <Button
                  variant='contained'
                  onClick={() => handleDeleteConfirmCommerce(deleteCommerceModalOpen.data)}
                  text={t('COMMON:DELETE')}
                />
              </Grid>
            </Stack>
          </Paper>
        </Modal>
        {openAlert && openAlert.show && (
          <Snackbar open={true} autoHideDuration={10000} onClose={handleCloseAlert}>
            <Alert severity={openAlert.status} sx={{ width: '100%' }} onClose={handleCloseAlert}>
              <AlertTitle>{t(openAlert.title)}</AlertTitle>
              {t(openAlert.message)}
            </Alert>
          </Snackbar>
        )}
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default CommercesAdminScreen;
