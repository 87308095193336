import PropTypes from 'prop-types';
import { Alert, AlertColor, Snackbar as MaterialSnackbar } from '@mui/material';

interface SnackbarProps {
  open: boolean;
  message: string;
  handleClose: () => void;
  severity?: AlertColor;
}

/**
 * @param  open If the snackbar is should be displayed
 * @param  handleClose Function that snackbar
 * @param  message The message of the snackbar
 * @param  severity The severity of the snackbar. Defaults error
 * @returns Snackbar
 */
const Snackbar = ({ open, handleClose, message, severity = 'error' }: SnackbarProps) => {
  return (
    <MaterialSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert elevation={6} variant='filled' onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </MaterialSnackbar>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  severity: PropTypes.string,
};

export default Snackbar;
