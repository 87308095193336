import i18n from '@i18n';
import { parseAmount } from '@utils';
import { VALIDATE_AMOUNT, VALIDATE_OBJECT } from '@validations';

export const SNACKBAR_DURATION = 2000;

/**
 * Dynamicaly generates a validation schema limiting the max amount to total balance and omits validations if its a full recovery
 * @param {number} options.totalBalance The total balance of the account
 * @param {boolean} options.fullRecovery The total balance of the account
 *
 */
export const BALANCE_RETRIEVE_VALIDATION = ({
  totalBalance,
  fullRecovery,
}: {
  totalBalance: number;
  fullRecovery: boolean;
}) => {
  if (fullRecovery) return;

  const helperText = i18n.t('EMPLOYEES:DETAIL:VALIDATIONS:AMOUNT_EXCEEDS_TOTAL_BALANCE', {
    totalBalance: parseAmount(totalBalance),
  });

  return VALIDATE_OBJECT({
    amount: VALIDATE_AMOUNT.max(totalBalance, helperText),
  });
};
