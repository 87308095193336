import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Alert, Typography, useTheme } from '@mui/material';
import { IconFileDescription } from '@tabler/icons-react';
import { Button } from '@atoms';
import { DEFAULT_ACCEPTED_FILES_CSV } from '@globalConstants';
import styles from './styles';

/**
 * render a drag & drop component
 */
const Dropzone = (props) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const {
    files,
    setFiles,
    totalFiles = 1,
    acceptedFilesTypes = DEFAULT_ACCEPTED_FILES_CSV,
    dragText = 'COMMON:FILE_UPLOADS:DRAG_AND_DROP',
    text = 'COMMON:FILE_UPLOADS:DROPZONE_TEXT',
    buttonText = 'COMMON:FILE_UPLOADS:BUTTON',
    handleFilesSelection,
    disabled = false,
  } = props;

  const {
    fileRejections,
    isDragActive,
    getRootProps,
    getInputProps,
    open: openSelectFile,
  } = useDropzone({
    maxFiles: totalFiles,
    accept: acceptedFilesTypes,
    onDrop: handleFilesSelection,
    disabled,
    noClick: true,
  });

  /**
   * @returns Selected file info
   */
  const getSelectionInfo = () => (
    <Box sx={styles.selectionInfo}>
      <IconFileDescription color={palette.primary.light} size='1.5rem' />
      <Typography variant='body2' color='gray.80'>
        {files[0].path}
      </Typography>
    </Box>
  );

  const hasSelectedFile = files.length > 0;

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div key={file.path}>
        {errors.map((e) => (
          <>
            <br />
            <Alert key={e.code} severity='error'>
              {file.path} <strong>{e.message}</strong>
            </Alert>
          </>
        ))}
      </div>
    );
  });
  return (
    <>
      <Box {...getRootProps({ className: 'dropzone' })} sx={styles.dropContainer}>
        <input {...getInputProps()} name='file' />
        {isDragActive ? (
          <Typography>{t(dragText)}</Typography>
        ) : hasSelectedFile ? (
          <Typography>{getSelectionInfo()}</Typography>
        ) : (
          <Typography textAlign='center' variant='body' color='gray.90'>
            {t(text)}
            <br />o
          </Typography>
        )}
        <Box sx={styles.selectionButtonBox}>
          {hasSelectedFile ? (
            <Button
              onClick={() => setFiles([])}
              text='COMMON:CLEAR'
              variant='outlined'
              color='primary'
            />
          ) : (
            <Button
              onClick={openSelectFile}
              text={t(buttonText)}
              disabled={disabled}
              variant='outlined'
              color='primary'
            />
          )}
        </Box>
        <aside>{fileRejectionItems}</aside>
      </Box>
    </>
  );
};

Dropzone.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  open: PropTypes.bool,
  totalFiles: PropTypes.number,
  dragText: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  error: PropTypes.object,
  acceptedFilesTypes: PropTypes.object,
  handleFilesSelection: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Dropzone;
