const styles = {
  icon: (color) => ({
    fontSize: '1.25rem',
    color: ({ palette }) => color(palette),
    borderRadius: '4px',
    height: 'auto',
  }),
  iconBox: (iconBoxColor) => ({
    display: 'flex',
    padding: '0.25rem',
    backgroundColor: ({ palette }) => iconBoxColor(palette),
    borderRadius: '0.25rem',
    height: 'auto',
    alignItems: 'flex-start',
  }),
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
    paddingTop: '4px',
  },
  paper: (widePaper) => ({
    display: 'flex',
    flexDirection: 'row',
    ...(widePaper && { width: '42.5rem' }),
  }),
  details: {
    paddingTop: '1rem',
  },
};

export default styles;
