import PropTypes from 'prop-types';
/**
 * @returns VisaLogo component
 */
const VisaLogo = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={40} height={13} fill='none' color={color}>
    <path
      fill={color}
      d='M29.076 1.242A8.31 8.31 0 0 0 26.174.74c-3.2 0-5.46 1.612-5.476 3.926-.02 1.7 1.606 2.658 2.836 3.226 1.262.584 1.684.952 1.68 1.474-.008.794-1.008 1.154-1.938 1.154-1.278 0-1.976-.178-3.05-.624l-.398-.186-.454 2.664c.778.324 2.18.602 3.628.626 3.402 0 5.626-1.602 5.652-4.064.028-1.358-.852-2.384-2.704-3.232-1.126-.55-1.824-.918-1.824-1.476 0-.494.598-1.022 1.848-1.022a5.9 5.9 0 0 1 2.426.458l.3.134.454-2.574-.078.018ZM37.38.956h-2.5c-.778 0-1.364.214-1.704.986l-4.808 10.892h3.402l.68-1.786 4.152.004c.098.418.398 1.782.398 1.782h3L37.38.956Zm-21.284-.1h3.242L17.31 12.74h-3.238L16.096.852v.004Zm-8.23 6.55.336 1.65 3.168-8.1h3.434L9.702 12.818H6.276l-2.8-10.044a.678.678 0 0 0-.298-.398A13.896 13.896 0 0 0-.006 1.198l.044-.25h5.218c.708.028 1.278.25 1.468 1.006l1.14 5.458.002-.006ZM33.38 8.618l1.292-3.324c-.016.036.266-.686.43-1.132l.222 1.026.75 3.428h-2.696l.002.002Z'
    />
  </svg>
);

VisaLogo.propTypes = {
  color: PropTypes.string,
};
export default VisaLogo;
