import { DEFAULT_FORMAT } from './constants';

/**
 * Date formatter (Ej:  27 de mar de 2024, 12:40:00)
 * @param date
 */
const formatDateV2 = (date: Date, options: Intl.DateTimeFormatOptions = DEFAULT_FORMAT) => {
  const formatter = new Intl.DateTimeFormat('es-AR', options);

  return formatter.format(date);
};

export default formatDateV2;
