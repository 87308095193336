import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import { DatePicker, DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import { IconCalendarTime } from '@tabler/icons-react';
import { formatDate } from '@utils';
import styles from './styles';

/**
 * Renders a Material UI date & time picker.
 * @param {object} props
 * @param {string[]} [props.calendarViews=['year', 'month', 'day', 'hours', 'minutes']] - Array of calendar views to show.
 * @param {string} [props.inputLabel=''] - Label for the input.
 * @param {boolean} [props.hideToolbar=false] - Whether to hide the toolbar.
 * @param {boolean} [props.amPmFormat=true] - Whether to use AM/PM format.
 * @param {Date} [props.minDate] - Minimum selectable date.
 * @param {Date} [props.maxDate] - Maximum selectable date.
 * @param {Date} [props.minDateTime] - Minimum selectable date and time.
 * @param {boolean} [props.disablePast=true] - Whether to disable past dates.
 * @param {any} props.value - The selected date/time value.
 * @param {function} props.handleChange - Function to handle the date/time change.
 * @param {boolean} [props.dateOnly=false] - Whether to show only the date picker.
 * @param {boolean} [props.fullWidth=false] - Whether to make the input full width.
 * @param {string} [props.placeholder='COMMON:SELECT_DATE_PLACEHOLDER'] - Placeholder text for the input.
 * @param {string} [props.name='dateTimePicker'] - The name of the input.
 * @param {boolean} [props.error=false] - Whether to apply error styles.
 * @param {string} [props.helperText=''] - Helper text displayed below the input.
 * @returns {JSX.Element} - Rendered date/time picker component.
 */
const DateTimePicker = ({
  calendarViews = ['year', 'month', 'day', 'hours', 'minutes'],
  inputLabel = '',
  hideToolbar = false,
  amPmFormat = true,
  minDate = {},
  maxDate = {},
  minDateTime = {},
  disablePast = true,
  value,
  handleChange,
  dateOnly = false,
  fullWidth = false,
  placeholder = 'COMMON:SELECT_DATE_PLACEHOLDER',
  name = 'dateTimePicker',
  error = false,
  helperText = '',
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();
  const { palette } = useTheme();

  const components = {
    DatePicker,
    MuiDateTimePicker,
  };

  const Picker = components[dateOnly ? 'DatePicker' : 'MuiDateTimePicker'];

  return (
    <Picker
      name={name}
      open={open}
      onClose={() => setOpen(false)}
      views={calendarViews}
      label={t(inputLabel)}
      ampm={amPmFormat}
      ampmInClock={true}
      showToolbar={!hideToolbar}
      minDate={minDate}
      maxDate={maxDate}
      minDateTime={minDateTime}
      value={value}
      components={{ OpenPickerIcon: IconCalendarTime }}
      PopperProps={{ anchorEl }}
      disablePast={disablePast}
      onChange={handleChange}
      InputAdornmentProps={{ position: 'start' }}
      {...(dateOnly && { inputFormat: 'dd/MM/yyyy' })}
      renderInput={(params) => {
        return (
          <>
            <Box
              component='button'
              {...params}
              onClick={(e) => {
                setOpen(true);
                setAnchorEl(e.currentTarget);
              }}
              sx={styles.button({ fullWidth, error })}
            >
              {inputLabel && (
                <Typography variant='body' color='primary.main' style={styles.inputLabelCustom}>
                  {t(inputLabel)}
                </Typography>
              )}
              <Box sx={styles.icon}>
                <IconCalendarTime size='1.5rem' color={palette.gray[80]} />
                <Typography variant='h5' color='gray.90'>
                  {value ? formatDate(value, { dateOnly }) : t(placeholder)}
                </Typography>
              </Box>
            </Box>
            {helperText && <Typography sx={styles.helperText(error)}>{t(helperText)}</Typography>}
          </>
        );
      }}
    />
  );
};

DateTimePicker.propTypes = {
  dateOnly: PropTypes.bool,
  calendarViews: PropTypes.arrayOf(PropTypes.string),
  inputLabel: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDateTime: PropTypes.instanceOf(Date),
  value: PropTypes.any,
  hideToolbar: PropTypes.bool,
  disablePast: PropTypes.bool,
  amPmFormat: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default DateTimePicker;
