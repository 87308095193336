import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, FormControlLabel, Checkbox, Stack } from '@mui/material';
import { IconForms } from '@tabler/icons-react';
import { Button, Alert } from '@atoms';
import { CSV_LINE_ERRORS_MESSAGES } from '@globalConstants';
import { useSelectedCompanyDetails, useUserSegments } from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { Dropzone, ErrorList } from '@molecules';
import {
  SelectCompany,
  SelectSegment,
  ProspectsConfirmationModal,
  TemplateLinkProspect,
} from '@organisms';
import { useCompanyStore, useSegmentStore } from '@stores';
import { apiClient } from '@config/api/instances';
import styles from './styles';
import ProspectCreationTopSection from './topSection';
import useProspectCreationSelectors from './useProspectCreationSelectors';

/**
 * Prospect ProspectUploadScreen
 */
const ProspectUploadScreen = () => {
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileErrorDetails, setFileErrorDetails] = useState([]);
  const [error, setError] = useState({ title: '', description: '' });

  const [confirmProspects, setConfirmProspects] = useState({
    openModal: false,
    id: null,
    total: null,
  });

  const {
    shouldShowSelectors,
    shouldShowCompanySelector,
    shouldShowSegmentSelector,
    selectorsTitle,
    showSelectedCompany,
    showSelectedSegmentInConfirmation,
  } = useProspectCreationSelectors();
  const { selectedSegment } = useSegmentStore();
  const { selectedCompany } = useCompanyStore();
  const [sendEmail, setSendEmail] = useState(true);

  const { data } = useUserSegments();
  const { data: companyDetails } = useSelectedCompanyDetails();

  const segmentName = data?.find((item) => item.number === selectedSegment)?.name;
  const companyName = companyDetails?.description;

  const hasSelectedFile = files.length > 0;
  const hasFileLineErrors = fileErrorDetails.length > 0;

  const disableButton =
    isLoading || !hasSelectedFile || !selectedSegment || hasFileLineErrors || !companyName;

  /**
   * Handle prospects creation confirmation
   * @param {boolean} confirm Whether the user confirms of cancels the prospect creation
   */
  const handleConfirmation = async (confirm) => {
    setLoading(true);

    try {
      await apiClient.put('/s3/confirm-prospect-create', {
        prospectId: confirmProspects.id,
        confirm,
      });
      if (confirm) {
        setSuccess(true);
        setSendEmail(true);
        setFiles([]);
      }
    } catch (error) {
      const response = error.response;
      if (confirm) {
        if (response?.status === 404 && response.data?.errors?.[0]?.message === 'Key not found') {
          setError({
            title: 'PROSPECTS:ERRORS:EXPIRED_TITLE',
            description: 'PROSPECTS:ERRORS:EXPIRED_DESCRIPTION',
          });
        } else {
          setError({
            title: 'PROSPECTS:ERRORS:CONFIRMATION_ERROR_TITLE',
            description: 'PROSPECTS:ERRORS:CONFIRMATION_ERROR_DESCRIPTION',
          });
        }
        setErrorOpen(true);
      }
    }
    setConfirmProspects({ id: null, openModal: false, count: null });
    setLoading(false);
  };

  const { t } = useTranslation();

  /**
   * handle on change of send email checkbox
   * @param {*} event Check on change event
   */
  const handleSendEmail = (event) => setSendEmail(event.target.checked);

  /**
   * Handle the selection of a file
   */
  const handleSelectFiles = useCallback(
    (files) => {
      if (hasFileLineErrors) {
        setFileErrorDetails([]);
      }

      setFiles(files);
    },
    [hasFileLineErrors]
  );

  /**
   * Send clients registration CSV file to BE
   */
  const handleSubmit = async () => {
    setLoading(true);

    const formData = new FormData();

    formData.append('file', files[0]);
    formData.append('companyId', selectedCompany);
    formData.append('segment', segmentName);
    formData.append('notification', sendEmail);

    const url = '/s3/bulk-prospect-create';
    const body = formData;

    try {
      const {
        data: { prospectId, total },
      } = await apiClient.post(url, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setConfirmProspects({ openModal: true, id: prospectId, total });
    } catch (error) {
      const response = error.response;
      if (response?.status === 400) {
        setErrorOpen(true);
        if (response?.data.code === 'file_line_errors') {
          const fileErrors = response.data.data;
          setFileErrorDetails(fileErrors);
          setError({
            title: 'PROSPECTS:ERRORS:FILE_CONTAINS_ERRORS_TITLE',
            description: 'PROSPECTS:ERRORS:FILE_CONTAINS_ERRORS_DESCRIPTION',
          });
        } else if (response.data.code === 'csv_record_inconsistent_columns') {
          setError({
            title: 'COMMON:VALIDATIONS:INCONSISTENT_COLUMNS_TITLE',
            description: t('COMMON:VALIDATIONS:INCONSISTENT_COLUMNS_DESCRIPTION', {
              line: response.data.data.line,
            }),
          });
        } else {
          const code = response.data?.errors[0]?.message;
          switch (code) {
            case 'Name is not accepted.':
              setError({
                title: 'PROSPECTS:ERRORS:INVALID_SEGMENT_NAME_TITLE',
                description: 'PROSPECTS:ERRORS:INVALID_FILE_NAME_DESCRIPTION',
              });
              break;
            default:
              setError({
                title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
                description: 'PROSPECTS:ERRORS:DEFAULT_BAD_REQUEST_ERROR',
              });
              break;
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <ProspectCreationTopSection
          showSelectedCompany={showSelectedCompany}
          title='PROSPECTS:ADD_PROSPECTS_FILE_TITLE'
          linkTitle='MENU:PROSPECTS:QUICK_CREATION'
          linkTo='/prealta/rapida'
          companyName={companyName}
          linkIcon={<IconForms />}
        />
        {shouldShowSelectors && (
          <BoxContainer>
            <Typography variant='h4'>{t(selectorsTitle)}</Typography>
            {shouldShowSegmentSelector && <SelectSegment />}
            {shouldShowCompanySelector && <SelectCompany />}
          </BoxContainer>
        )}
        <BoxContainer paperPadding='3rem'>
          <Box sx={styles.paperHead}>
            <Typography variant='h4'>{t('PROSPECTS:UPLOAD_FILE')}</Typography>
            <TemplateLinkProspect />
          </Box>
          <Dropzone
            files={files}
            setFiles={setFiles}
            disabled={isLoading}
            handleFilesSelection={handleSelectFiles}
          />
          <FormControlLabel
            required
            control={<Checkbox />}
            checked={sendEmail}
            onChange={handleSendEmail}
            label={t('PROSPECTS:ADD_PROSPECTS_SEND_EMAIL')}
            ml={2}
          />
          <Stack direction='row' justifyContent='flex-end'>
            <Button
              text='COMMON:FILE_UPLOADS:SEND_FILE_BUTTON'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              disabled={disableButton}
              isLoading={isLoading}
              size='large'
            />
          </Stack>
          <ProspectsConfirmationModal
            companyName={companyName}
            segmentName={segmentName}
            confirmProspects={confirmProspects}
            disableButton={disableButton}
            handleConfirmation={handleConfirmation}
            isLoading={isLoading}
            showSelectedSegmentInConfirmation={showSelectedSegmentInConfirmation}
          />
          <Alert
            modalVisible={success}
            handleClose={() => setSuccess(false)}
            variant='success'
            title={'PROSPECTS:SUCCESS_TITLE'}
            description={'PROSPECTS:SUCCESS_BODY'}
          />
          <Alert
            modalVisible={errorOpen}
            handleClose={() => setErrorOpen(false)}
            description={error.description}
            title={error.title}
            widePaper={hasFileLineErrors}
            content={
              hasFileLineErrors && (
                <ErrorList
                  errors={fileErrorDetails}
                  title={'PROSPECTS:CSV_ERRORS_TITLE'}
                  getMessages={CSV_LINE_ERRORS_MESSAGES}
                />
              )
            }
          />
        </BoxContainer>
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default ProspectUploadScreen;
