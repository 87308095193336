import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button as MaterialButton, CircularProgress, Box, SvgIcon } from '@mui/material';
import styles from './styles';

/**
 *  @param {boolean=} disabled
 *  @param {string} text i18n key
 *  @param {func} onClick
 *  @param {object=} customStyle.
 *  @param {object=} customStyle.button
 *  @param {object=} customStyle.text
 *  @param {object=} customStyle.box
 *  @param {boolean} isLoading
 *  @param {string} variant
 *  @param {string=} size
 *  @param {string} color
 *  @param {string} href
 *  @param {string} target
 *  @param {string} rel
 *  @param {string} type
 *  @param {React.ReactNode=} content JSX to show as content instead of a i18n alias
 * @returns {React.ReactElement} Custom Button
 */
const Button = ({
  text,
  disabled = false,
  onClick,
  customStyle = {},
  isLoading = false,
  variant,
  size,
  color,
  type,
  content,
  startIcon,
  endIcon,
  href,
  target,
  rel,
}) => {
  const { t } = useTranslation();

  const handleStartIcon = startIcon ? <SvgIcon fontSize='small'>{startIcon}</SvgIcon> : undefined;
  const handleEndIcon =
    endIcon && !isLoading ? <SvgIcon fontSize='small'>{endIcon}</SvgIcon> : undefined;
  const spinerIcon = <CircularProgress size={styles.spinnerSize(size)} sx={styles.spinner} />;

  return (
    <Box sx={{ ...styles.buttonBox, ...customStyle.box }}>
      <MaterialButton
        onClick={onClick}
        disabled={disabled || isLoading}
        variant={variant}
        sx={customStyle.button}
        size={size}
        color={color}
        type={type}
        href={href}
        target={target}
        rel={rel}
        startIcon={isLoading && handleStartIcon ? spinerIcon : handleStartIcon}
        endIcon={isLoading && handleEndIcon ? spinerIcon : handleEndIcon}
      >
        {isLoading && !handleStartIcon && !handleEndIcon ? (
          spinerIcon
        ) : (
          <span style={customStyle.text}>{content ?? t(text)}</span>
        )}
      </MaterialButton>
    </Box>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  customStyle: PropTypes.object,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  content: PropTypes.node,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

export default Button;
