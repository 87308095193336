import { SxProps } from '@mui/material';
import { NubiTheme } from '@theme';

const styles: {
  formSwitch: SxProps<NubiTheme>;
} = {
  formSwitch: { gap: '0.3rem', px: '0.5rem' },
};

export default styles;
