import { OPERATIONS } from '@globalConstants';
import useRoles from './useRoles';
import useSelectedCompanyProducts from './useSelectedCompanyProducts';

/**
 * @function useShowMenuByCorporateProduct
 * @description This function is used to show the menu based on the corporate product.
 * @return {boolean} - Returns a boolean value indicating whether the menu should be shown or not.
 * @example
 * const showMenu = useShowMenuByCorporateProduct();
 */
const useShowMenuByCorporateProduct = () => {
  const { validateRoles } = useRoles();
  const { data } = useSelectedCompanyProducts();
  const isOperationRole = validateRoles([OPERATIONS]);

  if (isOperationRole) {
    return true;
  }

  return data.includes('Corporativo') || data.includes('Almuerzo');
};

export default useShowMenuByCorporateProduct;
