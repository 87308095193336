const styles = {
  container: {},
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
  searchContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '0.5rem',
    padding: '1.5rem 1rem',
    borderRadius: '1rem',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '0.5rem',
  },
  actionsContainer: {
    display: 'flex',
    gap: '0.5rem',
    height: '49px',
    justifyContent: 'flex-end',
    maxWidth: '160px',
    width: '100%',
  },
  clearButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      fontSize: '0.75rem',
      padding: '1rem 0.5rem',
    },
  },
  buttonModalCancel: {
    box: {
      marginRight: '0.5rem',
    },
  },
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  inputMaxWidth: {
    maxWidth: '120px',
  },
  deleteContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
export default styles;
