import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IconHelp } from '@tabler/icons-react';
import { Alert, Button } from '@atoms';
import { useURLparams, useUser } from '@hooks';
import { LoginSideImage } from '@layouts';
import { LoginHelpDialog } from '@molecules';
import NubizLogo from '@assets/svg/nubizLogo-dark.svg';
import styles from './styles';

/**
 * Login screen
 */
const LoginScreen = () => {
  const { params } = useURLparams();

  const [open, setOpen] = useState(false);
  const [helpIsOpen, setHelpIsOpen] = useState(false);
  const [error, setError] = useState('');

  const { loginWithRedirect } = useUser();

  useEffect(() => {
    if (params.noRoles) {
      setError('LOGIN:ERRORS:NO_ROLES');
      setOpen(true);
    }

    if (params.noPartnerData) {
      setError('LOGIN:ERRORS:NO_PARTNER_DATA');
      setOpen(true);
    }
  }, [params]);

  return (
    <LoginSideImage>
      <Box sx={styles.container}>
        <img src={NubizLogo} alt='Logo' style={styles.logo} />
        <Button
          customStyle={{ box: styles.buttonBox, button: styles.button }}
          variant='contained'
          text='LOGIN:GET_STARTED'
          onClick={loginWithRedirect}
        />
      </Box>
      <Box>
        <Button
          text='FAQ:HELP'
          startIcon={<IconHelp />}
          customStyle={styles.helpButton}
          variant='contained'
          onClick={() => setHelpIsOpen(true)}
        />
      </Box>
      <LoginHelpDialog isOpen={helpIsOpen} handleClose={() => setHelpIsOpen(false)} />
      <Alert
        modalVisible={open}
        handleClose={() => setOpen(false)}
        title='LOGIN:ERRORS:ACCOUNT_ERROR_TITLE'
        description={error}
      />
    </LoginSideImage>
  );
};

export default LoginScreen;
