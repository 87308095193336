import { SystemStyleObject } from '@mui/system';
import { NubiTheme } from '@theme';

const styles: {
  textContainer:
    | SystemStyleObject<NubiTheme>
    | ((theme: NubiTheme) => SystemStyleObject<NubiTheme>);
  primaryText: SystemStyleObject<NubiTheme> | ((theme: NubiTheme) => SystemStyleObject<NubiTheme>);
  secondaryText:
    | SystemStyleObject<NubiTheme>
    | ((theme: NubiTheme) => SystemStyleObject<NubiTheme>);
} = {
  textContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    gap: '1.5rem',
    padding: '0.53rem 0rem',
    margin: 0,
  },
  primaryText: {
    margin: 'auto 0',
    width: '13.5rem',
    fontWeight: 600,
    color: ({ palette }) => palette.gray[90],
  },
  secondaryText: { color: ({ palette }) => palette.gray[90] },
};

export default styles;
