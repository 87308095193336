import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styles from './styles';

/**
 * A layout component for the base full width content
 * @param {React.ReactNode} children
 * @param {boolean} [topPadding] if the content is at the top of the page, set to true to include base vertical padding
 * @returns
 */
const FullWidthContent = ({ children, topPadding = false }) => {
  return <Box sx={styles.container(topPadding)}>{children}</Box>;
};

FullWidthContent.propTypes = {
  children: PropTypes.node,
  topPadding: PropTypes.bool,
};

export default FullWidthContent;
