import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Popover, SvgIcon, useMediaQuery } from '@mui/material';
import { IconDotsVertical } from '@tabler/icons-react';
import { Button } from '@atoms';
import styles from './styles';

/**
 * Toolbar actions
 * @param {Object} props - The properties of the Toolbar component.
 * @param {Array} props.buttons - An array of objects representing the actions.
 */
const Toolbar = ({ buttons }) => {
  const firstButtons = buttons.slice(0, 5);
  const moreButtons = buttons.slice(5);
  const showMoreButtons = buttons.length > 5;
  const [showPopover, setShowPopover] = useState(false);
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('md'));

  /** Open more actions  */
  const handleShowMoreClick = (event) => {
    setShowPopover(event.currentTarget);
  };
  /** Close more actions */
  const handleClose = () => {
    setShowPopover(false);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerFirstButtons(responsive)}>
        {firstButtons.map((button, index) => (
          <Button
            key={index}
            customStyle={styles.button}
            onClick={button.onClick}
            text={button.text}
            startIcon={<SvgIcon>{button.icon}</SvgIcon>}
            disabled={button.disabled}
            isLoading={button?.isLoading}
          />
        ))}
      </Box>
      {showMoreButtons && (
        <>
          <Button
            customStyle={styles.button}
            onClick={handleShowMoreClick}
            text={'Más'}
            startIcon={
              <SvgIcon>
                <IconDotsVertical />
              </SvgIcon>
            }
          />
          <Popover
            open={showPopover}
            anchorEl={showPopover}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={styles.containerPopover}>
              {moreButtons.map((button, index) => (
                <Button
                  key={index}
                  customStyle={styles.button}
                  onClick={button.onClick}
                  text={button.text}
                  startIcon={<SvgIcon>{button.icon}</SvgIcon>}
                />
              ))}
            </Box>
          </Popover>
        </>
      )}
    </Box>
  );
};

Toolbar.propTypes = {
  buttons: PropTypes.obj,
};

export default Toolbar;
