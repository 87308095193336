import { SxProps } from '@mui/material';
import { ProductColor } from '@globalConstants';
import { NubiTheme } from '@theme';

interface CardStyles {
  container: (productColor: ProductColor) => SxProps<NubiTheme>;
  caption: (productColor: ProductColor) => SxProps<NubiTheme>;
  expirationDate: SxProps<NubiTheme>;
  textAlignCenter: { textAlign: 'center' };
  alignSelfEnd: { alignSelf: 'flex-end' };
  text: (productColor: ProductColor) => SxProps<NubiTheme>;
  chip: (productColor: ProductColor) => SxProps;
}

const styles: CardStyles = {
  container: (productColor) => ({
    backgroundColor: productColor.main,
    borderColor: productColor.main,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 2,
    paddingX: (theme) => theme.spacing(3),
    paddingY: (theme) => theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  }),
  caption: (productColor) => ({
    color: productColor.complementary,
    fontSize: '0.625rem',
    lineHeight: 1,
  }),
  expirationDate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  textAlignCenter: { textAlign: 'center' },
  alignSelfEnd: { alignSelf: 'flex-end' },
  text: (productColor) => ({
    color: productColor.complementary,
  }),
  chip: (productColor) => ({
    backgroundColor: productColor.complementary,
    color: productColor.main,
    borderColor: productColor.complementary,
    '.MuiChip-label': {
      color: productColor.main,
    },
  }),
};

export default styles;
