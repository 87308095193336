import { Box, CircularProgress } from '@mui/material';
import styles from './styles';

/**
 * @returns Loading screen component
 */
const LoadingScreen = () => {
  return (
    <Box sx={styles.container}>
      <CircularProgress sx={styles.spinner} size={60} />
    </Box>
  );
};

export default LoadingScreen;
