export const CLIENT_ANALYST = 'Analista_cliente';
export const CUSTOMER_SUPPORT_ANALYST = 'Analista_customer';
export const OPERATIONS_ANALYST = 'Analista_operaciones';
export const PRODUCT_ANALYST = 'Analista_producto';
export const CLIENT = 'Cliente';
export const CUSTOMER_SUPPORT = 'Customer_support';
export const OPERATIONS = 'Operaciones';
export const PRODUCT = 'Producto';
export const OPERATIONS_SUPERVISOR = 'Supervisor_operaciones';
export const SUPER_ADMIN = 'SUPER_ADMIN';
export const TECH = 'Tecnología';
export const CORPORATE_CLIENT = 'Cliente_corporativo';

export const ALL_ROLES = [
  CLIENT_ANALYST,
  CUSTOMER_SUPPORT_ANALYST,
  OPERATIONS_ANALYST,
  PRODUCT_ANALYST,
  CLIENT,
  CUSTOMER_SUPPORT,
  OPERATIONS,
  PRODUCT,
  OPERATIONS_SUPERVISOR,
  SUPER_ADMIN,
  TECH,
  CORPORATE_CLIENT,
];
export const PERMISSIONS = {
  viewUsers: 'read:user',
  viewUserDetails: 'read:user_details',
  viewUserProduct: 'read:user_products',
  viewUserAccountDetail: 'read:user_accounts',
  viewUserMovement: 'read:user_movements',
  viewUserMovementDetail: 'read:user_movements_details',
  viewUserEmailOrSegment: 'read:user_email_segment',
  viewDispersions: 'read:dispersion',
  editUserEmailOrSegment: 'update:user_email_segment',
  viewUserBenefit: 'read:user_benefit',
  viewAllBenefits: 'read:all_benefits',
  editUserBenefit: 'update:user_benefit',
  sendMoney: 'create:dispersion',
  prospect: 'create:prospect',
  internalUser: 'view_all_companies',
  companies: 'read:companies',
  vouchers: 'read:client_vouchers',
  recoveryBalance: 'update:recovery_balance',
  viewClientMovements: 'read:client_movements',
  viewClient: 'read:client',
  viewClientVouchers: 'read:client_vouchers',
  createClient: 'create:client',
  viewClientBalance: 'read:client_balance',
  viewSegments: 'read:segments',
  viewCommerce: 'read:commerce',
  createCommerce: 'create:commerce',
  deleteCommence: 'delete:commerce',
  assignUserProducts: 'update:user_products',
  viewReports: 'read:reports',
  registerCompanyInPrisma: 'create:additional_segments',
};
