import useSWR from 'swr';

export interface Category {
  name: string;
}

/**
 * Obtains a list of all categories
 */
const useCategories = (allowRequest = true) => {
  const { data, isLoading, error } = useSWR<Category>(
    () => (allowRequest ? '/partner/categories' : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error };
};

export default useCategories;
