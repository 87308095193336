import { benefit, business, lunch } from 'theme/colors';
import { Box, Tooltip } from '@mui/material';
import { IconCreditCard } from '@tabler/icons-react';
import { CLIENT, OPERATIONS, OPERATIONS_SUPERVISOR } from '@globalConstants';
import { parseAmount } from '@utils';
import styles from './styles';

export const PRODUCTS_COLORS = {
  Beneficio: benefit,
  Corporativo: business,
  Almuerzo: lunch,
};
export const COLUMNS = [
  {
    title: 'COMMON:FIRST_NAME',
    content: (client) => {
      const isMaxCharacter = client.firstName.length > 25;
      return (
        <Tooltip title={isMaxCharacter && client.firstName} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{client.firstName}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:LAST_NAME',
    content: (client) => {
      const isMaxCharacter = client.lastName.length > 25;
      return (
        <Tooltip title={isMaxCharacter && client.lastName} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{client.lastName}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:EMAIL',
    content: (client) => {
      const isMaxCharacter = client.email.length > 25;
      return (
        <Tooltip title={isMaxCharacter && client.email} arrow>
          <Box sx={[styles.rowEllipsis, styles.emailMaxWidth]}>{client.email}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:CUIL',
    content: (client) => {
      const isMaxCharacter = client.cuit.length > 11;
      return (
        <Tooltip title={isMaxCharacter && client.cuit} arrow>
          <Box sx={[styles.rowEllipsis, styles.cuitMaxWidth]}>{client.cuit}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'COMMON:PRODUCT',
    content: (client) => {
      const productName = client.products;

      return (
        <>
          {[productName].map((item, index) => {
            return (
              <Box key={index} sx={[styles.rowEllipsis, styles.productMaxWidth]}>
                <Tooltip title={item} arrow>
                  <IconCreditCard stroke={2} color={PRODUCTS_COLORS[item]?.main} />
                </Tooltip>
                {parseAmount(client.balance)}
              </Box>
            );
          })}
        </>
      );
    },
  },
];
export const createProspectRoles = [CLIENT, OPERATIONS, OPERATIONS_SUPERVISOR];
export const DEFAULT_ROWS_PER_PAGE = 25;
