import useSegments from '../useSegments';
/**
 * Obtains the segments the current user is authorized to operate with
 */
const useUserSegments = () => {
  const props = useSegments(); //TODO: replace with logic that uses the current logged user data

  return props;
};

export default useUserSegments;
