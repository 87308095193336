import { useUser } from '@hooks';

/**
 * Component for selecting segment and company for prospect creation.
 */
const useProspectCreationSelectors = () => {
  const { user } = useUser();

  const shouldShowCompanySelector = user.isInternal;
  const shouldShowSegmentSelector = user.isInternal || user.metadata.types.length > 1;
  const shouldShowSelectors = shouldShowCompanySelector || shouldShowSegmentSelector;

  const showSelectedCompany = !user.isInternal && user.metadata.companies.length > 1;
  const showSelectedSegmentInConfirmation = shouldShowSegmentSelector;

  /**
   * @returns The title of the selectors section
   */
  const getTitle = () => {
    if (shouldShowSelectors) {
      const selectBoth = shouldShowCompanySelector && shouldShowSegmentSelector;

      if (selectBoth) {
        return 'COMMON:CHOOSE_SEGMENT_COMPANY';
      } else if (shouldShowCompanySelector) {
        return 'COMMON:CHOOSE_COMPANY';
      } else {
        return 'COMMON:CHOOSE_SEGMENT';
      }
    }
  };

  return {
    selectorsTitle: getTitle(),
    shouldShowSelectors,
    shouldShowCompanySelector,
    shouldShowSegmentSelector,
    showSelectedCompany,
    showSelectedSegmentInConfirmation,
  };
};

export default useProspectCreationSelectors;
