const styles = {
  mainBox: { display: 'flex', flexDirection: 'column' },
  goBackLink: { display: 'flex', padding: '0.5rem' },
  cardMedia: {
    border: 'none',
    borderRadius: '10px',
    height: '87vh',
  },
  container: {
    width: '100%',
    height: 'calc(100vh - 112px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export default styles;
