import { FC } from 'react';
import { t } from 'i18next';
import { propertyListItemStyle } from 'screens/employeeDetails/tabs/productsTab/ProductCard/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  CardContent,
  Typography,
  SvgIcon,
  Grid,
  CardHeader,
  CircularProgress,
} from '@mui/material';
import { Button, PropertyListItem } from '@atoms';
import { OPERATIONS, CLIENT } from '@globalConstants';
import { useBoolean, useURLparams } from '@hooks';
import { useCompanyStore } from '@stores';
import styles from './styles';
import usePostalAddressOfACompany from './usePostalAddressOfACompany';

interface CompanyDetailsProps {
  isOperationRole: boolean;
}

/** Render company details address */
const CompanyDetails: FC<CompanyDetailsProps> = ({ isOperationRole }) => {
  const { params } = useURLparams();
  const { selectedCompany } = useCompanyStore();

  const { data: companyPostalAddress, isLoading: isLoadingCompanyPostalAddress } =
    usePostalAddressOfACompany('company' in params ? params.company : selectedCompany);

  const floorAndAparment =
    companyPostalAddress &&
    (companyPostalAddress.floor || companyPostalAddress.apartment
      ? `${companyPostalAddress.floor ?? ''}/${companyPostalAddress.apartment ?? ''}`
      : '');

  const { isOpen: addressExpanded, toggle: toggleAddress } = useBoolean(true);

  return (
    <Card>
      <CardHeader
        title={t('CLIENTS:DETAIL:POSTAL_ADDRESS:TITLE')}
        titleTypographyProps={{ typography: 'h2', flex: 'none' }}
        sx={styles.headerContent}
        action={
          companyPostalAddress && (
            <Button
              customStyle={styles.toggleAddress}
              startIcon={
                <SvgIcon>{addressExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</SvgIcon>
              }
              color='secondary'
              variant='link'
              onClick={toggleAddress}
            />
          )
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        {isLoadingCompanyPostalAddress ? (
          <CircularProgress sx={styles.spinner} size={48} />
        ) : (
          <>
            {companyPostalAddress ? (
              <>
                {addressExpanded ? (
                  <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={4} md={4}>
                      <PropertyListItem
                        customStyle={propertyListItemStyle}
                        label='CLIENTS:DETAIL:POSTAL_ADDRESS:CITY'
                        value={companyPostalAddress.cityName}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <PropertyListItem
                        customStyle={propertyListItemStyle}
                        label='CLIENTS:DETAIL:POSTAL_ADDRESS:STATE'
                        value={companyPostalAddress.stateName}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <PropertyListItem
                        customStyle={propertyListItemStyle}
                        label='CLIENTS:DETAIL:POSTAL_ADDRESS:STREET'
                        value={companyPostalAddress.street}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <PropertyListItem
                        customStyle={propertyListItemStyle}
                        label='CLIENTS:DETAIL:POSTAL_ADDRESS:NUMBER'
                        value={companyPostalAddress.number}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <PropertyListItem
                        customStyle={propertyListItemStyle}
                        label='CLIENTS:DETAIL:POSTAL_ADDRESS:FLOOR'
                        value={floorAndAparment}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <PropertyListItem
                        customStyle={propertyListItemStyle}
                        label='CLIENTS:DETAIL:POSTAL_ADDRESS:POSTAL_CODE'
                        value={companyPostalAddress.postalCode}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </>
            ) : (
              <Typography>
                {isOperationRole
                  ? t(`CLIENTS:DETAIL:DONT_HAVE_POSTAL_ADDRESS:${OPERATIONS.toUpperCase()}`)
                  : t(`CLIENTS:DETAIL:DONT_HAVE_POSTAL_ADDRESS:${CLIENT.toUpperCase()}`)}
              </Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyDetails;
