import { useCallback, useState } from 'react';
import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';

export interface ExportAllUserMovementsFilters {
  fromDate?: string;
  toDate?: string;
  transactionType?: string;
  transactionStatus: string;
}
interface UseExportAllUserMovementsReturn {
  isLoading: boolean;
  exportAllUserMovements: (
    accountNumber: string,
    filters: ExportAllUserMovementsFilters
  ) => Promise<void>;
  error?: string;
}

/**
 * Custom hook to manage export all movements to xlsx file
 * @returns {isLoading, exportAllMovements, error}
 */
const useExportAllUserMovements = (): UseExportAllUserMovementsReturn => {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState<string>();

  const exportAllUserMovements = useCallback(
    async (accountNumbers: string, filters: ExportAllUserMovementsFilters) => {
      setIsloading(true);
      setError(undefined);
      try {
        const response = await apiClient.get('/export/userMovements', {
          params: { accountNumbers, ...filters },
          responseType: 'blob',
        });

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'movimientos.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } catch (error) {
        if (isAxiosError(error)) {
          setError(error.message);
        }
      } finally {
        setIsloading(false);
      }
    },
    []
  );

  return { isLoading, exportAllUserMovements, error };
};

export default useExportAllUserMovements;
