import { useCallback, useEffect, useState } from 'react';
import { ensureInitialized, fetchAndActivate } from 'firebase/remote-config';
import { Logger } from '@utils';
import { remoteConfig } from '@config/firebase';

const logger = new Logger('Remote Config');

/**
 * Activates Firebase Remote Config
 * @export
 */
export default function useRemoteConfigValues() {
  const [isReady, setIsReady] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    useCallback(() => {
      /**
       * Wrapper for async callback
       */
      const getRemoteConfigValues = async () => {
        try {
          logger.log('Trying to initialize, fetch and activate the module');
          await ensureInitialized(remoteConfig);
          logger.log('Ensured that RemoteConfig is initialized');
          await fetchAndActivate(remoteConfig);
          logger.log('Values fetched successfully');
          setIsReady(true);
        } catch (error) {
          logger.error('Error trying to initialize, fetch or activate the module');
        }
      };

      getRemoteConfigValues();
    }, []),
    []
  );
  return isReady;
}
