import { useState } from 'react';
import { Snackbar } from '@atoms';

/**
 * Custom hook for displaying an error Snackbar.
 * @returns {{showErrorSnackbar: (message: string) => void, ErrorSnackbar: () => React.ReactElement}} Functions and components related to the error Snackbar.
 */
const useErrorSnackbar = () => {
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * Displays the error Snackbar with the provided message.
   * @param {string} message - The error message to display in the Snackbar.
   */
  const showErrorSnackbar = (message) => {
    setErrorMessage(message);
    setErrorSnackbarOpen(true);
  };

  /**
   * Handles the close event of the error Snackbar.
   */
  const handleSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };

  /**
   * Error Snackbar component.
   * @returns {React.ReactElement} The ErrorSnackbar component.
   */
  const ErrorSnackbar = () => (
    <Snackbar open={errorSnackbarOpen} handleClose={handleSnackbarClose} message={errorMessage} />
  );

  return { showErrorSnackbar, ErrorSnackbar };
};

export default useErrorSnackbar;
