const styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    padding: '0.5rem 0 !important',
  },
  dialogPaper: (width) => ({ padding: '1.5rem', width }),
};

export default styles;
