const styles = {
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  linkContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '2rem',
  },
};
export default styles;
