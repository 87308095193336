import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  InputLabel,
  FormControl,
  Select as SelectMaterial,
  MenuItem,
  FormHelperText,
  Skeleton,
  SvgIcon,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { style } from '@mui/system';
import { IconAlertTriangle } from '@tabler/icons-react';
import styles from './styles';

/**
 * Select Component
 * @param {object} props - Component props
 * @param {string} props.name - The name of the select element
 * @param {string} props.label - The label text for the select element
 * @param {string=} props.value - The current value of the select element
 * @param {boolean} props.error - Indicates whether there's an error with the select element
 * @param {function=} props.onChange - The function to call when the select value changes
 * @param {function=} props.onBlur - The function to call when the select element loses focus
 * @param {string=} props.helperText - Additional helper text to display below the select element
 * @param {Array} props.items - The array of items to populate the select element
 * @param {function} props.parseOption - The function to parse each item in the items array
 * @param {boolean} [props.disabled=false] - Indicates whether the select element is disabled
 * @param {string} [props.variant='outlined'] - The variant of the select element (e.g., 'outlined', 'filled')
 * @param {string} [props.placeholder='COMMON:SELECT_PLACEHOLDER'] - The placeholder text for the select element
 * @param {boolean} [props.isLoading=false] - Indicates whether the select element is in a loading state
 * @param {boolean} [props.isError=false] - Indicates whether there's an error loading items for the select element
 * @param {boolean} [props.multiple=false] - Indicates whether the select element allows multiple selections
 * @param {obj} props.customStyle - Object custom styles
 * @returns {JSX.Element} Select component
 */
const Select = ({
  name,
  label,
  value,
  error,
  onChange,
  onBlur,
  helperText,
  items = [],
  parseOption,
  disabled = false,
  variant = 'outlined',
  placeholder,
  isLoading = false,
  isError = false,
  multiple = false,
  customStyle = {},
}) => {
  const { t } = useTranslation();
  const selectOptions = items.map((item) => parseOption(item));

  if (isLoading || !items) {
    return (
      <Box sx={style.skeletonBox}>
        <Skeleton variant='rectangular' height={49} />
      </Box>
    );
  }

  /**
   * Render value for multiple select
   * @param {Array} selected - The selected values
   */
  const renderValueMultiple = (selected, multiple) => {
    const placeholderResponse = placeholder ? t(placeholder) : undefined;
    if (selected && items) {
      if (Array.isArray(items) && multiple) {
        const parseItems = selectOptions.reduce((acc, curr) => {
          if (selected.includes(curr.value)) {
            acc.push(curr.label);
          }
          return acc;
        }, []);
        return parseItems.length > 0 ? parseItems.join(', ') : placeholderResponse;
      } else {
        return (
          selectOptions.find((option) => option.value === selected)?.label || placeholderResponse
        );
      }
    } else {
      return placeholderResponse;
    }
  };

  return (
    <FormControl fullWidth variant={variant} sx={customStyle}>
      <InputLabel id={`${name}-label`} sx={styles.inputLabel} error={error}>
        {isError ? (
          <>
            <SvgIcon fontSize='small' paddingRight='2'>
              <IconAlertTriangle />
            </SvgIcon>
            {t('COMMON:SELECT_ERROR')}
          </>
        ) : (
          label
        )}
      </InputLabel>
      <SelectMaterial
        name={name}
        labelId={`${name}-label`}
        label={label}
        value={value}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder ? placeholder : t('COMMON:SELECT_PLACEHOLDER')}
        disabled={isError || disabled}
        multiple={multiple}
        renderValue={(selected) => renderValueMultiple(selected, multiple)}
      >
        {selectOptions.length > 0 &&
          selectOptions.map((item) => {
            return multiple ? (
              <MenuItem key={item.value} value={item.value} style={styles.menuItem}>
                <Checkbox checked={value.indexOf(item.value) > -1} />
                <ListItemText primary={item.label} />
              </MenuItem>
            ) : (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
      </SelectMaterial>
      {helperText && <FormHelperText error={isError || error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  parseOption: PropTypes.func,
  customStyle: PropTypes.obj,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default Select;
