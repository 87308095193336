import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useUser } from '@hooks';

/**
 * @returns The Home content for an internal user
 */
const InternalUserHome = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <>
      <Typography variant='h1'>{t('HOME:WELCOME_TITLE_INTERNAL', { name: user.name })}</Typography>
      <Typography variant='h3Regular' color='gray.90'>
        {t('HOME:WELCOME_BODY_EXTERNAL')}
      </Typography>
    </>
  );
};

export default InternalUserHome;
