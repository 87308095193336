import { useUser } from '@hooks';

/**
 * Takes a list of features and removes the ones that are exclusive for internal users, if the user is external.
 * The internal features must contain the property `internalFeature`
 * @param {array} items a list of items representing features, fields or columns
 * @returns {array} The resulting items
 */
function useFilterInternalFeatures(items) {
  const { user } = useUser();

  if (user.isInternal) {
    return items;
  }

  /**
   * Whether an item should be displayed to the user
   */
  const shouldShowItem = (item) => !item.internalFeature;

  const externalUserItems = items.filter(shouldShowItem);

  return externalUserItems;
}

export default useFilterInternalFeatures;
