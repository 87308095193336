import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { IconUserPlus, IconPencilPlus, IconZoomMoney, IconFileExport } from '@tabler/icons-react';
import {
  CONFIG_AMOUNT_FIELD,
  VALIDATION_AMOUNT_SCHEMA,
} from 'screens/dispersions/fastDispersion/constants';
import { Alert } from '@atoms';
import { OPERATIONS, PERMISSIONS } from '@globalConstants';
import {
  useCompanyDetails,
  useCreateXlsx,
  useEmployees,
  useErrorSnackbar,
  useExportAllUserAccountsFiltered,
  useFastDispersion,
  useFilterInternalFeatures,
  usePermissions,
  useRoles,
  useURLparams,
  useUser,
  useUserCompanies,
} from '@hooks';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import { Table, Toolbar } from '@molecules';
import { DispersionConfirmationModal, FormModal } from '@organisms';
import { useClientStore, useCompanyStore } from '@stores';
import { filterDuplicateWalletIds } from '@utils';
import AssignNewProduct from './assignNewProduct';
import { getAlertInfo, ALERT_TYPES } from './assignNewProduct/constants';
import { COLUMNS, DEFAULT_ROWS_PER_PAGE } from './constants';
import SearchBoxClient from './searchBoxEmployees';
/**
 * Clients screen
 */
const EmployeesScreen = () => {
  const { user } = useUser();
  const { selectedCompany } = useCompanyStore();
  const { params, setURLparams } = useURLparams(
    !user.isInternal
      ? { page: 1, limit: DEFAULT_ROWS_PER_PAGE, companyId: selectedCompany }
      : undefined
  );
  const { ErrorSnackbar, showErrorSnackbar } = useErrorSnackbar();
  const navigate = useNavigate();
  const setClientSelected = useClientStore((state) => state.setClientSelected);
  const setSearchParams = useClientStore((state) => state.setSearchParams);
  const { t } = useTranslation();
  const canViewProspectsButton = usePermissions([PERMISSIONS.prospect]);
  const canViewSendMoneyButton = usePermissions([PERMISSIONS.sendMoney]);
  const canViewAddProductButton = usePermissions([PERMISSIONS.assignUserProducts]);
  const tableColumns = useFilterInternalFeatures(COLUMNS);
  const [differentCustomersAlertInfo, setDifferentCustomersAlertInfo] = useState(null);
  const [isShowAmountModal, setIsShowAmountModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const { validateRoles } = useRoles();
  const { data: companyData } = useCompanyDetails(
    validateRoles([OPERATIONS]) ? params.companyId : selectedCompany
  );

  const [confirmDispersion, setConfirmDispersion] = useState({
    openModal: false,
    id: null,
    total: null,
    count: null,
  });

  const {
    data,
    isLoading,
    isValidating,
    mutate,
    handlePageChange: onPageChange,
    handleRowsPerPageChange,
    filtersOpen,
    setFiltersOpen,
    onFiltersFormSubmit,
    assignProductOpen,
    setAssignProductOpen,
    selectedItems,
    setSelectedItems,
  } = useEmployees({
    params,
    setURLparams,
    /**
     * Handle the errors of the request
     */
    onError: function handleErrors(error) {
      switch (error?.response?.status) {
        case 400:
          showErrorSnackbar(t('EMPLOYEES:ERRORS:INVALID_SEARCH'));
          break;
        default:
          if (error) {
            showErrorSnackbar(t('EMPLOYEES:ERRORS:GENERIC_ERROR'));
          }
      }
    },
  });

  const { data: userCompanies } = useUserCompanies();

  const isOperations = validateRoles([OPERATIONS]);
  const companyId = isOperations ? params.companyId : selectedCompany;
  const customerId = selectedItems[0]?.customerId;
  const companyName =
    companyData?.description ||
    userCompanies?.find(({ id }) => id === Number(companyId))?.description;

  const {
    isLoading: isLoadingDispersion,
    fastDispersion,
    confirmFastDispersion,
    isError,
    setIsError,
    errorMessage,
    createFastDispersionWithAllFilteredData,
  } = useFastDispersion();

  const { isLoading: isLoadingCreateXlsx, createXlsx } = useCreateXlsx();

  const { isLoading: isLoadingExportAll, exportAllUserAccountsFiltered } =
    useExportAllUserAccountsFiltered();

  useEffect(
    function updateCompanyIdParamWhenChangeSelectedCompany() {
      if (!user.isInternal && selectedCompany !== parseInt(params.companyId)) {
        setURLparams({ companyId: selectedCompany });
      }
    },
    [params, selectedCompany, setURLparams, user.isInternal]
  );

  /**
   * Store client data & searched params & go to movement detail screen
   */
  function navigateToDetail(client) {
    setClientSelected(client);
    setSearchParams(params);
    navigate(`/detalle-cliente/${client.username}`, {
      state: { params, account_number: client.acctNumber },
    });
  }

  /**
   * Handles the logic for checking if all selected users belong to the same customer.
   * If they do, it performs a specified action. Otherwise, it shows an alert.
   * @param {Function} action - The action to perform if all customers are the same.
   */
  const handleCustomerCheck = (action) => {
    const hasNullCustomerId = selectedItems.some((user) => user.customerId === null);
    const alertInfo = getAlertInfo(
      { error_different_customers: true, alertType: ALERT_TYPES.DIFFERENT_CUSTOMERS },
      t
    );
    if (!hasNullCustomerId) {
      const allCustomerIdsEqual = selectedItems.every((user, index, array) => {
        if (index < array.length - 1) {
          return user.customerId === array[index + 1].customerId;
        }
        return true;
      });

      if ((isSelectedAll && companyId) || (!isSelectedAll && allCustomerIdsEqual)) {
        action();
      } else {
        setDifferentCustomersAlertInfo(alertInfo);
      }
    } else {
      setDifferentCustomersAlertInfo(alertInfo);
    }
  };

  /** Handles the assignment of a new product to selected users. */
  const handleAssignNewProduct = () => handleCustomerCheck(() => setAssignProductOpen(true));

  /** Handles the dispersion of selected users.*/
  const handleDispersion = () => handleCustomerCheck(() => setIsShowAmountModal(true));

  /** Handles exporting dispersion data.*/
  const handleExportAccounts = () => {
    if (isSelectedAll) {
      exportAllUserAccountsFiltered({
        ...(selectedCompany
          ? { companyId: selectedCompany }
          : params.companyId
          ? { companyId: params.companyId }
          : {}),
        lastName: params.lastName,
        firstName: params.firstName,
        email: params.email,
        cuit: params.cuit,
        walletId: params.walletId,
        product: params.product,
        categories: params.categories,
      });
    } else {
      createXlsx(selectedItems);
    }
  };

  const buttons = [
    {
      text: t('EMPLOYEES:PROSPECT_CREATE'),
      onClick: () => navigate('/prealta/rapida'),
      icon: <IconUserPlus />,
      show: canViewProspectsButton,
    },
    {
      text: t('DISPERSIONS:DISPERSE'),
      onClick: handleDispersion,
      icon: <IconZoomMoney />,
      disabled: !selectedItems.length > 0,
      show: canViewSendMoneyButton,
      isLoading: isLoadingDispersion,
    },
    {
      text: t('EMPLOYEES:ASSIGN_PRODUCT:BUTTON'),
      onClick: handleAssignNewProduct,
      icon: <IconPencilPlus />,
      disabled: !selectedItems.length > 0,
      show: canViewAddProductButton,
    },
    {
      text: t('COMMON:EXPORT'),
      onClick: handleExportAccounts,
      icon: <IconFileExport />,
      disabled: !selectedItems.length > 0,
      isLoading: isLoadingExportAll || isLoadingCreateXlsx,
      show: false,
    },
  ];
  const filteredButtons = buttons.filter((button) => button.show);

  /** Handle submit fast dispersion. */
  const handleSubmit = async ({ amount }) => {
    let fastDispersionResponse;

    setIsShowAmountModal(false);

    if (isSelectedAll) {
      fastDispersionResponse = await createFastDispersionWithAllFilteredData({
        ...(companyId ? { companyId } : { customerId }),
        amount,
        filters: {
          cuil: params.cuil,
          firstName: params.firstName,
          lastName: params.lastName,
          categories: params.categories,
          product: params.product,
        },
      });
    } else {
      fastDispersionResponse = await fastDispersion({
        employees: selectedItems,
        amount,
        ...(companyId ? { companyId } : { customerId }),
      });
    }

    const { dispersionId, total, count } = fastDispersionResponse;

    if (dispersionId && total && count) {
      setConfirmDispersion({
        openModal: true,
        id: dispersionId,
        total,
        count,
      });
    }
  };

  /** Handle submit conformation fast dispersion. */
  const handleDispersionConfirm = async (confirm) => {
    const data = await confirmFastDispersion({
      dispersionId: confirmDispersion.id,
      confirm,
    });
    if (data) {
      if (confirm) {
        setSelectedItems([]);
        setIsSuccess(true);
      }
    }
    setConfirmDispersion({ openModal: false, id: null, total: null, count: null });
  };

  /** Handle action when page change */
  const handlePageChange = (page) => {
    onPageChange(page);
    if (isSelectedAll) {
      setIsSelectedAll(false);
      setSelectedItems([]);
    }
  };

  /** Handles select all data */
  const handleSelectAll = () => {
    setIsSelectedAll((prev) => {
      if (prev) {
        setSelectedItems([]);
      }

      return !prev;
    });
  };

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <Stack spacing={3}>
          <SearchBoxClient
            isOpen={filtersOpen}
            onClose={() => setFiltersOpen(false)}
            params={params && params}
            isLoading={isLoading || isValidating}
            retry={mutate}
            onFiltersFormSubmit={onFiltersFormSubmit}
            disabledExportAll={!data?.page.length}
          />
          <Toolbar buttons={filteredButtons} />
          <Table
            count={data?.totalCount || 0}
            items={data?.page}
            isLoading={isLoading}
            columns={tableColumns}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onItemClick={navigateToDetail}
            page={params.page || 1}
            rowsPerPage={params.limit || DEFAULT_ROWS_PER_PAGE}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            checkedItemProp='acctNumber'
            onSelectAll={handleSelectAll}
            isSelectedAll={isSelectedAll}
            setIsSelectedAll={setIsSelectedAll}
            showCheckbox
          />
        </Stack>
        <AssignNewProduct
          assignProductOpen={assignProductOpen}
          setAssignProductOpen={setAssignProductOpen}
          selectedUsers={filterDuplicateWalletIds(selectedItems)}
        />
        <ErrorSnackbar />
        <Alert
          modalVisible={differentCustomersAlertInfo?.modalVisible}
          handleClose={() => setDifferentCustomersAlertInfo(null)}
          variant={differentCustomersAlertInfo?.variant}
          title={differentCustomersAlertInfo?.title}
          description={differentCustomersAlertInfo?.description}
          closeButtonText={t('COMMON:ACCEPT')}
        />
        <FormModal
          title='DISPERSIONS:FAST_DISPERSION:TITLE_AMOUNT'
          description='DISPERSIONS:FAST_DISPERSION:DESCRIPTION_AMOUNT'
          isOpen={isShowAmountModal}
          validationSchema={VALIDATION_AMOUNT_SCHEMA}
          configFields={CONFIG_AMOUNT_FIELD}
          onClose={() => setIsShowAmountModal(false)}
          initialValues={{ amount: '' }}
          showClearFilter={false}
          onSubmit={handleSubmit}
        />
        <DispersionConfirmationModal
          openModal={confirmDispersion.openModal}
          handleConfirmation={handleDispersionConfirm}
          total={confirmDispersion.total}
          count={confirmDispersion.count}
          companyName={companyName}
          submitLoading={isLoadingDispersion}
        />
        <Alert
          modalVisible={isError}
          handleClose={() => setIsError(false)}
          variant='error'
          title={errorMessage.title}
          description={errorMessage.description}
        />
        <Alert
          modalVisible={isSuccess}
          handleClose={() => setIsSuccess(false)}
          variant='success'
          title={'DISPERSIONS:FAST_DISPERSION:DISPERSIONS_SUCCESS_TITLE_INSTANT'}
          description={'DISPERSIONS:FAST_DISPERSION:DISPERSIONS_SUCCESS_BODY_INSTANT'}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

EmployeesScreen.propTypes = {};

export default EmployeesScreen;
