import { toast } from 'react-toastify';
import { DEFAULT_SHORT_TOAST_DURATION } from '@globalConstants';
import 'react-toastify/dist/ReactToastify.css';

export const options = {
  autoClose: DEFAULT_SHORT_TOAST_DURATION,
  position: 'bottom-right',
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};
const Snackbar = {
  show: (text, customOptions) => {
    toast(text, customOptions);
  },
  error: (text, customOptions) => {
    toast.error(text, customOptions);
  },
  warn: (text, customOptions) => {
    toast.warn(text, customOptions);
  },
};
export default Snackbar;
