import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip, Typography, Chip as MuiChip, SxProps } from '@mui/material';
import styles from './styles';

interface ChipProps {
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  label: string;
  tooltip?: string;
  size?: 'small' | 'medium';
  variant?: 'filled' | 'outlined';
  sx?: SxProps;
}
/**
 * @param {string} color - The color of the chip
 * @param {string} label - The text of the chip (i18n alias)
 * @param {string} [tooltip] - A tooltip to add when hovering over the chip
 * @returns
 */
const Chip = ({ color, label, tooltip, variant, size, sx }: ChipProps) => {
  const { t } = useTranslation();

  const isChipClickable = Boolean(tooltip);

  const ChipComponent = (
    <MuiChip
      sx={{ ...sx, ...styles.chip(isChipClickable) }}
      color={color}
      variant={variant}
      label={<Typography variant='h5'>{t(label)}</Typography>}
      size={size}
    />
  );

  if (tooltip) {
    return (
      <Tooltip title={t(tooltip)} componentsProps={{ tooltip: { sx: styles.tooltip } }}>
        {ChipComponent}
      </Tooltip>
    );
  }

  return ChipComponent;
};

Chip.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  size: PropTypes.string,
  sx: PropTypes.object,
  variant: PropTypes.string,
};

export default Chip;
