const styles = {
  main: {
    flexGrow: 1,
    py: 8,
  },
  stack: {
    gap: '9px',
    flexDirection: 'row',
  },
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  nameMaxWidth: {
    maxWidth: '190px',
  },
  emailMaxWidth: {
    maxWidth: '200px',
  },
  walletMaxWidth: {
    maxWidth: '190px',
  },
  productMaxWidth: {
    maxWidth: '190px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  cuitMaxWidth: {
    maxWidth: '100px',
  },
};
export default styles;
