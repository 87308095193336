const styles = {
  dropContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px dashed',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    borderColor: ({ palette }) => palette.primary.light,
    borderRadius: '0.5rem',
    padding: '3rem',
  },
  selectionInfo: { display: 'flex', gap: '0.5rem', alignItems: 'center' },
  selectionButtonBox: { display: 'flex', justifyContent: 'center' },
};
export default styles;
