import axios from 'axios';
import { TIMEOUT_LIMIT } from '@globalConstants';
import { attachCancelToken } from '../interceptors';

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: TIMEOUT_LIMIT,
};

const apiClient = axios.create(axiosConfig);

apiClient.interceptors.request.use(attachCancelToken(TIMEOUT_LIMIT));

export default apiClient;
