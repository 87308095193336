import axios from 'axios';
import {
  blockError,
  allowError,
  evaluateStaticEndpoints,
  evaluateDynamicEndpoints,
  defaultErrorHandler,
  timeoutHandler,
  pushCancellableRequest,
} from './helpers';
import { apiClient } from './instances';

export const CancelToken = axios.CancelToken;

let errorInterceptorId = null;
let currentTokenInterceptorId = null;

/**
 * Attaches Authorization header to all requests
 * If an interceptor already exists with the same id, removes it.
 * @param {object} config Config object of the outgoing request
 */
export const attachCurrentToken = (token) => {
  if (currentTokenInterceptorId) {
    apiClient.interceptors.request.eject(currentTokenInterceptorId);
  }

  currentTokenInterceptorId = apiClient.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  });
};

/**
 * Adds an error interceptor to handle Axios response errors.
 * @param {function} showErrorSnackbar - The function to display the error snackbar.
 */
export const addErrorInterceptor = (showErrorSnackbar) => {
  errorInterceptorId = apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      errorInterceptor(error, showErrorSnackbar);
      return Promise.reject(error);
    }
  );
};

/**
 * Removes the error interceptor. This guarantees that there is no unnecessary interference or error handling in components that no longer require that functionality
 */
export const removeErrorInterceptor = () => {
  apiClient.interceptors.response.eject(errorInterceptorId);
};

/**
 * This interceptor is responsible for the following things:
 *  - Handling timeouts (determines if it was caused by disconnection)
 *  - Recognizing when a request was cancelled by the user (by navigating away)
 *  - Check if the received error is in any endpoint registered in the registeredEndpoints file
 * to let it pass or, conversely, stop it and display generic snackbar.
 * -If the token is invalid, send param with true value in the forcelogout function so that the logout request is not executed
 * @param  {object} error The received error, containing the response (most of the time).
 * @returns {Promise.reject(error || undefined)}
 */
export const errorInterceptor = async (error, showErrorSnackbar) => {
  if (!error.response) {
    await timeoutHandler(error, showErrorSnackbar);
    return blockError();
  }

  const errorInfo = {
    url: error.response?.config?.url,
    method: error.response?.config?.method?.toLowerCase(),
    status: error.response?.status,
    code: error.response?.data?.code,
    params: error.response?.config?.params,
    requestData: error.response?.config?.data,
    headers: error.response?.config?.headers,
    _retry: error.response?.config?._retry,
  };

  const isHandled = evaluateStaticEndpoints(errorInfo) || evaluateDynamicEndpoints(errorInfo);

  if (isHandled) {
    return allowError(error);
  } else {
    defaultErrorHandler(error.response, showErrorSnackbar);
    return blockError();
  }
};

/**
 * Attaches a new CancelToken to every axios request promise.
 * - Push cancel function to cancellableRequests array, so we can cancel it when we want
 * - Manually cancel the request (force a timeout) after a period defined by timeoutLimit, because in certain conditions Axios will bug out and never stop waiting for a response.
 * @param {int} timeoutLimit Timeout value for the outgoing request
 * @returns the interceptor function, which returns the request with a forced expiration time (timeoutLimit)
 */
export const attachCancelToken = (timeoutLimit) => (config) => {
  config.cancelToken = new CancelToken((cancelRequest) => {
    if (!config.notCancellable) {
      pushCancellableRequest(cancelRequest);
    }
    setTimeout(cancelRequest, timeoutLimit);
  });
  return config;
};
