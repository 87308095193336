import PropTypes from 'prop-types';
import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import styles from './styles';

/**
 * A placeholder for loading tables and lists
 * @param {object} props
 * @param {object} props.length The amount of placeholder rows
 * @param {...any} props Props to be passed to the parent MUI grid
 */
const ListPlaceholder = ({ length, ...props }) => {
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const direction = smUp ? 'row' : 'column';

  return [...Array(length)].map((_item, key) => (
    <Grid key={key} direction={direction} container spacing={smUp ? 2 : 0} {...props}>
      <Grid xs={3} item>
        <Skeleton sx={styles.skeleton} height='45px' />
      </Grid>
      <Grid xs={9} item>
        <Skeleton sx={styles.skeleton} height='45px' />
      </Grid>
    </Grid>
  ));
};

ListPlaceholder.propTypes = {
  length: PropTypes.number,
};

export default ListPlaceholder;
