import { SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from '@globalConstants';

const styles = {
  navbar: {
    zIndex: (theme) => theme.zIndex.appBar,
    backdropFilter: 'blur(6px)',
    width: {
      lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
    },
    position: 'fixed',
    left: {
      lg: `${SIDE_NAV_WIDTH}px`,
    },
    minHeight: TOP_NAV_HEIGHT,
    display: 'flex',
    padding: '16px',
    flexDirection: 'row',
    justifyContent: 'items-between',
  },
  profileDropdownContainer: {
    display: 'flex',
    width: '100%',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
  },
  navLinksContainer: { display: 'flex', flexDirection: 'row', width: '100%' },
  avatar: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    height: 40,
    width: 40,
  },
};

export default styles;
