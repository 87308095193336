/**
 * parse an array of categories to a string, for example:
 * ["SUPERMERCADO", "FARMACIA", "GENERIC"] => Supermercado, Farmacia e Ilimitado
 * ["SUPERMERCADO", "FARMACIA"] => Supermercado y Farmacia
 */
export default (categories) => {
  if (!categories || categories.length === 0) return '';
  const formattedCategories = categories.map((category) => {
    if (category === 'GENERIC') {
      return 'Ilimitado';
    }
    return category.charAt(0) + category.substring(1).toLocaleLowerCase();
  });

  if (formattedCategories.length === 1) {
    return formattedCategories[0];
  }

  const lastCategory = formattedCategories.pop();
  return `${formattedCategories.join(', ')} ${
    lastCategory === 'Ilimitado' ? 'e' : 'y'
  } ${lastCategory}`;
};
