import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import NavItemIcons from './NavItemIcons';
import styles from './styles';

/**
 * @param {object} props
 * @param {boolean} props.active
 * @param {boolean} props.disabled
 * @param {boolean} props.external
 * @param {React.ReactNode} props.icon
 * @param {string} props.path
 * @param {string} props.title
 * @param {boolean} props.isSubItem
 * @param {boolean} props.hasSubMenus
 * @param {boolean} props.isNew
 * @param {boolean} props.isOpen
 * @param {function} props.onClick
 * @param {function} props.validateRenderNavItem
 * @returns {React.Component} Custom navbar button
 */
const SideNavItem = ({
  active = false,
  disabled,
  icon,
  path,
  title,
  onClick,
  isOpen,
  isSubItem = false,
  hasSubMenus = false,
  isNew = false,
  validateRenderNavItem,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const showNavItem = validateRenderNavItem ? validateRenderNavItem() : true;

  /**
   * @param {node} Component The icon component
   * @returns Renders the icon, with style props
   */
  const renderIcon = (Component) => <Component color={palette.primary.main} size='1.5rem' />;

  const buttonBase = (
    <ButtonBase sx={styles.buttonBase(active, isSubItem)} disabled={disabled} onClick={onClick}>
      {icon && renderIcon(icon)}
      <Box sx={styles.boxText}>
        <Typography
          fontWeight={active ? 600 : 400}
          color={active ? palette.primary.main : palette.gray[90]}
          variant={active ? 'h4' : 'body'}
        >
          {t(title)}
        </Typography>
        <NavItemIcons isNew={isNew} hasSubMenus={hasSubMenus} active={active} isOpen={isOpen} />
      </Box>
    </ButtonBase>
  );

  if (!showNavItem) {
    return null;
  }

  if (hasSubMenus) {
    return <li>{buttonBase}</li>;
  }

  return (
    <li>
      <Link to={path}>{buttonBase}</Link>
    </li>
  );
};

SideNavItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  isSubItem: PropTypes.bool,
  hasSubMenus: PropTypes.bool,
  isNew: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  validateRenderNavItem: PropTypes.func,
};
export default SideNavItem;
