import { SystemStyleObject } from '@mui/system';
import { NubiTheme } from '@theme';

type CustomStyle =
  | SystemStyleObject<NubiTheme>
  | ((theme: NubiTheme) => SystemStyleObject<NubiTheme>);

interface TemplateLinkProspect {
  link: CustomStyle;
  box: CustomStyle;
  spinner: CustomStyle;
}

const styles: TemplateLinkProspect = {
  link: {
    color: ({ palette }) => palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignSelf: 'flex-start',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)!important',
  },
};

export default styles;
