import useUser from './useUser';

/**
 * Custom hook to manage user roles.
 * @returns {{ validateRoles: (includeRoles: string | string[], excludeRoles?: string | string[]) => boolean }} - An object containing the `validateRoles` function.
 */
export function useRoles() {
  const { user } = useUser();

  /**
   * Function to check if user has the required role(s).
   * @param {string|array} find - Role(s) to find.
   * @returns {boolean} - True if user has the required role(s), false otherwise.
   */
  const isValidRole = (find) =>
    typeof find === 'string'
      ? user.roles.includes(find)
      : find.some((role) => user.roles.includes(role));

  /**
   * Function to validate if user has the required role(s) and exclude certain roles.
   * @param {string|array} includeRoles - Roles to include for validation.
   * @param {string|array} excludeRoles - Roles to exclude from validation.
   * @returns {boolean} - True if user meets the conditions, false otherwise.
   */
  const validateRoles = (includeRoles, excludeRoles) => {
    const shouldInclude = !includeRoles || isValidRole(includeRoles);
    const shouldExclude = excludeRoles && isValidRole(excludeRoles);

    return shouldInclude && !shouldExclude;
  };

  return { validateRoles };
}

export default useRoles;
