import { useState } from 'react';
import i18n from '@i18n';
import { apiClient } from '@config/api';

/**
 * Custom hook to manage create a xlsx file..
 * @returns {object} Object containing data, loading state, errors, and functions for creating and confirming prospects.
 */
function useCreateXlsx() {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Function to create a xlsx file.
   * @param {object} body - The request body for creating the file.
   * @returns {Promise<object>} A Promise that resolves with the response data once the mutation is complete.
   */
  const createXlsx = async (body) => {
    setIsloading(true);
    setError(null);
    try {
      const file = body.map(({ firstName, lastName, cuil, product, categories }) => ({
        Nombre: firstName,
        Apellido: lastName,
        CUIL: cuil,
        Monto: '',
        Producto: product,
        Rubro: categories
          .split(',')
          .map((category) => i18n.t(`CATEGORIES:${category}`))
          .join(','),
      }));
      const { data } = await apiClient.post('/s3/create-xlsx', { file }, { responseType: 'blob' });
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'dispersion.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsloading(false);
    } catch (error) {
      setError(error);
      setIsloading(false);
    }
  };

  return { isLoading, error, createXlsx };
}

export default useCreateXlsx;
