import { UseDownloadLink } from '@hooks';
import { URL_EXPIRATION_TIME_REFRESH } from './constants';

/**
 * Obtains invoices download ling for a segment during a given period
 * @param {string} segment
 * @param {string} year
 * @param {string} month
 */
function useInvoices({ segment, year, month }, options = {}) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  const requestsCurrentPeriod = currentMonth === Number(month) && currentYear === year;

  /**
   * Gets URL with params, or stops the request if a param is missing
   * @returns SWR url/key or false
   */
  const getKey = () => {
    const hasSelectedParams = segment && year && month;

    if (!hasSelectedParams) {
      return false;
    }

    if (requestsCurrentPeriod) {
      return `/s3/zipped-vouchers?entityId=${segment}`;
    } else {
      return `/s3/voucher?entityId=${segment}&time=${year}${month}`;
    }
  };

  const props = UseDownloadLink(getKey, {
    ...options,
    refreshInterval: URL_EXPIRATION_TIME_REFRESH,
    refreshWhenHidden: true,
  });

  return props;
}

export default useInvoices;
