import PropTypes from 'prop-types';
import { Box, TableCell, Tooltip } from '@mui/material';
import styles from './styles';
/**
 * @param {string} tooltipText if theres a string, then the tooltip will show it
 * @param {node} children content to show
 * @param {boolean} isShortened if true, then the content has a max width of 150
 * @param {boolean} isTableCell if true, then the container will be a tablecell
 * @returns component with optional tooltip
 */
const ConditionalTooltip = ({
  tooltipText,
  children,
  isShortened = false,
  isTableCell = false,
}) => {
  const content = tooltipText ? (
    <Tooltip title={tooltipText} placement='top' arrow>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );

  if (isTableCell) {
    return <TableCell sx={isShortened ? styles.container : {}}>{content}</TableCell>;
  }

  return <Box sx={isShortened ? styles.container : {}}>{content}</Box>;
};

ConditionalTooltip.propTypes = {
  tooltipText: PropTypes.string,
  children: PropTypes.node,
  isShortened: PropTypes.boolean,
  isTableCell: PropTypes.boolean,
};

export default ConditionalTooltip;
