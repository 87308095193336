/**
 * Takes a valid Date initializer
 * @param {Date} date The date initializer
 * @param {object} [options]
 * @param {boolean} [options.dateOnly] Should only return date (eg: 22/10/2003)
 * @param {boolean} [options.showSeconds] Should return seconds
 * @returns {string | null} the formatted date string or null (if init is falsey or invalid)
 */
export default function formatDate(
  dateInit,
  { dateOnly, showSeconds } = { dateOnly: false, showSeconds: false }
) {
  if (!dateInit) {
    return null;
  }

  const date = new Date(dateInit);

  if (dateOnly) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  let formattedDate = date.toLocaleString();

  if (!showSeconds) {
    formattedDate = formattedDate.slice(0, -3);
  }

  return formattedDate !== 'Invalid Date' && formattedDate;
}
