import {
  ACCOUNT_NUMBER_FILTER,
  DATE_PICKERS,
  FILTER_FIELDS,
} from 'screens/employeeDetails/tabs/movementsTab/constants';
import { usePagination } from '@hooks';
import { dateToIsoString } from '@utils';

/**
 * Data returned by the hook
 * @typedef {object} PaginationHookResult
 * @property {object} data - The response data from the API.
 * @property {boolean} isLoading - Indicates whether the data is currently being loaded. (from SWR)
 * @property {Error} error - The error object, if an error occurred during the request. (from SWR)
 * @property {boolean} isValidating - Indicates whether the data is currently being revalidated. (from SWR)
 * @property {function} handleRowsPerPageChange - Function to handle the change of rows per page.
 * @property {function} handlePageChange - Function to handle the change of page.
 * @property {boolean} filtersOpen - Indicates whether the filters are open.
 * @property {function} setFiltersOpen - Function to update the state of filtersOpen.
 * @property {function} onFiltersFormSubmit - Function to handle the submission of the filters form.
 */

/**
 *
 * A reusable SWR hook for paginated search.
 * @param {string} url - The API endpoint URL.
 * @param {object} options - Options for pagination or search.
 * @param {object} options.params - Key-value object containing filtering/data params of the request.
 * @param {function} options.setURLparams - Function to update the URL parameters.
 * @returns {PaginationHookResult} - An object containing the following properties and functions:
 */
const useMovements = (clientId, { params, setURLparams }) => {
  const { onFiltersFormSubmit, ...paginationProps } = usePagination(
    `/transactions/userMovements/${clientId}`,
    {
      params,
      setURLparams,
      paramKeys: [ACCOUNT_NUMBER_FILTER, ...DATE_PICKERS, ...FILTER_FIELDS].map(
        (field) => field.name
      ),
      revalidateOnFocus: false,
    }
  );

  /**
   * Adds custom format to the date from form
   */
  function customSubmit(values) {
    onFiltersFormSubmit({
      ...values,
      toDate: dateToIsoString(values.toDate),
      fromDate: dateToIsoString(values.fromDate),
    });
  }

  return { onFiltersFormSubmit: customSubmit, ...paginationProps };
};

export default useMovements;
