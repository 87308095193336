//import { blue } from 'theme/colors';

const styles = {
  scrollableContainer: { overflow: 'auto' },
  tableHead: {
    '& > .MuiCheckbox-root': {
      padding: 0,
    },
  },
  tableContainer: { width: '100%', display: 'table', tableLayout: 'fixed' },
  tableRow: ([clickleable, isSelected]) => ({
    cursor: clickleable ? 'pointer' : 'default',
    backgroundColor: ({ palette }) => (isSelected ? `${palette.neutral[200]}` : 'transparent'),
    '& > .MuiTableCell-root': {
      '& > .MuiCheckbox-root': {
        padding: 0,
      },
    },
  }),
  selectedRows: {
    padding: '1em',
    fontSize: '0.875rem',
    gap: '0.375rem',
    display: 'flex',
    alignItems: 'center',
  },
  containerRows: {
    display: 'flex',
    width: '100%',
  },
  paginationContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  justifyContentRight: { justifyContent: 'right' },
  styleButton: {
    box: {
      height: '22px',
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'primary.main',
      fontSize: '0.875rem',
    },
  },
};

export default styles;
