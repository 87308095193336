import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Alert,
  FormControl,
  InputLabel,
  Select as MaterialSelect,
  ListItemButton,
  Checkbox,
  FormHelperText,
  MenuItem,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { IconChevronDown } from '@tabler/icons-react';
import { Button } from '@atoms';
import { LOADING_OPTIONS_MESSAGE, ERROR_FETCHING_OPTIONS } from './constants';
import styles from './styles';

/**
 *  @param {string=} label The label of the input
 *  @param {string} value input value
 *  @param {string=} size input size
 *  @param {boolean=} error If true, the label is displayed in an error state.
 *  @param {(text: string) => void | Promise<void>} onChange Callback fired when the value is changed.
 *  @param {(text: string) => void | Promise<void>=} onBlur Callback fired when the element loses focus.
 *  @param {(field: string, value: any, shouldValidate?: boolean) => void=} setFieldValue Callback fired when press cancel button
 *  @param {string=} variant The variant to use
 *  @param {object=} sx The system prop that allows defining system overrides as well as additional CSS styles
 *  @param {string=} emptyOption Text of the empty valued option. If not provided, no empty valued option will render
 *  @param {boolean=} multiple If true, the select allows multiple selection and the value will be an array
 *  @param {string=} helperText - Additional helper text to display below the select element
 *  @param {boolean=} isLoadingConfirmButton
 *  @param {boolean=} showOpenModal if true, show the opened modal
 *  @param {() => void | Promise<void>} customOnConfirmClick custom function to execute on confirm button
 *  @param {() => void} customOnCancelClick custom function to execute on cancel button
 * @returns {React.ReactElement}
 */
const SelectFetch = ({
  name,
  label,
  value,
  size = 'medium',
  getOptions,
  parseOption,
  error,
  onChange,
  variant = 'outlined',
  emptyOption,
  disabled = false,
  multiple = false,
  placeholder = 'COMMON:SELECT_PLACEHOLDER',
  helperText,
  setFieldValue,
  customOnConfirmClick,
  isLoadingConfirmButton = false,
  customOnCancelClick,
  showOpenModal = false,
}) => {
  const { t } = useTranslation();
  const { data, isLoading: loadingOptions } = getOptions();
  const selectOptions = data && (parseOption ? data.map((item) => parseOption(item, value)) : data);
  const { typography, palette } = useTheme();
  const [openList, setOpenList] = useState(showOpenModal);
  const [customEvent, setCustomEvent] = useState({});
  const [latestValues, setLatestValues] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const initialValue = useRef(value);

  const placeholderRender = (
    <Typography variant='h4' color={styles.placeholderColor(isFocused)}>
      {t(placeholder)}
    </Typography>
  );

  /**
   * Render value for multiple select
   * @param {Array} selected - The selected values
   */
  const renderValueMultiple = (selected, multiple) => {
    const placeholderResponse = placeholder ? placeholderRender : undefined;
    if (selected && selectOptions) {
      if (Array.isArray(selectOptions) && multiple) {
        const parseItems = selectOptions.reduce((acc, curr) => {
          if (selected.includes(curr.value)) {
            acc.push(t(curr.text));
          }
          return acc;
        }, []);
        return parseItems.length > 0 ? parseItems.join(', ') : placeholderResponse;
      } else {
        return (
          selectOptions.find((option) => option.value === selected)?.text || placeholderResponse
        );
      }
    }
    return placeholderResponse;
  };

  /**
   * parsedLastestValues - parsed event value
   * @param {Object} e - Event object.
   */
  const parsedLastestValues = (e) => {
    const selectedOptions = e.target.value;
    const lastOption = selectedOptions[selectedOptions.length - 1];
    if (lastOption !== 'GENERIC' && selectedOptions.includes('GENERIC')) {
      e.target.value = selectedOptions.filter((option) => option !== 'GENERIC');
    } else if (lastOption === 'GENERIC' && selectedOptions.includes('GENERIC')) {
      e.target.value = ['GENERIC'];
    }
    return e;
  };

  /**
   * multipleHandleChange - Handle form event
   * @param {Object} e - Event object.
   */
  const multipleHandleChange = (e) => {
    const customEvent = parsedLastestValues(e);
    onChange(e);
    setCustomEvent(customEvent);
  };

  /**
   * onCancel - handle cancel button
   */
  const onCancel = () => {
    if (multiple) {
      if (latestValues?.target?.value?.length > 0) {
        onChange(latestValues);
      } else {
        setFieldValue
          ? setFieldValue(name, initialValue.current)
          : onChange({ target: { value: initialValue.current } });
      }
    }
    setOpenList(false);
    if (customOnCancelClick) {
      customOnCancelClick();
    }
  };

  /**
   * handleChange - handle form event
   * @param {Object} e - Event object.
   */
  const handleChange = (e) => {
    if (multiple) {
      multipleHandleChange(e);
    } else {
      onChange(e);
      setOpenList(false);
    }
  };

  /**
   * onConfirm - handle confirm button
   */
  const onConfirm = () => {
    setOpenList(false);
    setLatestValues(customEvent);
    if (customOnConfirmClick) {
      customOnConfirmClick();
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink={true} error={error}>
        {t(label)}
      </InputLabel>
      <MaterialSelect
        name={name}
        SelectDisplayProps={{
          style: styles.select({ typography }),
        }}
        MenuProps={{
          MenuListProps: { sx: styles.menuList },
        }}
        IconComponent={(props) => (
          <IconChevronDown
            {...props}
            style={{ top: 'calc(50% - 0.7em)', color: palette.primary.main }}
          />
        )}
        onFocus={() => setIsFocused(true)}
        onMouseEnter={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMouseLeave={() => setIsFocused(false)}
        label={t(label)}
        notched={Boolean(label)}
        variant={variant}
        value={value}
        size={size}
        error={error}
        onChange={handleChange}
        displayEmpty
        disabled={disabled}
        multiple={multiple}
        renderValue={(selected) => renderValueMultiple(selected, multiple)}
        onOpen={() => setOpenList(true)}
        onClose={onCancel}
        open={openList}
      >
        {selectOptions
          ? [
              Boolean(emptyOption) && <ListItemButton value={''}>{t(emptyOption)}</ListItemButton>,
              selectOptions.map((option, key) => {
                return multiple ? (
                  <MenuItem key={option.value} value={option.value} style={styles.menuItem}>
                    <Checkbox checked={value.indexOf(option.value) > -1} />
                    <ListItemText primary={t(option.text)} />
                  </MenuItem>
                ) : (
                  <MenuItem disabled={option.disabled || false} value={option.value} key={key}>
                    {t(option.text)}
                  </MenuItem>
                );
              }),
              multiple && (
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  width='100%'
                  gap={1}
                >
                  <Button text={t('COMMON:CANCEL')} color='error' onClick={onCancel} />
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={onConfirm}
                    text={t('COMMON:CONFIRM')}
                    isLoading={isLoadingConfirmButton}
                  />
                </Box>
              ),
            ]
          : [
              value ? (
                <ListItemButton key={0} value={value}>
                  {value}
                </ListItemButton>
              ) : (
                Boolean(emptyOption) && (
                  <ListItemButton title={emptyOption} value={''}>
                    {t(emptyOption)}
                  </ListItemButton>
                )
              ),
              loadingOptions ? (
                <ListItemButton disabled>{LOADING_OPTIONS_MESSAGE}</ListItemButton>
              ) : (
                <Alert severity='error' variant='filled'>
                  {ERROR_FETCHING_OPTIONS}
                </Alert>
              ),
            ]}
      </MaterialSelect>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectFetch;

SelectFetch.propTypes = {
  name: PropTypes.string,
  emptyOption: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  getOptions: PropTypes.func,
  parseOption: PropTypes.func,
  size: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  setFieldValue: PropTypes.func,
  customOnConfirmClick: PropTypes.func,
  isLoadingConfirmButton: PropTypes.bool,
  customOnCancelClick: PropTypes.func,
  showOpenModal: PropTypes.bool,
};
