import CancelIcon from '@mui/icons-material/Cancel';
import { IconCircleCheckFilled, IconAlertTriangleFilled } from '@tabler/icons-react';

export const VARIANTS = {
  success: {
    icon: IconCircleCheckFilled,
    iconColor: (palette) => palette.success.main,
    iconBoxColor: (palette) => palette.success.light,
  },
  warning: {
    icon: IconAlertTriangleFilled,
    iconColor: (palette) => palette.warning.main,
    iconBoxColor: (palette) => palette.warning.light,
  },
  error: {
    icon: CancelIcon,
    iconColor: (palette) => palette.error.main,
    iconBoxColor: (palette) => palette.error.light,
  },
};
