/**
 * Logger class for using on differents modules
 *
 * @export
 * @class Logger
 */
export default class Logger {
  /**
   * Creates an instance of Logger.
   * @param {string} prefixKey A word displayed before any message coming from the same instance
   * @param {boolean | undefined} show
   *
   * @memberOf Logger
   * */
  constructor(prefixKey, show = true) {
    this.prefixKey = prefixKey;
    this.enabled = process.env.NODE_ENV !== 'production' && show;
  }

  /**
   * Returns a function with the prefix keyword attached to the logging function
   *
   * @param {any} loggingFunction
   * @returns
   *
   * @memberOf Logger
   */
  _addPrefix(loggingFunction) {
    return (...args) => loggingFunction(this.prefixKey, ':', ...args);
  }

  /**
   * Logs a message. Can be disabled if `show` parameter was passed as `false` at constructor
   *
   * @param {any} args
   *
   * @memberOf Logger
   */
  log(...args) {
    this.enabled && this._addPrefix(console.log)(...args);
  }

  /**
   * Logs a warning message. Can be disabled if `show` parameter was passed as `false` at constructor
   *
   * @param {any} args
   *
   * @memberOf Logger
   */
  warn(...args) {
    this.enabled && this._addPrefix(console.warn)(...args);
  }

  /**
   * Logs a error message
   *
   * @param {any} args
   *
   * @memberOf Logger
   */
  error(...args) {
    this._addPrefix(console.error)(...args);
  }
}
