const styles = {
  container: (responsive) => ({
    display: 'flex',
    backgroundColor: '#fff',
    gap: '0.5rem',
    padding: '0.75rem',
    borderRadius: '1rem',
    flexDirection: responsive ? 'column' : 'row',
    flexWrap: responsive ? 'wrap' : 'nowrap',
    alignContent: 'flex-start',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  }),
  button: {
    button: {
      padding: '0.5rem',
      fontSize: '12px',
      fontWeight: '400',
      color: 'gray.90',
      maxWidth: '200px',
      borderColor: ({ palette }) => palette.gray[90],
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    icon: (isSelected) => ({
      color: ({ palette }) => isSelected && palette.primary.main,
    }),
  },
  containerResponsive: (responsive) => ({
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    gap: '0.5rem',
    flexWrap: responsive ? 'wrap' : 'nowrap',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  }),
  rightButton: { flexShrink: 0, display: 'flex', alignSelf: 'flex-start' },
};

export default styles;
