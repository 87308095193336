const styles = {
  inputsContainer: {
    display: 'flex',
    gap: '0.5rem',
    flexWrap: 'wrap',
  },
  checkboxContainer: {
    display: 'flex',
    gap: '0.5rem',
    flexWrap: 'wrap',
  },
};
export default styles;
