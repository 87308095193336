import { create } from 'zustand';

const useGlobalErrorStore = create((set) => ({
  showError: false,
  setShowError: (showError) => set({ showError }),
  error: { title: '', description: '' },
  setError: (error) => set({ error }),
}));

export default useGlobalErrorStore;
