import { common } from '@mui/material/colors';
import { PaletteOptions, alpha } from '@mui/material/styles';
import {
  GrayColor,
  NeutralColor,
  blue,
  error,
  gray,
  info,
  neutral,
  success,
  warning,
  benefit,
  business,
  lunch,
} from './colors';

export interface SecondaryColor {
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  lightest: string;
}

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor extends Partial<SecondaryColor> {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  }

  interface Palette {
    neutral: NeutralColor;
    gray: GrayColor;
    benefit: PaletteColor;
    business: PaletteColor;
    lunch: PaletteColor;
  }

  interface PaletteOptions {
    neutral?: NeutralColor;
    gray?: GrayColor;
    benefit?: PaletteColor;
    business?: PaletteColor;
    lunch?: PaletteColor;
  }
}

/**
 *
 * @returns
 */
function createPalette(): PaletteOptions {
  return {
    action: {
      disabledBackground: blue.lightest,
      focus: blue.lightest,
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: '#F6F6F6',
      paper: common.white,
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    neutral,
    gray,
    primary: blue,
    success,
    text: {
      primary: gray[100],
      secondary: gray[70],
    },
    warning,
    benefit,
    business,
    lunch,
  };
}

export const palette = createPalette();
