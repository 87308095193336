import { useCompanyStore } from '@stores';
import useProductsByCompany from './useProductsByCompany';

/**
 * Obtains a list of products by company
 */
const useSelectedCompanyProducts = (allowRequest = true) => {
  const { selectedCompany } = useCompanyStore();
  const props = useProductsByCompany({ allowRequest, companyId: selectedCompany });

  return props;
};

export default useSelectedCompanyProducts;
