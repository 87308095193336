import useSWR from 'swr';

/**
 * A reusable SWR hook for paginated company details.
 */
const useDispersionDetails = (dispersionId, allowRequest = true) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && dispersionId ? `/s3/dispersionDetail/${dispersionId}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data: data || [], isLoading, error };
};

export default useDispersionDetails;
