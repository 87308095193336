import useSWR from 'swr';
/**
 * Obtains a list of segments
 */
const useSegments = (allowRequest = true) => {
  const { data, isLoading, error } = useSWR(() => (allowRequest ? '/client/B2B-Segments' : false), {
    revalidateOnFocus: false,
  });

  const sortedSegments = data?.sort((a, b) => a.name.localeCompare(b.name));

  return { data: sortedSegments, isLoading, error };
};

export default useSegments;
