const styles = {
  container: {
    display: 'flex',
    gap: '4rem',
    flexDirection: 'column',
    width: '26rem',
    justifyContent: 'center',
  },
  logo: { height: '2.5rem', alignSelf: 'flex-start' },
  buttonBox: { alignSelf: 'stretch' },
  button: { width: '100%' },
  helpButton: {
    button: { borderRadius: '2rem' },
    box: {
      position: 'fixed',
      bottom: '0.625rem',
      right: '1.25rem',
    },
  },
};
export default styles;
