// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import { defaultValues } from '@globalConstants';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env?.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env?.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env?.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env?.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  appId: process.env?.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env?.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const _INTERNAL_REMOTE_CONFIG_INSTANCE = getRemoteConfig(app);

// This is the way RemoteConfig Web assigs default values......
_INTERNAL_REMOTE_CONFIG_INSTANCE.defaultConfig = defaultValues;

export const remoteConfig = _INTERNAL_REMOTE_CONFIG_INSTANCE;
