// TODO Temporally disbled zenDesk
export const ZEN_DESK_ENABLED = false;

/**
 * Loads the Zendesk chat widget script asynchronously.
 *
 * This function creates a new script element and appends it to the document's
 * head to load the Zendesk chat widget. The script is loaded asynchronously
 * to avoid blocking page rendering. Once the script is loaded, the chat
 * widget is automatically hidden.
 *
 * @function
 */
export const loadZendeskScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.id = 'ze-snippet';
  script.src =
    'https://static.zdassets.com/ekr/snippet.js?key=0a6f6ad0-5fbc-4648-b62d-16ed3f88ffa9';
  document.head.appendChild(script);

  script.onload = function () {
    window.zE('messenger', 'hide');
  };
};
