import { create } from 'zustand';

const useClienteStore = create((set) => ({
  clientSelected: null,
  searchParams: null,
  setClientSelected: (client) => set({ clientSelected: client }),
  setSearchParams: (params) => set({ searchParams: params }),
}));

export default useClienteStore;
