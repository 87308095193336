const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: { md: '100%', lg: '90%', xl: '50%' },
  },
};

export default styles;
