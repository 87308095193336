import { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Stack, Grid, Typography } from '@mui/material';
import { Input, Button } from '@atoms';
import { useTranslationWithPrefix } from '@hooks';
import { BoxContainer } from '@layouts';
import { SelectFetch } from '@molecules';
import { SelectCompany, SelectSegment } from '@organisms';
import { FIELDS_CONFIG } from './constants';
import validationSchema from './schema';
import useProspectCreationSelectors from '../prospectUpload/useProspectCreationSelectors';

/**
 * QuickCreationModal component for adding a prospect from a Modal.
 *
 * @component
 * @param {Object} props - The properties of the component.+
 * @param {Object} initialValues -
 * @param {array} props.data - The data stored in the list.
 * @param {function} props.onClose - Callback function to handle modal closure.
 * @param {function} props.onChange - Callback function to handle modal on changed.
 * @returns {JSX.Element} QuickCreationModal component.
 */
const QuickCreationModal = ({ data, initialValues, onClose, onChange }) => {
  const { t } = useTranslationWithPrefix('PROSPECTS:QUICK_CREATION_MODAL');
  const { shouldShowSelectors, shouldShowCompanySelector, shouldShowSegmentSelector } =
    useProspectCreationSelectors();
  const moreThanOneRow = data.length > 0;

  /** Event to handle what the form does when it is submitted  */
  const onSubmit = (formValues) => {
    onChange(formValues);
    onClose();
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    resetForm,
    setFieldValue,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema(data),
    onSubmit,
  });

  useEffect(() => {
    resetForm({ values: initialValues });
  }, [resetForm, initialValues]);

  /** Event to close the modal when the user clicks the cancel button or outside the modal. */
  const handleClose = () => {
    onClose();
  };

  /** Event to handle form fields error  */
  const handleError = (field) => Boolean(errors[field]) && touched[field];

  /** Event to handle whether or not to show the error message  */
  const handleHelperText = (field, helperText = '') =>
    handleError(field) ? t(errors[field]) : helperText;

  return (
    <>
      <BoxContainer>
        <Typography variant='h3'>{t('TITLE')}</Typography>
        <Grid container spacing={3}>
          {shouldShowSelectors && (
            <>
              {shouldShowCompanySelector && (
                <Grid item xs={12} md={6}>
                  <SelectCompany placeholder={'COMMON:COMPANY'} disabled={moreThanOneRow} />
                </Grid>
              )}
              {shouldShowSegmentSelector && (
                <Grid item xs={12} md={6}>
                  <SelectSegment placeholder={'COMMON:SEGMENT'} disabled={moreThanOneRow} />
                </Grid>
              )}
            </>
          )}
          {FIELDS_CONFIG(data).map(({ name, label, type, multiple, getOptions, parseOption }) => (
            <Grid key={name} item xs={12} md={6}>
              {type === 'select' ? (
                <SelectFetch
                  name={name}
                  label={t(label)}
                  value={values[name]}
                  error={handleError(name)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  parseOption={parseOption}
                  helperText={handleHelperText(name)}
                  getOptions={getOptions}
                  multiple={multiple}
                  setFieldValue={setFieldValue}
                />
              ) : (
                <Input
                  name={name}
                  label={t(label)}
                  value={values[name]}
                  error={handleError(name)}
                  helperText={handleHelperText(name)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </BoxContainer>
      <Stack direction='row' gap={2} justifyContent='flex-end'>
        {moreThanOneRow && (
          <Button
            variant='outlined'
            color='primary'
            type='submit'
            onClick={handleClose}
            content={t('BUTTON_CANCEL_TEXT')}
          />
        )}
        <Button
          variant='contained'
          color='primary'
          type='button'
          onClick={() => handleSubmit()}
          content={t('BUTTON_SUBMIT_TEXT')}
        />
      </Stack>
    </>
  );
};

QuickCreationModal.propTypes = {
  data: PropTypes.array,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
export default QuickCreationModal;
