import { blue } from 'theme/colors';
import { MenuItem, Typography } from '@mui/material';
import { IconCheck } from '@tabler/icons-react';
import { useUserCompanies } from '@hooks';
import { useCompanyStore } from '@stores';
import styles from './styles';

/**
 * @returns Client user's companies list. User can select the one with which to operate.
 */
const ClientCompaniesList = () => {
  const { data } = useUserCompanies();
  const { selectedCompany, setSelectedCompany } = useCompanyStore();

  return data?.map((company, key) => (
    <MenuItem
      key={key}
      onClick={() => setSelectedCompany(company.id)}
      sx={styles.companiesMenuItems}
    >
      <Typography variant='body'>{company.description}</Typography>
      {selectedCompany === company.id && <IconCheck size='1rem' color={blue[400]} />}
    </MenuItem>
  ));
};

export default ClientCompaniesList;
