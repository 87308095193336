import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button, FieldList } from '@atoms';
import { useErrorSnackbar } from '@hooks';
import { Dialog, SelectFetch } from '@molecules';
import { apiClient } from '@config/api';
import {
  ASSIGN_PRODUCT_FIELDS_CONFIG,
  validationSchema,
  ALERT_TYPES,
  FIELD_NAMES,
} from './constants';

/**
 * Renders a modal to assign a product to users
 * @param {boolean} props.isOpen if modal is or not visible
 * @param {array} props.selectedUsers array of selected users
 * @param {() => void} props.setAssignProductOpen function to close the modal
 * @param {() => void} props.setAssignProductData function to set the result of the request, including error response
 */
const AssignProductModal = ({
  isOpen,
  selectedUsers,
  setAssignProductOpen,
  setAssignProductData,
  customer_id,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { showErrorSnackbar, ErrorSnackbar } = useErrorSnackbar();

  /**
   * Sends request to backend to assign product to users
   */
  const handleAssignProductSubmit = async (values) => {
    try {
      setIsLoading(true);
      const users_id = selectedUsers.map((item) => item.walletId);
      let { data } = await apiClient.post('/client/assign-product', {
        users_id,
        product_name: values.product,
        categories_name: values.category,
        customer_id,
      });
      handleOnClose();
      let parsed = [];
      if (data.users_failed.length > 0) {
        parsed = data.users_failed.map((item) => ({
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          messages: [{ message: item.error_message, code: item.error_code }],
        }));
      }
      setAssignProductData({
        ...data,
        product: values.product,
        categories: values.category.join(', '),
        users_failed: parsed,
        alertType: parsed.length > 0 ? ALERT_TYPES.USERS_FAILED : ALERT_TYPES.SUCCESS,
      });
    } catch (error) {
      switch (error?.response?.status) {
        case 400:
          if (error.response.data.non_field_errors) {
            const result = error.response.data.non_field_errors.filter(
              (item) => item === 'Users mismatching Customer'
            );
            if (result.length > 0) {
              setAssignProductData({
                error_different_customers: true,
                alertType: ALERT_TYPES.DIFFERENT_CUSTOMERS,
              });
            } else {
              showErrorSnackbar(t('COMMON:ERRORS:DEFAULT_ERROR'));
            }
          } else {
            showErrorSnackbar(t('COMMON:ERRORS:DEFAULT_ERROR'));
          }
          break;
        default:
          if (error) {
            showErrorSnackbar(t('COMMON:ERRORS:DEFAULT_ERROR'));
          }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    errors,
    values,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      product: '',
      category: [],
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: handleAssignProductSubmit,
  });

  /**
   * Parses categories options into an object with text and value fields.
   * @param {object} item - The options object.
   * @param {string} item.name - The value to be used as the label and value.
   * @returns {object} - An object containing the text and value fields.
   */
  const parseCategoryOption = (item) => ({ text: `CATEGORIES:${item?.name}`, value: item?.name });

  /**
   Parses products options into an object with text and value fields.
   * @param {string} value - The value to be used as the label and value.
   * @returns {object} - An object containing the text and value fields.
   */
  const parseProductOption = (value) => ({ text: value, value });

  /** Event to handle form fields error  */
  const handleError = (field) => Boolean(errors[field]) && touched[field];

  /**
   * Close the modal and reset the form
   */
  const handleOnClose = () => {
    setAssignProductOpen(false);
    resetForm();
  };

  const disableButton = isLoading || !isValid;

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleOnClose}
        dialogTitle={
          <Box>
            <Typography variant='h4'>{t('EMPLOYEES:ASSIGN_PRODUCT:MODAL_TITLE')}</Typography>
            <Typography variant='body'>
              {t('EMPLOYEES:ASSIGN_PRODUCT:MODAL_DESCRIPTION')}
            </Typography>
          </Box>
        }
        dialogContent={
          <FieldList>
            {ASSIGN_PRODUCT_FIELDS_CONFIG.map(({ name, label, id, multiple, getOptions }) => (
              <SelectFetch
                name={name}
                placeholder={`EMPLOYEES:${label}`}
                value={values[name]}
                error={handleError(name)}
                onChange={handleChange}
                onBlur={handleBlur}
                parseOption={
                  name === FIELD_NAMES.PRODUCT ? parseProductOption : parseCategoryOption
                }
                key={id}
                multiple={multiple}
                getOptions={getOptions}
                setFieldValue={setFieldValue}
              />
            ))}
          </FieldList>
        }
        dialogActions={
          <>
            <Button
              text={t('COMMON:CANCEL')}
              color='error'
              onClick={handleOnClose}
              disabled={isLoading}
            />
            <Button
              variant='contained'
              color='primary'
              type='submit'
              isLoading={isLoading}
              disabled={disableButton}
              onClick={handleSubmit}
              text={t('COMMON:CONFIRM')}
            />
          </>
        }
      ></Dialog>
      <ErrorSnackbar />
    </>
  );
};

AssignProductModal.propTypes = {
  isOpen: PropTypes.bool,
  setAssignProductOpen: PropTypes.func,
  selectedUsers: PropTypes.array,
  setAssignProductData: PropTypes.func,
  customer_id: PropTypes.number,
};

export default AssignProductModal;
