/**
 * Convert phrase to CamelCase. Ex: "HELLO world" -> "Hello World"
 * @param {string} phrase string to camelize.
 * @returns {string} string camelized
 */
export default function camelizeString(phrase) {
  return phrase
    .trim()
    .split(' ')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`)
    .join(' ');
}
