import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { IconCoin } from '@tabler/icons-react';
import { useSelectedCompanyDetails, useUser } from '@hooks';
import { CardWithIcon } from '@molecules';
import { parseAmount } from '@utils';
import styles from './CompanyBalanceCard.styles';

/**
 * CompanyBalanceCard is a component that displays the balance available for a selected company
 */
const CompanyBalanceCard = () => {
  const { t } = useTranslation();

  const { user } = useUser();
  const { data } = useSelectedCompanyDetails();

  return (
    <CardWithIcon
      cardStyle={!user?.isInternal ? styles.balanceOnlyCard : undefined} // Remove when unhiding the footer button
      subtitle={t('HOME:BALANCE_AVAILABLE')}
      icon={
        <Box sx={styles.iconBox}>
          <IconCoin color={'white'} size='2rem' />
        </Box>
      }
    >
      <Typography variant='h1'>
        {data?.balance ? parseAmount(data.balance) : t('COMMON:NO_BALANCE')}
      </Typography>
    </CardWithIcon>
  );
};

export default CompanyBalanceCard;
