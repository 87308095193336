import { useCallback, useState } from 'react';
import { apiClient } from 'config/api';

const INITIAL_STATES = {
  status: '',
  alertMessage: {},
};

/**
 * Custom hook to cancel a dispersion.
 * @returns {Object} The status of the cancellation request and the cancelDispersion function.
 */
function useCancelDispersion() {
  const [states, setStates] = useState(INITIAL_STATES);

  /**
   * Function to cancel a dispersion.
   * @param {string} companyId - The ID of the company.
   * @param {string} dispersionId - The ID of the dispersion.
   */
  const cancelDispersion = useCallback(async (companyId, dispersionId) => {
    setStates({ ...INITIAL_STATES, status: 'loading' });

    try {
      const { status } = await apiClient.delete(`/dispersion/${companyId}`, {
        params: { dispersionId },
      });

      if (status === 204) {
        setStates({
          status: 'success',
          alertMessage: {
            title: 'DISPERSIONS:CANCELLED:SUCCESS:TITLE',
            description: 'DISPERSIONS:CANCELLED:SUCCESS:DESCRIPTION',
          },
        });
      }
    } catch (error) {
      let alertMessage;
      const errorStatuses = [404, 500, 503, 410];
      if (error.response && errorStatuses.includes(error.response.status)) {
        alertMessage = {
          title: 'DISPERSIONS:CANCELLED:ERROR:TITLE',
          description: `DISPERSIONS:CANCELLED:ERROR:DESCRIPTION_${error.response.status}`,
        };
      } else {
        alertMessage = {
          title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
          description: 'COMMON:ERRORS:DEFAULT_ERROR',
        };
      }
      setStates({
        status: 'error',
        alertMessage,
      });
    }
  }, []);

  const showModal = states.status === 'success' || states.status === 'error';

  const isLoading = states.status === 'loading';

  /** Close the modal */
  const closeModal = () => {
    setStates(INITIAL_STATES);
  };

  return {
    status: states.status,
    alertMessage: states.alertMessage,
    isLoading,
    showModal,
    closeModal,
    cancelDispersion,
  };
}

export default useCancelDispersion;
