import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Alert, Box, List, Typography } from '@mui/material';
import { ListPlaceholder, PropertyListItem } from '@atoms';
import { useMovementDetails, useURLparams } from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { GoBackButton } from '@molecules';
import { ITEMS } from './constants';

/**
 * Movement details screen
 */
const MovementDetails = () => {
  const { id } = useParams();
  const { params } = useURLparams();

  const { isLoading, error, data } = useMovementDetails(id, params.clientId);

  const { t } = useTranslation();

  /**
   * Get the error message to display
   */
  function getErrorMessage(error) {
    switch (error?.response?.status) {
      case 404:
        return t('MOVEMENTS:ERRORS:NOT_FOUND');
      default:
        if (error) {
          t('MOVEMENTS:ERRORS:MOVEMENT_FETCH_GENERIC_ERROR');
        }
    }
  }

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <BoxContainer>
          <Typography variant='h4'>{t('MOVEMENTS:DETAILS_TITLE')}</Typography>
          {isLoading ? (
            <Box sx={{ mt: 3 }}>
              <ListPlaceholder length={ITEMS.length} />
            </Box>
          ) : data ? (
            <List>
              {ITEMS.map(({ label, getText }) => (
                <PropertyListItem
                  disablePadding
                  key={label}
                  label={label}
                  value={
                    getText(data) || (
                      <Typography sx={{ fontStyle: 'italic', fontSize: 15, color: 'neutral.400' }}>
                        {t('COMMON:NO_INFORMATION')}
                      </Typography>
                    )
                  }
                />
              ))}
            </List>
          ) : (
            <Alert variant='filled' severity='error'>
              {getErrorMessage(error)}
            </Alert>
          )}
        </BoxContainer>
        <GoBackButton variant='outlined' />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

MovementDetails.propTypes = {};

export default MovementDetails;
