import {
  IconLayoutDashboard,
  IconUsers,
  IconUsersPlus,
  IconUserPlus,
  IconPremiumRights,
  IconFileDollar,
  IconBuildingStore,
  IconCash,
  IconFileInvoice,
  IconUpload,
  IconForms,
  IconZoomMoney,
  IconReportMoney,
  IconMailForward,
  IconTags,
  IconListDetails,
  IconBuilding,
  // IconClipboardData,
} from '@tabler/icons-react';
import {
  OPERATIONS,
  CLIENT,
  CUSTOMER_SUPPORT,
  PRODUCT,
  ALL_ROLES,
  OPERATIONS_ANALYST,
  CLIENT_ANALYST,
  PRODUCT_ANALYST,
  SUPER_ADMIN,
  TECH,
  OPERATIONS_SUPERVISOR,
  CUSTOMER_SUPPORT_ANALYST,
  CORPORATE_CLIENT,
} from '@globalConstants';
import { useShowMenuByCorporateProduct } from '@hooks';
import AddClient from './addClients';
import AddCustomerProduct from './addCustomerProduct';
import AddSegments from './addSegments';
import ClientsScreen from './clients';
import CommercesAdminScreen from './commercesAdmin';
import ConsumptionList from './consumptionList';
import {
  DispersionDetails,
  FastDispersionScreen,
  FileDispersionsScreen,
  MyDispersionsScreen,
} from './dispersions';
import EmployeeDetailsScreen from './employeeDetails';
import EmployeesListScreen from './employeesList';
import ExchangeRatesScreen from './exchangeRates';
import HelpScreen from './help';
import HomeScreen from './home';
import Invoices from './invoices';
import LoginScreen from './login';
import MovementDetails from './movementDetails';
import MyBalance from './myBalance';
import ProspectCreationScreen from './prospectCreation';
import ProspectSendEmail from './prospectSendMail';
import ProspectUploadScreen from './prospectUpload';
import ReportDetails from './reportDetails';
export { default as SelectCompany } from './selectCompany';
export { default as LoadingScreen } from './loading';

const screens = [
  {
    path: '/',
    onMenu: true,
    title: 'Inicio',
    roles: ALL_ROLES,
    component: HomeScreen,
    icon: IconLayoutDashboard,
  },
  {
    path: '/ayuda',
    onMenu: false,
    title: 'Ayuda',
    roles: ALL_ROLES,
    component: HelpScreen,
  },
  { path: '/login', onMenu: false, roles: false, component: LoginScreen },
  {
    title: 'MENU:CLIENTS',
    path: '/clientes',
    icon: IconUsers,
    onMenu: true,
    roles: [
      PRODUCT,
      PRODUCT_ANALYST,
      OPERATIONS_ANALYST,
      CUSTOMER_SUPPORT,
      CUSTOMER_SUPPORT_ANALYST,
      OPERATIONS,
      SUPER_ADMIN,
      TECH,
      OPERATIONS_SUPERVISOR,
    ],
    component: ClientsScreen,
  },
  {
    title: 'MENU:EMPLOYEES',
    path: '/colaboradores',
    icon: IconUserPlus,
    onMenu: true,
    roles: [
      CLIENT,
      CORPORATE_CLIENT,
      PRODUCT,
      PRODUCT_ANALYST,
      OPERATIONS_ANALYST,
      CLIENT_ANALYST,
      CUSTOMER_SUPPORT,
      CUSTOMER_SUPPORT_ANALYST,
      OPERATIONS,
      SUPER_ADMIN,
      TECH,
      OPERATIONS_SUPERVISOR,
    ],
    component: EmployeesListScreen,
  },
  {
    title: 'MENU:BALANCE',
    path: '/saldo',
    icon: IconCash,
    onMenu: true,
    roles: [CLIENT, CORPORATE_CLIENT, CLIENT_ANALYST],
    component: MyBalance,
  },
  {
    title: 'MENU:CONSUMPTION:TITLE',
    icon: IconFileInvoice,
    onMenu: true,
    roles: [CLIENT, CORPORATE_CLIENT, CLIENT_ANALYST, OPERATIONS],
    validateShowMenu: useShowMenuByCorporateProduct,
    subMenus: [
      {
        title: 'MENU:CONSUMPTION:LIST',
        path: '/consumos',
        icon: IconFileInvoice,
        onMenu: true,
        roles: [CLIENT, CORPORATE_CLIENT, CLIENT_ANALYST, OPERATIONS],
        validateShowMenu: useShowMenuByCorporateProduct,
        component: ConsumptionList,
      },
      {
        title: 'MENU:CONSUMPTION:INVOICES',
        path: '/comprobantes',
        icon: IconFileInvoice,
        onMenu: true,
        roles: [CLIENT, CORPORATE_CLIENT, CLIENT_ANALYST, OPERATIONS],
        validateShowMenu: useShowMenuByCorporateProduct,
        component: Invoices,
      },
    ],
  },
  {
    title: 'MENU:DISPERSIONS:TITLE',
    path: '/dispersiones',
    icon: IconPremiumRights,
    onMenu: true,
    roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, CORPORATE_CLIENT, SUPER_ADMIN],
    subMenus: [
      {
        title: 'MENU:DISPERSIONS:FAST_DISPERSION',
        path: '/dispersiones/dispersion-rapida',
        icon: IconZoomMoney,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, SUPER_ADMIN],
        component: FastDispersionScreen,
      },
      {
        title: 'MENU:DISPERSIONS:FILE_DISPERSION',
        path: '/dispersiones/subir-archivo',
        icon: IconUpload,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, CORPORATE_CLIENT, SUPER_ADMIN],
        component: FileDispersionsScreen,
      },
      {
        title: 'MENU:DISPERSIONS:MY_DISPERSIONS',
        path: '/dispersiones/mis-dispersiones',
        icon: IconReportMoney,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, CORPORATE_CLIENT, SUPER_ADMIN],
        component: MyDispersionsScreen,
      },
      {
        title: 'MENU:DISPERSIONS:MY_DISPERSIONS',
        path: '/dispersiones/mis-dispersiones/:dispersionId',
        onMenu: false,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, CORPORATE_CLIENT, SUPER_ADMIN],
        component: DispersionDetails,
      },
    ],
  },
  {
    title: 'MENU:PROSPECTS:TITLE',
    path: '/prealta/rapida',
    icon: IconUsersPlus,
    onMenu: true,
    roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, SUPER_ADMIN],
    component: ProspectCreationScreen,
    subMenus: [
      {
        title: 'MENU:PROSPECTS:QUICK_CREATION',
        path: '/prealta/rapida',
        icon: IconForms,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, SUPER_ADMIN],
        component: ProspectCreationScreen,
      },
      {
        title: 'MENU:PROSPECTS:UPLOAD_FILE',
        path: '/prealta/subir-archivo',
        icon: IconUpload,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, SUPER_ADMIN],
        component: ProspectUploadScreen,
      },
      {
        title: 'MENU:PROSPECTS:SEND_EMAIL',
        path: '/prealta/enviar-email',
        icon: IconMailForward,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR, CLIENT, SUPER_ADMIN],
        component: ProspectSendEmail,
      },
    ],
  },
  // ! Hide this section for next release, should be displayed when the data team completes the power bi boards
  /*   {
    title: 'MENU:REPORTS',
    path: '/reportes',
    icon: IconClipboardData,
    onMenu: true,
    roles: [CLIENT],
    component: ReportDetails,
  }, */
  {
    path: '/reportes-operaciones',
    onMenu: false,
    roles: [OPERATIONS, OPERATIONS_SUPERVISOR, SUPER_ADMIN],
    component: ReportDetails,
  },
  {
    path: '/detalle-cliente/:username',
    onMenu: false,
    roles: [
      CLIENT,
      CORPORATE_CLIENT,
      PRODUCT,
      PRODUCT_ANALYST,
      OPERATIONS_ANALYST,
      CLIENT_ANALYST,
      CUSTOMER_SUPPORT,
      CUSTOMER_SUPPORT_ANALYST,
      OPERATIONS,
      SUPER_ADMIN,
      TECH,
      OPERATIONS_SUPERVISOR,
    ],
    component: EmployeeDetailsScreen,
  },
  {
    path: '/movimiento/:id',
    onMenu: false,
    roles: [
      CLIENT,
      CORPORATE_CLIENT,
      PRODUCT,
      PRODUCT_ANALYST,
      OPERATIONS_ANALYST,
      CLIENT_ANALYST,
      CUSTOMER_SUPPORT,
      CUSTOMER_SUPPORT_ANALYST,
      OPERATIONS,
      SUPER_ADMIN,
      TECH,
      OPERATIONS_SUPERVISOR,
    ],
    component: MovementDetails,
  },
  {
    title: 'MENU:CURRENCY_RATE',
    path: '/cotizaciones',
    roles: [OPERATIONS, OPERATIONS_ANALYST, OPERATIONS_SUPERVISOR, SUPER_ADMIN],
    onMenu: true,
    icon: IconFileDollar,
    component: ExchangeRatesScreen,
  },
  {
    title: 'MENU:COMMERCES',
    path: '/administrador-comercios',
    roles: [OPERATIONS, OPERATIONS_ANALYST, OPERATIONS_SUPERVISOR, SUPER_ADMIN],
    onMenu: true,
    icon: IconBuildingStore,
    component: CommercesAdminScreen,
  },
  {
    path: '/*',
    roles: ALL_ROLES,
    onMenu: false,
    component: HomeScreen, //TODO: Screen 404
  },
  {
    title: 'CLIENTS:CREATE_CLIENT_BUTTON',
    path: '/altas/cliente',
    icon: IconUsersPlus,
    roles: [OPERATIONS, OPERATIONS_ANALYST],
    onMenu: true,
    component: AddClient,
    subMenus: [
      {
        title: 'COMMON:COMPANY',
        path: '/altas/razon-social',
        icon: IconBuilding,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR],
        component: AddClient,
      },
      {
        title: 'MENU:ADD_SEGMENT',
        path: '/altas/segmentos',
        icon: IconTags,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR],
        component: AddSegments,
      },
      {
        title: 'EMPLOYEES:DETAIL:PRODUCTS',
        path: '/altas/productos',
        icon: IconListDetails,
        onMenu: true,
        roles: [OPERATIONS, OPERATIONS_SUPERVISOR],
        component: AddCustomerProduct,
      },
    ],
  },
];

export default screens;
