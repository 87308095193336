const styles = {
  spinner: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    color: 'primary',
    margin: '5rem auto',
  },
  toggleAddress: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'secondary.main',
      minWidth: '46px',
      '& > .MuiButton-startIcon': {
        margin: '0',
      },
      padding: '0.75rem 0.75rem',
    },
  },
  headerContent: {
    gap: 2,
    '& > .MuiCardHeader-content': {
      flex: 'none',
    },
  },
};

export default styles;
