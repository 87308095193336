import { gray } from './colors';
/**
 *
 * @returns
 */
const createTypography = () => {
  return {
    color: gray[90],
    letterSpacing: '-0.0175rem',
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    button: {
      fontWeight: 600,
    },
    h1: {
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: '140%',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: '140%',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '140%',
    },
    h3Regular: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '140%',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '140%',
    },
    h5: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '140%',
    },
    body: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '140%',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '140%',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '140%',
    },
  };
};

export const typography = createTypography();

export type Typography = typeof typography;
