import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { gray } from 'theme/colors';
import { Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { IconUserCircle, IconLogout } from '@tabler/icons-react';
import { useUser } from '@hooks';
import ClientCompaniesList from './ClientCompaniesList';
import styles from './styles';

/**
 * @param  {object} props
 *   @param {any} props.anchorEL
 *   @param {() => void | () => Promise<void>} props.onClose
 *   @param {boolean} props.open
 */
const AccountPopover = ({ anchorEl, onClose, open }) => {
  const { logout, user } = useUser();
  const { t } = useTranslation();

  /**
   */
  const handleSignOut = () => {
    logout();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={onClose}
      open={open}
      PaperProps={styles.paperProps}
    >
      <MenuList sx={styles.menuList}>
        <MenuItem disabled>
          <IconUserCircle size={'1.25rem'} color={gray[90]} />
          <Typography variant='body'>{t('MENU:MY_ACCOUNT')}</Typography>
        </MenuItem>
        {!user.isInternal && (
          <>
            <Divider sx={styles.divider} />
            <ClientCompaniesList />
          </>
        )}
        <Divider />
        <MenuItem onClick={handleSignOut}>
          <IconLogout size={'1.25rem'} color={gray[90]} />
          <Typography variant='body'>{t('MENU:SIGN_OUT')}</Typography>{' '}
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
export default AccountPopover;
