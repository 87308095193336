import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { useUser } from '@hooks';

/**
 * Custom hook to maintain the state of a component in sessionStorage using Zustand.
 * @param {string} key The key under which the state will be stored in localStorage.
 * @param {any} defaultValue The default value of the state if there is no value stored in localStorage.
 * @returns {[any, Function]} An array containing the current state and a function to update it.
 */
const useSessionStorageState = (key, defaultValue) => {
  const { user } = useUser();
  const useStore = create(
    persist(
      (set) => ({
        [key]: defaultValue,
        setState: (newState) => set({ [key]: newState }),
      }),
      {
        name: `${user.email}-${key}-storage`,
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  );

  return [useStore((state) => state[key]), useStore((state) => state.setState)];
};

export default useSessionStorageState;
