import { SystemStyleObject } from '@mui/system';
import { NubiTheme } from '@theme';

type CustomStyle =
  | SystemStyleObject<NubiTheme>
  | ((theme: NubiTheme) => SystemStyleObject<NubiTheme>);

interface CompanyBalanceCardStyles {
  iconBox: CustomStyle;
  balanceOnlyCard: CustomStyle;
}

const styles: CompanyBalanceCardStyles = {
  iconBox: ({ palette }) => ({
    justifyContent: 'center',
    display: 'flex',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: palette.primary.main,
    width: 'fit-content',
  }),
  balanceOnlyCard: {
    justifyContent: 'flex-start',
    gap: '1rem',
  },
};

export default styles;
