import { Trans } from 'react-i18next';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import { CLIENT_TRANSACTION_FILTER_FIELDS, CLIENT_TRANSACTION_TYPES } from '@globalConstants';
import i18n from '@i18n';
import { parseAmount, formatDate } from '@utils';
import { VALIDATE_DATE, VALIDATE_STRING } from '@validations';

export const TRANSACTION_DEBITS = ['CASHIN_PPCAAS', 'CASHIN_TJURIDICA'];

/**
 * @param {string} transactionType
 * @returns Receives the transaction type and returns the corresponding translation. If no translation available, returns the id.
 */
function getConcept(transactionType, movementType, accountNumber) {
  const translation =
    CLIENT_TRANSACTION_TYPES[
      transactionType === 'ADJUSTMENT' ? `ADJUSTMENT_${movementType}` : transactionType
    ];

  if (translation) {
    return <Trans i18nKey={translation} values={{ accountNumber }} />;
  }

  return transactionType;
}

export const COLUMNS = [
  { title: 'COMMON:DATE', content: (item) => formatDate(item.createdAt) },
  {
    title: 'COMMON:CONCEPT',
    content: (item) =>
      getConcept(item.customerTransactionType, item.movementType, item.sourceAccountNumber),
  },
  {
    title: 'COMMON:AMOUNT',
    content: (item) => {
      let sign = '+';
      let color = 'primary.light';

      if (item.movementType === 'DEBIT') {
        sign = '-';
        color = 'error.main';
      }

      return (
        <Typography variant='body2' color={color}>
          {`${sign} ${parseAmount(item.amount)}`}
        </Typography>
      );
    },
  },
  // TODO: UNCOMMENT WHEN BE FIX ISSUE WITH BALANCEAFTER DISPLAYING USER BALNACES INSTEAD OF CLIENT BALANCE {
  //   title: 'COMMON:TOTAL_BALANCE',
  //   content: (item) =>
  //     item.balanceAfter ? (
  //       <Typography variant='h5'>{parseAmount(item.balanceAfter)} </Typography>
  //     ) : (
  //       ''
  //     ),
  // },
];

export const DEFAULT_ROWS_PER_PAGE = 25;

export const validationSchema = Yup.object().shape({
  fromDate: VALIDATE_DATE.required(),
  toDate: VALIDATE_DATE.required(),
  transactionType: VALIDATE_STRING,
});

export const DATE_PICKER_PROPS = {
  size: 'medium',
  dateOnly: true,
  readOnly: false,
  disablePast: false,
  calendarViews: ['year', 'month', 'day'],
  fullWidth: true,
};

export const DATE_PICKERS = [
  { name: 'fromDate', label: 'COMMON:FROM_DATE' },
  { name: 'toDate', label: 'COMMON:TO_DATE' },
];

export const FILTER_FIELDS = [
  {
    label: i18n.t('COMMON:TYPE'),
    getInitialValue: () => '',
    name: 'customerTransactionType',
    inputValidation: VALIDATE_STRING.required(),
    inputType: 'select',
    items: CLIENT_TRANSACTION_FILTER_FIELDS,
    parseOption: (type) => ({ label: i18n.t(type[1]), value: type[0] }),
  },
];
