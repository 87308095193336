import { ChangeEvent, useMemo, useState } from 'react';
import { CustomerProduct } from 'helpers/hooks/useCustomerProduct';
import { useCustomerProduct } from '@hooks';
import { SelectFetch } from '@molecules';

interface SelectCustomerProductProps {
  customerProducts?: CustomerProduct[];
  onChange?: (customerProductsSelected: CustomerProduct[]) => void;
}

/**
 * SelectProduct component renders a form to select and add products.
 * It includes a dropdown to fetch and select products and a button to submit the selected products.
 *
 * @component
 * @param props - The component props
 * @param  props.customerProducts - The current selected value(s) of the SelectFetch component
 * @param  props.handleChange - Function to handle the change event
 * @returns The rendered component
 */
function SelectCustomerProduct({ customerProducts, onChange }: SelectCustomerProductProps) {
  const { data } = useCustomerProduct();
  const [customerProductsOption, setCustomerProductsOption] = useState<string[]>([]);
  const value = useMemo(
    () => (customerProducts ? customerProducts.map((cp) => cp.id) : customerProductsOption),
    [customerProducts, customerProductsOption]
  );

  /** Handle changes in select customer product  */
  const handleChange = (event: ChangeEvent & { target: { value: string[] } }) => {
    const customerProductsSelected: CustomerProduct[] = [];

    event.target.value.forEach((customerProductId: string) => {
      const customerProduct = data?.find(
        (customerProduct) => customerProduct.id === customerProductId
      );

      if (customerProduct) {
        customerProductsSelected.push(customerProduct);
      }
    });

    setCustomerProductsOption(event.target.value);
    onChange && onChange(customerProductsSelected);
  };

  return (
    <SelectFetch
      multiple
      placeholder='EMPLOYEES:PRODUCT_LABEL'
      value={value}
      parseOption={(product) => ({
        value: product.id,
        text: product.displayName,
        provider: product.provider,
      })}
      getOptions={useCustomerProduct}
      onChange={handleChange}
    />
  );
}

export default SelectCustomerProduct;
