import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';
import { apiClient } from 'config/api';
import { useCompanyStore } from '@stores';

interface UseDownloadReceiptByFiltersResponse {
  isLoading: boolean;
  downloadReceipt: (params: Params) => Promise<void>;
  error: string | undefined;
}

interface Params {
  lastName?: string;
  firstName?: string;
  cuit?: string;
  fromDate?: string;
  toDate?: string;
  page?: string;
  limit?: string;
}

/**
 * Custom hook to download a receipt by filters
 * @param { UseDownloadReceiptByFiltersProps } params - The filters to download the receipt
 * @param { string } params.transactionId - The transaction id to download the receipt
 * @returns { isLoading, downloadReceipt, error }
 */
const useDownloadReceiptByFilters = (): UseDownloadReceiptByFiltersResponse => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState<string>();
  const { selectedCompany } = useCompanyStore();

  const downloadReceipt = useCallback(
    async (params: Params) => {
      setIsloading(true);
      setError(undefined);
      try {
        const { headers, data } = await apiClient.get('/export/consumption', {
          params: {
            ...params,
            companyId: String(selectedCompany),
          },
          responseType: 'blob',
        });

        const contentDisposition = headers['content-disposition'];
        const contentType = headers['content-type'];
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = filenameMatch ? filenameMatch[1] : `${t('CONSUMPTION:XLS_FILE')}.xlsx`;

        const blob = new Blob([data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      } catch (error) {
        if (isAxiosError(error)) {
          setError(error.message);
        }
      } finally {
        setIsloading(false);
      }
    },
    [selectedCompany, t]
  );

  return { isLoading, downloadReceipt, error };
};

export default useDownloadReceiptByFilters;
