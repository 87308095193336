import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
/**
 * @param {} props.
 *  @param {string} props.label The label of the input
 *  @param {string} props.margin can be used to alter the vertical spacing of the input
 *  @param {string} props.value input value
 *  @param {string} props.size input size
 *  @param {boolean} props.password if it is true, it shows the icon to show / hide the password and it also set security text entry.
 *  @param {boolean} props.error If true, the label is displayed in an error state.
 *  @param {(text: string) => void | Promise<void> } props.onChange Callback fired when the value is changed.
 *  @param {(text: string) => void | Promise<void>} props.onBlur Callback fired when the element loses focus.
 *  @param {string} props.helperTextThe helper text content.
 *  @param {string} props.variant The variant to use
 *  @param {object} props.sx The system prop that allows defining system overrides as well as additional CSS styles
 *  @param {object} props.inputProps alter the props applied to the input element
 *  @param {function} props.onChange Handle input change
 * @returns {React.ReactElement}
 */
const CustomInput = ({
  label,
  margin = 'normal',
  value,
  size = 'medium',
  error,
  onChange,
  onBlur,
  helperText,
  variant = 'outlined',
  inputProps = {},
  sx = {},
  disabled = false,
  name,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <TextField
      label={t(label)}
      margin={margin}
      variant={variant}
      value={value}
      size={size}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      helperText={t(helperText)}
      sx={sx}
      InputProps={inputProps}
      fullWidth
      disabled={disabled}
      name={name}
      {...rest}
    ></TextField>
  );
};
export default CustomInput;
CustomInput.propTypes = {
  label: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  password: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  sx: PropTypes.object,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};
