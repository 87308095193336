import { useTranslation } from 'react-i18next';
import { Tooltip, Box } from '@mui/material';
import { Link } from '@atoms';
import { UseDownloadLink } from '@hooks';
import styles from './styles';

/**
 * React component for a prospect template link that triggers the download of a file.
 *
 * @function
 * @name TemplateLinkProspect
 * @returns {React.Component} A React component representing a link to download a file template.
 */
const TemplateLinkProspect = (): JSX.Element => {
  const { t } = useTranslation();
  const { data: fileUrl, isLoading } = UseDownloadLink('s3/templates/prospect');
  const disableButton = !fileUrl || isLoading;

  return (
    <Box sx={{ ...styles.box }}>
      <Tooltip title={t('COMMON:TOOLTIP:PROSPECT')}>
        <Link
          href={fileUrl}
          disabled={disableButton}
          download={t('COMMON:TEMPLATE_FILE_NAME', { type: 'prospect' })}
          isLoading={isLoading}
          content={t('COMMON:TEMPLATE_LINK_TITLE')}
          target='_blank'
        />
      </Tooltip>
    </Box>
  );
};

export default TemplateLinkProspect;
