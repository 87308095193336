const styles = {
  searchContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '0.5rem',
    padding: '1.5rem 1rem',
    borderRadius: '1rem',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '0.5rem',
  },
  firstLine: (responsive) => ({
    display: 'flex',
    flexDirection: responsive ? 'column' : 'row',
    justifyContent: 'space-between',
    gap: '0.5rem',
  }),
  secondLine: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    flexDirection: responsive ? 'column' : 'row',
  }),
  actionsContainer: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    height: '49px',
    justifyContent: responsive && 'flex-end',
  }),
  ChipContainer: {
    display: 'flex',
    gap: '0.5rem',
    flexWrap: 'wrap',
  },
  footer: {},
  checkboxInput: {
    height: '24px',
    margin: '0',
    gap: '0.5rem',
    '& > .MuiCheckbox-root': {
      padding: '0',
    },
  },
  clearButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      fontSize: '0.75rem',
      padding: '1rem 0.5rem',
    },
  },
  filterButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      minWidth: '46px',
      '& > .MuiButton-startIcon': {
        margin: '0',
      },
      padding: '1rem 0.5rem',
    },
  },
  inputGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '0.5rem',
  },
  inputCuilContainer: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    flexDirection: responsive ? 'column' : 'row',
  }),
  inputCuil: (error, responsive) => ({
    height: error ? '60px' : '49px',
    maxWidth: responsive ? '100%' : '150px',
    '& > p': {
      margin: '0',
      width: '20rem',
    },
  }),
  infoCuil: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    height: '49px',
  },
  secondLineSearch: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '0.5rem',
    pt: '0.5rem',
  },
  searchActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
};

export default styles;
