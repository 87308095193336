import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import styles from './styles';

/**
 * @param {object} props
 * @param {boolean} props.isNew - Indicates whether this is a new element.
 * @param {boolean} props.hasSubMenus - Indicates whether the item has submenus.
 * @param {boolean} props.isOpen - Indicates whether the element is open.
 * @returns {React.Component} - Component that renders specific icons.
 */
const NavItemIcons = ({ isNew, hasSubMenus, isOpen }) => {
  const { palette } = useTheme();
  return (
    <>
      {isNew && <Chip label='nuevo' size='small' color='primary' sx={styles.chip} />}
      {hasSubMenus &&
        (isOpen ? (
          <IconChevronDown color={palette.primary.main} size='1.2rem' />
        ) : (
          <IconChevronRight color={palette.primary.main} size='1.2rem' />
        ))}
    </>
  );
};

NavItemIcons.propTypes = {
  isNew: PropTypes.bool,
  hasSubMenus: PropTypes.bool,
  isOpen: PropTypes.bool,
};

export default NavItemIcons;
