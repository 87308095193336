import { t } from 'i18next';
import { Box, Tooltip } from '@mui/material';
import { parseAmount } from '@utils';
import { styles } from './styles';

export const COLUMNS = [
  { title: 'COMMON:CUIL', content: ({ cuil }) => cuil },
  //TODO: Falta implementar el campo "account"
  // { title: 'DISPERSIONS:ACCOUNT', content: ({ account }) => account },
  { title: 'DISPERSIONS:AMOUNT', content: ({ amount }) => parseAmount(amount) },
  {
    title: 'Resultado',
    content: ({ status, description }) => {
      const isMaxCharacter =
        (description && description.length > 25) || t('COMMON:ACCREDITED').length > 25;
      return (
        <Tooltip
          title={(isMaxCharacter && description) || (isMaxCharacter && t('COMMON:ACCREDITED'))}
          arrow
        >
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>
            {status === 'failed' ? description : t('COMMON:ACCREDITED')}
          </Box>
        </Tooltip>
      );
    },
  },
];
