import React, { ReactNode } from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import styles from './styles';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  dialogTitle?: ReactNode;
  dialogContent: ReactNode;
  dialogActions?: ReactNode;
  dialogWidth?: 'xs' | 'sm' | 'md' | 'lg';
}
/**
 * Dialog component for displaying a standardized dialog with custom actions.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {boolean} props.isOpen - Determines whether the dialog is open or closed.
 * @param {function} props.onClose - Callback function to handle the dialog's close event.
 * @param {string} props.dialogTitle - The title of the dialog.
 * @param {ReactNode} props.dialogContent - The content of the dialog.
 * @param {ReactNode} props.dialogActions - The custom actions or buttons to be displayed in the dialog.
 * @param {'xs' | 'sm' | 'md' | 'lg'} [props.dialogWidth] - The custom Dialog width ('xs', 'sm', 'md' o 'lg').
 * @returns {JSX.Element} - The rendered Dialog component.
 */
const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  dialogTitle,
  dialogContent,
  dialogActions,
  dialogWidth = 'sm',
}) => {
  const sizes = {
    xs: '20.5rem',
    sm: '31.5rem',
    md: '42.5rem',
    lg: '53.5rem',
  };

  return (
    <MuiDialog
      PaperProps={{ sx: styles.dialogPaper(sizes[dialogWidth]) }}
      open={isOpen}
      onClose={onClose}
      maxWidth={dialogWidth}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent sx={styles.dialogContent}>{dialogContent}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
