import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, SvgIcon, Typography, Button as ButtonMaterial } from '@mui/material';
import styles from './styles';

/**
 * Re-usable component for the top section of both prospect creation screens
 * @param {string} title The title of the section
 * @param {string} companyName The name of the selected company
 * @param {boolean} showSelectedCompany Should show a subtitle with the selected company
 * @param {string} linkTo The link for the other section
 * @param {string} linkTitle The title of the link
 */
const ProspectCreationTopSection = ({
  title,
  companyName,
  linkTo,
  linkTitle,
  linkIcon,
  showSelectedCompany,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={styles.topSection}>
      <Box sx={styles.titleSelectedCompany}>
        <Typography variant='h2'>{t(title)}</Typography>
        {showSelectedCompany && companyName && (
          <Typography variant='body'>
            {t('PROSPECTS:ADD_PROSPECTS_SELECTED_COMPANY', { company: companyName })}
          </Typography>
        )}
      </Box>
      <ButtonMaterial
        variant='outlined'
        style={styles.switchSectionButton}
        startIcon={<SvgIcon fontSize='small'>{linkIcon}</SvgIcon>}
        onClick={() => navigate(linkTo)}
      >
        {t(linkTitle)}
      </ButtonMaterial>
    </Box>
  );
};

ProspectCreationTopSection.propTypes = {
  title: PropTypes.string,
  companyName: PropTypes.string,
  linkTo: PropTypes.string,
  linkTitle: PropTypes.string,
  linkIcon: PropTypes.node,
  showSelectedCompany: PropTypes.bool,
};
export default ProspectCreationTopSection;
