export const styles = {
  cardContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5rem',
    backgroundColor: ({ palette }) => palette.primary.lightest,
  },
  boxContent: { flex: 1, textAlign: 'center' },
  typographyError: { whiteSpace: 'break-spaces' },
  boxError: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
  },
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  nameMaxWidth: {
    maxWidth: '120px',
  },
};
