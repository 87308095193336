import { Route, Routes } from 'react-router-dom';
import { useIsAuthReady } from 'config/auth';
import { useRemoteConfigValues } from '@hooks';
import screens, { LoadingScreen } from '@screens';
import { PrivateRoute } from './index';

/**
 * App router
 */
const AppRouter = () => {
  const remoteConfigReady = useRemoteConfigValues();
  const authReady = useIsAuthReady();

  const isReady = remoteConfigReady && authReady;

  return isReady ? (
    <Routes>
      {screens.map(({ component: Component, path, roles, subMenus }) =>
        roles ? (
          <>
            {!subMenus ? (
              <Route key={path} element={<PrivateRoute allowedRoles={roles} />}>
                <Route path={path} element={<Component />} />
              </Route>
            ) : (
              subMenus.map(({ component: SubComponent, ...subRoute }) => (
                <Route key={subRoute.path} element={<PrivateRoute allowedRoles={subRoute.roles} />}>
                  <Route path={subRoute.path} element={<SubComponent />} />
                </Route>
              ))
            )}
          </>
        ) : (
          <>
            {!subMenus ? (
              <Route path={path} key={path} element={<Component />} />
            ) : (
              subMenus.map(({ component: SubComponent, ...subRoute }) => (
                <Route key={subRoute.path} path={subRoute.path} element={<SubComponent />} />
              ))
            )}
          </>
        )
      )}
    </Routes>
  ) : (
    <LoadingScreen />
  );
};

export default AppRouter;
