const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    padding: '1.5rem',
    marginY: '1rem',
    gap: '0.5rem',
    justifyContent: 'space-between',
    height: '100%',
    flexShrink: 0,
  },
  cardHeader: { gap: '0.5rem', display: 'flex', flexDirection: 'column' },
  title: { display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'baseline' },
};

export default styles;
