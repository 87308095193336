import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { getDifferentProperties, getSearchParams } from '@utils';

/**
 * Data returned by the hook
 * @typedef {object} PaginationHookResult
 * @property {object} data - The response data from the API.
 * @property {boolean} isLoading - Indicates whether the data is currently being loaded. (from SWR)
 * @property {Error} error - The error object, if an error occurred during the request. (from SWR)
 * @property {boolean} isValidating - Indicates whether the data is currently being revalidated. (from SWR)
 * @property {function} handleRowsPerPageChange - Function to handle the change of rows per page.
 * @property {function} handlePageChange - Function to handle the change of page.
 * @property {boolean} filtersOpen - Indicates whether the filters are open.
 * @property {Array} selectedItems - List of checked items in the table
 * @property {function} setFiltersOpen - Function to update the state of filtersOpen.
 * @property {function} onFiltersFormSubmit - Function to handle the submission of the filters form.
 */

/**
 *
 * A reusable SWR hook for paginated search.
 * @param {string} url - The API endpoint URL.
 * @param {object} options - Options for pagination or search.
 * @param {object} options.params - Key-value object containing filtering/data params of the request.
 * @param {function} options.setURLparams - Function to update the URL parameters.
 * @param {array} options.paramKeys - Array of keys to be extracted from the browser URL parameters to include as request query params.
 * @param {function} [options.onError] - cb function to execute when SWR encounters an error
 * @param {boolean} [options.shouldFetchWithoutParams] - A flag indicating whether initial params are required to make the request.
 * @returns {PaginationHookResult} - An object containing the following properties and functions:
 */
function usePagination(
  url,
  { params, setURLparams, paramKeys = [], onError, shouldFetchWithoutParams = false }
) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const hasParams = Object.keys(params).length !== 0;
  const shouldStartFetching = shouldFetchWithoutParams ? true : hasParams;

  const filteredParams = useMemo(() => getSearchParams(params, paramKeys), [params, paramKeys]);

  /**
   * @returns {function} If params are required and empty, falsey key stops SWR from executing request.
   * If params are present, returns the SWR key as array (dependency list)
   */
  const getKey = () => {
    if (url && shouldStartFetching) {
      return [url, { params: filteredParams }];
    } else {
      return false;
    }
  };

  const swrConfig = {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: () => {
      setFiltersOpen(false);
    },
    ...(Boolean(onError) && { onError }),
  };

  const { data, isLoading, error, isValidating, mutate } = useSWR(getKey, swrConfig);

  /**
   * Triggers a rows per page change
   */
  function handleRowsPerPageChange(event) {
    const limit = event.target.value;
    setURLparams({ ...params, limit, page: 1 });
    setSelectedItems([]);
  }
  /**
   * Triggers a page change
   */
  function handlePageChange(newIndex) {
    const page = newIndex + 1;
    setURLparams({ ...params, page });
  }

  /**
   * On submitting filters form
   */
  function getHasChangedSearch(prevValues, newValues) {
    const oldKeys = Object.keys(prevValues).length;
    const newKeys = Object.keys(newValues).length;

    if (newKeys !== oldKeys) {
      return true;
    }

    return Object.keys(getDifferentProperties(prevValues, newValues)).length !== 0;
  }

  /**
   * On submitting filters form
   */
  async function onFiltersFormSubmit(formValues) {
    const prevValues = filteredParams;
    const newValues = getSearchParams(formValues, paramKeys);

    const hasChangedSearch = getHasChangedSearch(prevValues, newValues);

    if (hasChangedSearch) {
      setURLparams({ ...newValues });
    } else {
      mutate();
    }
    setSelectedItems([]);
  }

  /**
   * Refreshes the data by calling mutate
   */
  const handleRefresh = () => {
    mutate(url);
  };

  return {
    data,
    isLoading,
    error,
    isValidating,
    handleRowsPerPageChange,
    handlePageChange,
    onFiltersFormSubmit,
    filtersOpen,
    setFiltersOpen,
    mutate,
    selectedItems,
    setSelectedItems,
    handleRefresh,
  };
}

export default usePagination;
