import { logEvent } from 'firebase/analytics';
import { FIREBASE } from '@globalConstants';
import { analytics } from '@config/firebase';
import { Logger } from '.';

const logger = new Logger('Trackings');

/**
 * Abstraction to upload events to firebase analytics.
 * Receives an array of event objects
 * @param {{
 * name: string,
 * props: object,
 * type: string
 * }[]} eventsArray
 */
export default async function trackings(eventsArray) {
  eventsArray.forEach((event) => {
    const { type, name, props } = event;

    if (type) {
      switch (type) {
        case FIREBASE:
          logger.log(`Firebase event '${name}' sended with props`, props);
          logEvent(analytics, name, props);
          break;
        default:
          logger.error('Unable to send unsupported event: ', event);
          break;
      }
    }
  });
}
