/**
 * Takes an object and fills every property with a value
 * @param {object} prevObject
 * @returns {object}
 */
export default function clearObject(provObject) {
  const newObject = {};
  Object.keys(provObject).forEach((v) => {
    if (Array.isArray(provObject[v])) {
      newObject[v] = [];
    } else {
      newObject[v] = '';
    }
  });

  return newObject;
}
