import { useTranslation } from 'react-i18next';

/**
 * Custom hook that provides the translation function (`t`) with a custom key prefix.
 * @param {string} keyPrefix The custom key prefix to apply to translations.
 * @param {object} otherOptions Other options that can be passed to `useTranslation`.
 * @returns {object} Object containing the translation function (`t`) with the applied custom key prefix.
 */
const useTranslationWithPrefix = (keyPrefix, otherOptions) => {
  return useTranslation('', { keyPrefix, ...otherOptions });
};

export default useTranslationWithPrefix;
