import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTheme } from '@emotion/react';

/**
 * Input component with calendar picker
 * @param {string} label label of the input
 * @param {Date} minDate min Date the calendar will show
 * @param {Date} maxDate max Date the calendar will show
 * @param {any} value current value
 * @param {Function} onChange event triggered when calendar or input value changes
 * @param {boolean | string} error if true then the Input will have error style
 * @param {string} helperText error text showed below the input (if there's an error, error param has to be true)
 * @returns {React.component}
 */
const DateInput = ({
  label = '',
  minDate,
  maxDate,
  value,
  onChange = () => {},
  error,
  helperText,
}) => {
  const { t } = useTranslation();

  return (
    <DatePicker
      label={t(label)}
      ampm={false}
      ampmInClock={false}
      disableFuture
      value={value}
      onChange={(e) => onChange({ target: { value: e } })}
      InputAdornmentProps={{ position: 'start' }}
      inputFormat='dd/MM/yyyy'
      openTo='year'
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(params) => <RenderInput {...{ params, error, helperText }} />}
    />
  );
};
/**
 * Textfield with icon of calendar that changes the icon placement
 * @param {Object} params Input params
 * @param {boolean | string} error if true then the Input will have error style
 * @param {string} helperText error text showed below the input (if there's an error, error param has to be true)
 * @returns
 */
const RenderInput = ({ params, error, helperText }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const paramsAux = {
    ...params,
    InputProps: {
      ...params.InputProps,
      endAdornment: {
        ...params.InputProps.startAdornment,
        props: { ...params.InputProps.startAdornment.props, position: 'end' },
      },
      startAdornment: null,
    },
    error: Boolean(error),
    inputProps: {
      ...params.inputProps,
      placeholder: 'DD/MM/AAAA',
      style: {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
      },
    },
  };

  return <TextField {...paramsAux} variant='outlined' fullWidth helperText={t(helperText)} />;
};
DateInput.propTypes = {
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

RenderInput.propTypes = {
  params: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default DateInput;
