import {
  alpha,
  filledInputClasses,
  inputLabelClasses,
  outlinedInputClasses,
  paperClasses,
  Components,
} from '@mui/material';
import { NubiTheme } from '@theme';
import { Typography } from './typography';

// This is the way that @mui has to add our types to the theme. So, we extend the type `ButtonPropsVariantOverrides` for include "link" variant
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

interface Config {
  typography: Typography;
}

/**
 *
 * @returns
 */
export function createComponents(config: Config): Components<NubiTheme> {
  const { typography } = config;
  return {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'link' },
          style: ({ theme }) => ({
            padding: 0,
            color: theme.palette.primary.main,
          }),
        },
      ],
      styleOverrides: {
        root: {
          ...typography.h4,
          borderRadius: '8px',
          textTransform: 'none',
        },
        contained: {
          '&.Mui-disabled': {
            color: 'white',
          },
        },
        sizeSmall: {
          padding: '6px 16px',
        },
        sizeMedium: {
          padding: '0.8125rem 1rem',
        },
        sizeLarge: {
          padding: '11px 24px',
        },
        textSizeSmall: {
          padding: '7px 12px',
        },
        textSizeMedium: {
          padding: '0.8125rem 1rem',
        },
        textSizeLarge: {
          padding: '12px 16px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          [`&.${paperClasses.elevation1}`]: {
            boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 24px',
          '&:last-child': {
            paddingBottom: '32px',
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
        subheaderTypographyProps: {
          variant: 'body2',
        },
      },
      styleOverrides: {
        root: {
          padding: '32px 24px 16px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      body,html,#root {
        margin: 0;
        padding: 0;
        min-height: 100vh;
      }
    `,
    },
    MuiInputBase: {
      styleOverrides: {
        input: ({ theme }) => ({
          '&.Mui-disabled': { '-webkit-text-fill-color': theme.palette.gray[60] },
        }),
        adornedEnd: ({ theme }) => ({
          ...typography.h4,
          color: theme.palette.gray[80],
        }),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: alpha(theme.palette.primary.dark, 0.4),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          gap: '1rem',
          width: '31.5rem',
          boxShadow: '0px 8px 16px 0px rgba(10, 16, 68, 0.08)',
          borderRadius: '0.75rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          alignSelf: 'stretch',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '140%',
          color: '#1F1F1F',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) => ({ ...typography.body, color: theme.palette.gray[90] }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontWeight: 500,
          '&::placeholder': {
            color: theme.palette.text.secondary,
          },
        }),
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          'input, .MuiSelect-select': { padding: '0.8125rem 1rem' },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.gray[50],
            fontSize: '1.15rem',
            borderRadius: '0.5rem',
          },
          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
            },
          },
          [`&.${filledInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.error.main,
            },
          },
          [`&.${filledInputClasses.disabled}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.gray[50],
            },
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...typography.body,
          fontWeight: 500,
          [`&.${inputLabelClasses.outlined}`]: {
            transform: 'translate(1rem, 0.8125rem) scale(1)',
          },
          [`&.${inputLabelClasses.shrink}`]: {
            color: theme.palette.primary.main,
            [`&.${inputLabelClasses.standard}`]: {
              transform: 'translate(0, -1.5px) scale(0.85)',
            },
            [`&.${inputLabelClasses.filled}`]: {
              transform: 'translate(12px, 6px) scale(0.85)',
            },
            [`&.${inputLabelClasses.outlined}`]: {
              transform: 'translate(14px, -9px) scale(0.85)',
            },
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        centered: {
          gap: '0.5rem',
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...typography.h4,
          textTransform: 'none',
          fontWeight: 600,
          color: theme.palette.gray[90],
          width: '9.25rem',
          padding: '0.8125rem 1rem',
          borderRadius: '6.25rem',
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.lightest,
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          backgroundColor: theme.palette.primary.lightest,
          borderBottom: `1px solid ${theme.palette.gray[50]}`,
          ...typography.h5,
        }),
        body: ({ theme }) => ({
          borderBottom: `1px solid ${theme.palette.gray[50]}`,
          whiteSpace: 'nowrap',
          ...typography.body2,
          color: theme.palette.gray[90],
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '0.5rem',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...typography.h4,
          color: theme.palette.gray[90],
          borderRadius: '0.25rem',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: theme.palette.primary.lightest,
            color: theme.palette.primary.main,
          },
          '&:active': {
            backgroundColor: theme.palette.primary.lightest,
            color: theme.palette.primary.main,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.lightest,
            color: theme.palette.primary.main,

            '&:hover': {
              backgroundColor: theme.palette.primary.lightest,
              color: theme.palette.primary.main,
            },
            '&:active': {
              backgroundColor: theme.palette.primary.lightest,
              color: theme.palette.primary.main,
            },
            '&.Mui-focusVisible': {
              backgroundColor: theme.palette.primary.lightest,
              color: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          gap: '0.5rem',
          padding: '0.5rem',
          '+.MuiDivider-root': {
            margin: 0,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '2.5rem',
          height: '1.25rem',
          borderRadius: '6.25rem',

          padding: 0,
        },
        switchBase: ({ theme }) => ({
          padding: 0,
          transitionDuration: '300ms',
          '& + .MuiSwitch-track': {
            backgroundColor: 'white',
            opacity: 1,
            border: `2px solid ${theme.palette.primary[500]}`,
          },
          '&.Mui-checked': {
            transform: 'translate(1.5rem, 0.25rem)',

            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: 'white',
              opacity: 1,
              border: `2px solid ${theme.palette.primary.main}`,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.primary.main,
          },
          '&.Mui-checked .MuiSwitch-thumb': {
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.gray[50] : theme.palette.gray[90],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
          transform: 'translate(0.25rem, 0.25rem)',
        }),
        thumb: ({ theme }) => ({
          boxSizing: 'border-box',
          width: '0.75rem',
          height: '0.75rem',
          borderRadius: '0.375rem',
          color: theme.palette.primary[500],
        }),
        colorPrimary: ({ theme }) => ({
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }),
        track: {
          width: '2.5rem',
          height: '1.25rem',
          borderRadius: '6.25rem',
          backgroundColor: 'white',
          opacity: 1,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '1rem',
          overflow: 'hidden',
        },
        root: { boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)' },
      },
    },
  };
}
