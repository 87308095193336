export const MONTHS = [
  { text: 'Enero', value: '01' },
  { text: 'Febrero', value: '02' },
  { text: 'Marzo', value: '03' },
  { text: 'Abril', value: '04' },
  { text: 'Mayo', value: '05' },
  { text: 'Junio', value: '06' },
  { text: 'Julio', value: '07' },
  { text: 'Agosto', value: '08' },
  { text: 'Septiembre', value: '09' },
  { text: 'Octubre', value: '10' },
  { text: 'Noviembre', value: '11' },
  { text: 'Diciembre', value: '12' },
];
