import { useState } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Popover, SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Button, ConditionalTooltip } from '@atoms';
import styles from './styles';

/**
 * Three dots menu component
 */
const ThreeDotsMenu = ({ options }) => {
  const [showPopover, setShowPopover] = useState(false);

  /**
   * open menu
   */
  const handleClick = (event) => {
    setShowPopover(event.currentTarget);
  };

  /**
   * close menu
   */
  const handleClose = () => {
    setShowPopover(false);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={showPopover}
        anchorEl={showPopover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={styles.containerPopover}>
          {options.map(
            (button, index) =>
              button.canViewOption && (
                <ConditionalTooltip
                  key={index}
                  tooltipText={button.tooltipText}
                  arrow
                  placement='top'
                >
                  <Button
                    customStyle={styles.button}
                    onClick={() => {
                      button.onClick();
                      handleClose();
                    }}
                    text={button.text}
                    startIcon={button.icon && <SvgIcon>{button.icon}</SvgIcon>}
                    disabled={button.disabled}
                  />
                </ConditionalTooltip>
              )
          )}
        </Box>
      </Popover>
    </>
  );
};

ThreeDotsMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      canViewOption: PropTypes.bool,
      tooltipText: PropTypes.string,
      icon: PropTypes.elementType,
      disabled: PropTypes.bool,
    })
  ),
};

export default ThreeDotsMenu;
