/**
 * @param {string} text
 * @param {string} textLink
 * @param {string} url
 */
const createSublistItem = (text, textLink, url) => ({
  text,
  textLink,
  url,
});

/**
 * @param {string} type
 * @param {string} text
 * @param {string} subtext
 * @param {string} sublist
 */
const createRow = ({ type, text, subtext = null, sublist = null }) => ({
  type,
  text,
  subtext,
  sublist,
});

/**
 * @param {string} type
 * @param {array} rows
 */
const createContent = ({ type, rows }) => ({
  type,
  rows,
});

/**
 * @param {string} title
 * @param {string} body
 * @param {array} content
 */
const createItem = ({ title, body = null, content }) => ({
  title,
  body,
  content,
});

const ITEMS = [
  createItem({
    title: 'HELP_SCREEN:LOGIN_PROBLEMS:TITLE',
    content: createContent({
      type: 'list',
      rows: [
        createRow({
          type: 'body1',
          text: 'HELP_SCREEN:LOGIN_PROBLEMS:FIRST_ITEM',
          sublist: {
            rows: [
              createSublistItem(
                'HELP_SCREEN:LOGIN_PROBLEMS:SUBLIST_FIRST_ITEM:TEXT',
                'HELP_SCREEN:LOGIN_PROBLEMS:SUBLIST_FIRST_ITEM:TEXTLINK',
                'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
              ),
              createSublistItem(
                'HELP_SCREEN:LOGIN_PROBLEMS:SUBLIST_SECOND_ITEM:TEXT',
                'HELP_SCREEN:LOGIN_PROBLEMS:SUBLIST_SECOND_ITEM:TEXTLINK',
                'https://apps.apple.com/us/app/google-authenticator/id388497605'
              ),
            ],
          },
        }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:LOGIN_PROBLEMS:SECOND_ITEM' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:LOGIN_PROBLEMS:THIRD_ITEM' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:LOGIN_PROBLEMS:FOURTH_ITEM' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:LOGIN_PROBLEMS:FIFTH_ITEM' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:LOGIN_PROBLEMS:SIXTH_ITEM' }),
      ],
    }),
  }),
  createItem({
    title: 'HELP_SCREEN:MY_BALANCE:TITLE',
    content: createContent({
      rows: [createRow({ type: 'body1', text: 'HELP_SCREEN:MY_BALANCE:FIRST_ITEM' })],
    }),
  }),
  createItem({
    title: 'HELP_SCREEN:ACCOUNTS:TITLE',
    body: 'HELP_SCREEN:ACCOUNTS:BODY',
    content: createContent({
      rows: [
        createRow({ type: 'h4', text: 'HELP_SCREEN:ACCOUNTS:SECOND_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:ACCOUNTS:SECOND_ITEM_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:ACCOUNTS:THIRD_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:ACCOUNTS:THIRD_ITEM_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:ACCOUNTS:FOURTH_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:ACCOUNTS:FOURTH_ITEM_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:ACCOUNTS:FIFTH_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:ACCOUNTS:FIFTH_ITEM_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:ACCOUNTS:SIXTH_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:ACCOUNTS:SIXTH_ITEM_BODY' }),
      ],
    }),
  }),
  createItem({
    title: 'HELP_SCREEN:CONSUMPTIONS_LIST:TITLE',
    body: 'HELP_SCREEN:CONSUMPTIONS_LIST:BODY',
    content: createContent({
      rows: [
        createRow({ type: 'h4', text: 'HELP_SCREEN:CONSUMPTIONS_LIST:FIRST_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:CONSUMPTIONS_LIST:FIRST_ITEM_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:CONSUMPTIONS_LIST:SECOND_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:CONSUMPTIONS_LIST:SECOND_ITEM_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:CONSUMPTIONS_LIST:THIRD_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:CONSUMPTIONS_LIST:THIRD_ITEM_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:CONSUMPTIONS_LIST:FOURTH_ITEM_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:CONSUMPTIONS_LIST:FOURTH_ITEM_BODY' }),
      ],
    }),
  }),
  createItem({
    title: 'HELP_SCREEN:DOWNLOAD_RECEIPTS:TITLE',

    content: createContent({
      type: 'list',
      rows: [createRow({ type: 'body1', text: 'HELP_SCREEN:DOWNLOAD_RECEIPTS:FIRST_ITEM' })],
    }),
  }),
  createItem({
    title: 'HELP_SCREEN:DISPERSSIONS:TITLE',
    content: createContent({
      rows: [
        createRow({ type: 'h3', text: 'HELP_SCREEN:DISPERSSIONS:FIRST_ITEM:TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:DISPERSSIONS:FIRST_ITEM:BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:DISPERSSIONS:FIRST_ITEM:FIRST_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:DISPERSSIONS:FIRST_ITEM:FIRST_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:DISPERSSIONS:FIRST_ITEM:SECOND_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:DISPERSSIONS:FIRST_ITEM:SECOND_BODY' }),
        createRow({ type: 'h3', text: 'HELP_SCREEN:DISPERSSIONS:SECOND_ITEM:TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:DISPERSSIONS:SECOND_ITEM:BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:DISPERSSIONS:SECOND_ITEM:FIRST_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:DISPERSSIONS:SECOND_ITEM:FIRST_BODY' }),
        createRow({ type: 'h4', text: 'HELP_SCREEN:DISPERSSIONS:SECOND_ITEM:SECOND_TITLE' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:DISPERSSIONS:SECOND_ITEM:SECOND_BODY' }),
      ],
    }),
  }),

  createItem({
    title: 'HELP_SCREEN:SUPPORT_MAIL:TITLE',
    content: createContent({
      rows: [
        createRow({ type: 'body1', text: 'HELP_SCREEN:SUPPORT_MAIL:FIRST_ITEM' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:SUPPORT_MAIL:SECOND_ITEM' }),
        createRow({ type: 'body1', text: 'HELP_SCREEN:SUPPORT_MAIL:THIRD_ITEM' }),
      ],
    }),
  }),
];

export default ITEMS;
