const styles = {
  container: (responsive) => ({
    display: 'flex',
    alignItems: responsive ? 'flex-start' : 'center',
    flexDirection: responsive ? 'column' : 'row',
    justifyContent: 'space-between',
  }),
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 2,
  },
  titleContainer: (responsive) => ({
    display: 'flex',
    alignItems: 'center',
    gap: responsive ? 0 : 2,
    flexDirection: responsive ? 'column' : 'row',
  }),
  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
    direction: 'row',
    gap: 1,
  },
  avatar: {
    height: 40,
    width: 40,
    bgcolor: 'transparent',
    border: ({ palette }) => `2px solid ${palette.primary.main}`,
  },
  iconAvatar: { color: ({ palette }) => palette.primary.main },
  subtitle: { color: ({ palette }) => palette.gray[80] },
  rightTextContainer: { flexDirection: 'row', display: 'flex', gap: 1, alignItems: 'center' },
  iconContainer: { display: 'flex', alignItems: 'center' },
};

export default styles;
