import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CompanyDetails from 'screens/clients/CompanyDetails';
import { palette } from 'theme/palette';
import { IconBuildingStore, IconEye, IconEyeClosed } from '@tabler/icons-react';
import { useSelectedCompanyDetails, useURLparams } from '@hooks';
import { MainMenuLayout, FullWidthContent } from '@layouts';
import { CardHeader, GoBackButton } from '@molecules';
import { ClientMovementsTable } from '@organisms';
import { parseAmount } from '@utils';

/**
 * Mi Saldo screen
 */
const MyBalance = () => {
  const { t } = useTranslation();
  const { params, setURLparams } = useURLparams();
  const navigate = useNavigate();

  const { data: client } = useSelectedCompanyDetails();
  const balance = client?.balance ? parseAmount(client.balance) : '-';
  const [showBalance, setShowBalance] = useState(false);

  const clientMenu = [
    {
      text: t('EMPLOYEES:DETAIL:VIEW_DETAIL'),
      canViewOption: params.tab !== 'details',
      onClick: () => {
        setURLparams({ ...params, tab: 'details' });
      },
    },
    {
      text: t('COMMON:SEE_REPORTS'),
      canViewOption: params.tab !== 'details',
      onClick: () => {
        navigate('/reportes', { state: { goBack: '/saldo' } });
      },
    },
  ];

  /** */
  const onChangeBalance = () => {
    setShowBalance(!showBalance);
  };

  const leftComponentOptions = {
    type: 'avatar',
    icon: <IconBuildingStore color={palette.primary.main} />,
  };
  const rightComponentOptions = {
    type: 'text',
    text: !showBalance ? balance : '$ *****',
    icon: !showBalance ? <IconEye /> : <IconEyeClosed />,
    onClickIcon: onChangeBalance,
    menuOptions: clientMenu,
  };

  const subtitleOptions = {
    text: client?.cuil || '-',
  };

  /** Render content depending of "tab" selected */
  const renderContent = (tabSelected) => {
    switch (tabSelected) {
      case 'details': {
        return (
          <>
            <CompanyDetails isOperationRole={false} />
            <GoBackButton variant='outlined' />
          </>
        );
      }
      default: {
        return <>{client?.id && <ClientMovementsTable client={client} />}</>;
      }
    }
  };

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <CardHeader
          withMenu={false}
          title={client?.description}
          leftComponentOptions={leftComponentOptions}
          rightComponentOptions={rightComponentOptions}
          subtitleOptions={subtitleOptions}
        />
        {renderContent(params.tab)}
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default MyBalance;
