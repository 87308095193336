import { apiClient } from '../api';

/**
 *
 * @param {string} url The url of the request
 * @param {object} config Axios request config
 */
export default async function fetcher(key) {
  if (!Array.isArray(key)) {
    key = [key];
  }

  const response = await apiClient.get(...key);
  const data = response?.data;

  return data;
}
