import { Alert } from '@atoms';
import { useGlobalErrorStore } from '@stores';

/**
 * Uses global state/context and displays a global error to be displayed everywhere in the app
 */
const GlobalErrorAlert = () => {
  const { showError, setShowError, error } = useGlobalErrorStore();

  return (
    <Alert
      modalVisible={showError}
      title={error.title}
      description={error.description}
      handleClose={() => setShowError(false)}
    />
  );
};

export default GlobalErrorAlert;
