import { useTranslation } from 'react-i18next';
import { FormikProps, getIn } from 'formik';
import { FormControl, Stack, Typography } from '@mui/material';
import { Input, Select } from '@atoms';
import { AddCustomerProductSchema } from './addCustomerProduct.schema';
import companyTypes from './companyTypes.json';

interface RegisterCompanyInPrismaProps {
  formik: FormikProps<AddCustomerProductSchema>;
}

/** Component is responsable for validate and submit the register process of a company in Prisma
 * @param props
 * @param props.formik formik props for add customer product form
 */
const RegisterCompanyInPrisma = ({ formik }: RegisterCompanyInPrismaProps) => {
  const { t } = useTranslation();
  const {
    values: { registerCompanyInPrisma },
    touched,
    errors,
    handleChange,
    handleBlur,
  } = formik;

  return (
    <Stack spacing={3} mt={1}>
      <Typography variant='h4'>{t('COMMON:REGISTER_COMPANY_IN_PRISMA')}</Typography>
      <FormControl>
        <Select
          error={
            Boolean(getIn(touched, 'registerCompanyInPrisma.companyType')) &&
            Boolean(getIn(errors, 'registerCompanyInPrisma.companyType'))
          }
          helperText={
            getIn(touched, 'registerCompanyInPrisma.companyType')
              ? t(getIn(errors, 'registerCompanyInPrisma.companyType'))
              : undefined
          }
          items={companyTypes}
          label={t('COMMON:COMPANY_TYPE')}
          name='registerCompanyInPrisma.companyType'
          parseOption={(companyType: string) => ({
            label: companyType,
            value: companyType,
          })}
          value={registerCompanyInPrisma?.companyType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormControl>
      <Stack direction='row' spacing={2}>
        <FormControl>
          <Input
            id='contactEmail'
            error={
              Boolean(getIn(touched, 'registerCompanyInPrisma.contactEmail')) &&
              Boolean(getIn(errors, 'registerCompanyInPrisma.contactEmail'))
            }
            helperText={
              getIn(touched, 'registerCompanyInPrisma.contactEmail')
                ? getIn(errors, 'registerCompanyInPrisma.contactEmail')
                : undefined
            }
            name='registerCompanyInPrisma.contactEmail'
            label={t('COMMON:CONTACT_EMAIL')}
            type='email'
            value={registerCompanyInPrisma?.contactEmail}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>
        <FormControl>
          <Input
            id='fantasyName'
            error={
              Boolean(getIn(touched, 'registerCompanyInPrisma.fantasyName')) &&
              Boolean(getIn(errors, 'registerCompanyInPrisma.fantasyName'))
            }
            helperText={
              getIn(touched, 'registerCompanyInPrisma.fantasyName')
                ? getIn(errors, 'registerCompanyInPrisma.fantasyName')
                : undefined
            }
            name='registerCompanyInPrisma.fantasyName'
            label={t('COMMON:FANTASY_NAME')}
            value={registerCompanyInPrisma?.fantasyName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default RegisterCompanyInPrisma;
