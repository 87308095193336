import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import styles from './styles';

/**
 *
 * @param {object} props
 * @param {array} props.errors
 * @param {string} props.title A descriptive title of the error box
 * @param {func} props.getMessages Key-value dictionary of error message translation ids
 * @param {boolean} props.customLine Indicates whether to display a custom line instead of the default "line <number>"
 * @param {func} props.getCustomLine Function to get the custom line
 * @returns {React.ReactElement}
 */
const ErrorList = ({ errors, getMessages, customLine = false, getCustomLine }) => {
  const { t } = useTranslation();

  /**
   * Gets the text of the error message
   * @param {string} code
   */
  function getText(code) {
    const message = getMessages(code);
    if (typeof message === 'string') {
      return t(message);
    } else if (Array.isArray(message)) {
      return t(...message);
    }

    return message;
  }

  return (
    <Box component={'ul'} sx={styles.box}>
      {errors?.map((error, key) => {
        return (
          <li key={key}>
            <Typography component='span' variant='h4'>
              {`${
                customLine
                  ? getCustomLine(error)
                  : t('COMMON:FILE_UPLOADS:LINE_NUMBER', { number: error.line })
              }: `}
            </Typography>
            <Typography variant='body'>{error.messages.map(getText).join('; ')}</Typography>
          </li>
        );
      })}
    </Box>
  );
};

ErrorList.propTypes = {
  errors: propTypes.array,
  title: propTypes.string,
  getMessages: propTypes.func,
  customLine: propTypes.bool,
  getCustomLine: propTypes.func,
};

export default ErrorList;
