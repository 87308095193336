const styles = {
  toolbarContainer: { flexDirection: 'row', justifyContent: 'flex-end', gap: 1 },
  headerContainer: { justifyContent: 'space-between', flexDirection: 'row', gap: 1 },
  buttonsContainer: { flexDirection: 'row', gap: 1 },
  selectsConatianer: {
    flexDirection: 'row',
    gap: 1,
    width: '70%',
    '> *': { maxWidth: '50%' },
  },
  clearFilter: { box: { whiteSpace: 'nowrap' } },
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  nameMaxWidth: {
    maxWidth: '120px',
  },
  lastNameMaxWidth: {
    maxWidth: '160px',
  },
  cuilMaxWidth: {
    maxWidth: '120px',
  },
  productMaxWidth: {
    maxWidth: '190px',
  },
  searchContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '0.5rem',
    padding: '1.5rem 1rem',
    borderRadius: '1rem',
    backgroundColor: '#fff',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '0.5rem',
  },
  firstLine: (responsive) => ({
    display: 'flex',
    flexDirection: responsive ? 'row' : 'row',
    justifyContent: 'space-between',
    gap: '0.5rem',
  }),
  secondLine: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    flexDirection: responsive ? 'column' : 'row',
  }),
  inputsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  },
  actionsContainer: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    height: '49px',
    justifyContent: responsive && 'flex-end',
  }),
  inputGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '0.5rem',
    width: '100%',
  },
  ChipContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
  footer: {},
  secondLineSearch: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '0.5rem',
    pt: '0.5rem',
  },
  searchActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  checkboxInput: {
    height: '24px',
    margin: '0',
    gap: '0.5rem',
    '& > .MuiCheckbox-root': {
      padding: '0',
    },
  },
  clearButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      fontSize: '0.75rem',
      padding: '1rem 0.5rem',
    },
  },
  filterButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      minWidth: '46px',
      '& > .MuiButton-startIcon': {
        margin: '0',
      },
      padding: '1rem 0.5rem',
    },
  },
  searchButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      minWidth: '46px',
      '& > .MuiButton-startIcon': {
        margin: '0',
      },
    },
  },
};

export default styles;
