/**
 * We wait up to 40 seconds for a request to return a response, otherwise axios should timeout
 */
export const TIMEOUT_LIMIT = 40000;

/**
 * We wait up to 3 seconds for a connection check request to return a response, otherwise axios should timeout
 */
export const CONN_CHECK_TIMEOUT = 3000;

export const API_GENERAL_ERRORS = {
  USER_OR_PASSWORD_INVALID: 'USER_OR_PASSWORD_INVALID',
  EXPIRED_TOKEN: 'NW_AUTH_ERRNO_0015',
  INVALID_TOKEN: 'NW_AUTH_ERRNO_0002',
};
