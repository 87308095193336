import useSWR from 'swr';
import { URL_EXPIRATION_TIME_REFRESH } from './constants';

/**
 *  custom hook to obtain the download link for a template.
 */
const UseDownloadLink = (url, options = {}) => {
  const props = useSWR(url, {
    revalidateOnFocus: false,
    refreshInterval: URL_EXPIRATION_TIME_REFRESH,
    refreshWhenHidden: true,
    ...options,
  });
  return props;
};

export default UseDownloadLink;
