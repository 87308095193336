import { NETWORK_ERROR, DEFAULT_ERROR } from '@globalConstants';
import { checkInternetConnection, Logger } from '@utils';
import { staticEndpoints, dynamicEndpoints } from './endpoints';
import { apiClient } from './instances';

/**
 * Array of promises (pending requests that failed because of expired token and are waiting for refresh)
 */
//let failedRequestsQueue = [];

const logger = new Logger('InterceptorHelpers');

/**
 * Puts a failed request on hold, pushing a new object to failedRequestsQueue containing resolve and reject functions that will eventually fulfill the original request
 * @param {object} failedRequestConfig Object containing config values from the request that failed.
 * @returns {Promise} Promise that will fulfill after attempting to refresh token
 */
// const queueRequest = (failedRequestConfig) => {
//   return new Promise(function (resolve, reject) {
//     failedRequestsQueue.push({ resolve, reject });
//   })
//     .then(() => retryRequest(failedRequestConfig))
//     .catch(() => blockError());
// };

/**
 * Resolves/rejects all promises in failedRequestsQueue, depending if there is an error or not
 * @param {object} error
 */
// const fulfillQueue = (error = null) => {
//   failedRequestsQueue.forEach((promise) => {
//     if (error) {
//       promise.reject(error);
//     } else {
//       promise.resolve();
//     }
//   });

//   failedRequestsQueue = [];
// };

/**
 * Attempts to refresh access token by sending a request to /auth/refresh-token. Then fulfills all pending requests
 * Upon success, saves new refresh/access tokens and retries all requests that had failed previously (the first one, and any promise in failedRequestsQueue array)
 * Upon failure, logs out the user
 * @param {object} failedRequestConfig Object containing config values from the request that failed.
 * @returns {Promise} Promise that will fulfill after attempting to refresh token with /auth/refresh-token endpoint
 */
//export const expiredTokenHandler = async (failedRequestConfig) => {};

/**
 * Retries a failed request
 * @param {object} failedRequestConfig Object containing config values from the request that failed.
 * @returns {Promise} apiClient call
 */
export const retryRequest = async ({ url, requestData, params, headers, method }) => {
  switch (method) {
    case 'get':
    case 'delete':
      return await apiClient[method](url, {
        headers,
        params,
      });
    case 'post':
    case 'patch':
      return await apiClient[method](url, requestData, {
        headers,
        params,
      });
    default:
      throw { error: `Method '${method}' not found` };
  }
};

/**
 * Rejects promise with undefined value
 */
export const blockError = () => Promise.reject(undefined);

/**
 * Rejects promise with the value of the error
 * @param {object} error
 */
export const allowError = (error) => Promise.reject(error);

/**
 * 1 - Check if URL is registered in static endpoints object
 * 2 - If it is, iterate over that array value to find the particular error that was received
 * @param {Object} errorInfo Info about the error received from backend
 * @returns {Boolean} indicating wether the incoming error is handled in STATIC endpoints
 */
export const evaluateStaticEndpoints = ({ url, status }) => {
  const errorsFromThisEndpointHandled = staticEndpoints[url];
  if (!errorsFromThisEndpointHandled) return false;

  return errorsFromThisEndpointHandled.some(
    (staticEndpointError) =>
      staticEndpointError.all ||
      // (staticEndpointError.code === code &&
      staticEndpointError.status === status
  );
};

/**
 * Check if received error is in dynamic endpoints array, by testing 'url' regex
 * @param {Object} errorInfo Info about the error received from backend
 * @returns {Boolean} indicating if the error is handled among the DYNAMIC endpoints
 */
export const evaluateDynamicEndpoints = ({ url, status, method }) => {
  return dynamicEndpoints.some(
    (dynamicEndpointError) =>
      dynamicEndpointError.url.test(url) &&
      dynamicEndpointError.method === method &&
      (dynamicEndpointError.all ||
        dynamicEndpointError.errors.some((internalError) => {
          return internalError.status === status;
          // && internalError.code === code;
        }))
  );
};

/**
 * Shows disconnection Snackbar
 */
export const noConnectionHandler = (showErrorSnackbar) => {
  logger.error(NETWORK_ERROR);
  showErrorSnackbar(NETWORK_ERROR);
};

/**
 * Shows default error Snackbar and warns in logger
 * This function is only called when we receive an error which isn't registered
 * Ideally, that never happens and this function is never called.
 * @param {Object} response error.response received from backend
 * @param {Func} showErrorSnackbar
 */
export const defaultErrorHandler = (response, showErrorSnackbar) => {
  logger.warn('Entering default error handler');
  showErrorSnackbar(DEFAULT_ERROR);

  response &&
    logger.warn(
      `Interceptor Error Handler: unrecognized error. An error was received which wasn't found in the endpoints.js file.
    A default Snackbar message was displayed, but this is a last resort and shouldn't happen. Here's what you have to do:
    1)Register '${response.config?.url}' endpoint in src/config/api/endpoints.js
    2)Add the specific error that was received (shown below) and handle it in your catch block.
    Status: ${response.status}
    Code: '${response.data?.code}'
    Method: '${response.config?.method}'
    You can also add the 'all' property to allow any error from this endpoint to be passed on to the catch block in your code, though this is not recommended.
    Full error response:
    `,
      response
    );
};

/**
 * Determines if there is an internet connection or not
 * Shows a snackbar message accordingly
 */
export const timeoutHandler = async (error, showErrorSnackbar) => {
  const isConnected = await checkInternetConnection();

  if (isConnected) {
    defaultErrorHandler(error, showErrorSnackbar);
  } else {
    noConnectionHandler(showErrorSnackbar);
  }
};

/**
 * Forces logout through navigation
 * @param {string} message Message to show on snackbar, to explain to the user why they're being logged out
 * @param {array} events Events array for trackings
 */
export const forceLogout = () => {
  logger.warn('Force logout not implemented yet');
  // navigate(LOADING_ANIMATION, {
  //   forcedLogout: {
  //     shouldLogout: true,
  //     message,
  //     events,
  //     invalidToken,
  //   },
  // });
};

/* Async request cancellation logic */
let cancellableRequests = [];

/**
 * Pushes new cancel function to cancellableRequests array
 * @param {function} cancelFunction
 */
export const pushCancellableRequest = (cancelFunction) => {
  cancellableRequests.push(cancelFunction);
};
