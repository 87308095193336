import { SxProps } from '@mui/material';
import { NubiTheme } from '@theme';

const styles: {
  modalContent: SxProps<NubiTheme>;
  formSwitch: SxProps<NubiTheme>;
} = {
  modalContent: { display: 'flex', flexDirection: 'column', gap: '1rem' },
  formSwitch: { gap: '1rem', px: '1rem' },
};

export default styles;
