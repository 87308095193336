import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { apiClient } from 'config/api';
import { Alert, Button, StrongModalText, Snackbar } from '@atoms';
import { useUserSegments } from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { SelectSegment } from '@organisms';
import { useSegmentStore } from '@stores';
import useProspectCreationSelectors from '../prospectUpload/useProspectCreationSelectors';

/**
 * Prospect Send email Screen Component
 * @returns {JSX.Element} React component
 */
const ProspectSendEmail = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { shouldShowSelectors, shouldShowSegmentSelector } = useProspectCreationSelectors();
  const { selectedSegment } = useSegmentStore();
  const { data: SegmentsDetails } = useUserSegments();
  const segmentName = SegmentsDetails?.find((item) => item.number === selectedSegment)?.name;

  const disabledCreateProspect = !selectedSegment;
  /**
   * Handle submit
   */
  const handleSubmit = async () => {
    setLoading(true);

    const url = '/client/prospects/send-notification';
    const body = { segment: segmentName };

    try {
      await apiClient.post(url, body);
      setIsSuccess(true);
    } finally {
      setIsModalOpen(false);
      setLoading(false);
    }
  };

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <Stack direction='column' justifyContent='flex-start' gap={2} width={'100%'}>
          <Typography variant='h2'>{t('PROSPECTS:SEND_EMAIL:TITLE')}</Typography>
          <Typography variant='h3Regular' color='gray.90'>
            {t(
              shouldShowSelectors
                ? 'PROSPECTS:SEND_EMAIL:DESCRIPTION'
                : 'PROSPECTS:SEND_EMAIL:DESCRIPTION_CLIENT'
            )}
          </Typography>
        </Stack>
        <BoxContainer>
          {shouldShowSelectors ? (
            <>
              <Typography variant='h4'>{t('COMMON:CHOOSE_SEGMENT')}</Typography>
              {shouldShowSegmentSelector && <SelectSegment />}
            </>
          ) : (
            <Typography variant='h3Regular'>{t('PROSPECTS:SEND_EMAIL:BODY_CLIENT')}</Typography>
          )}
          <Stack direction='row' justifyContent='flex-end' paddingTop={2} gap={2}>
            <Button
              text='PROSPECTS:SEND_EMAIL:BUTTON'
              variant='contained'
              color='primary'
              onClick={() => setIsModalOpen(true)}
              disabled={disabledCreateProspect}
              size='large'
            />
          </Stack>
        </BoxContainer>
        <Alert
          variant='warning'
          modalVisible={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          handleSubmit={handleSubmit}
          closeButtonText='COMMON:CANCEL'
          title={t('PROSPECTS:SEND_EMAIL:MODAL:TITLE')}
          submitLoading={isLoading}
          content={
            shouldShowSelectors ? (
              <Trans i18nKey='PROSPECTS:SEND_EMAIL:MODAL:DESCRIPTION'>
                Estás a punto de enviar el email de registro a los colaboradores del segmento
                <StrongModalText>{{ segment: segmentName }}</StrongModalText>
                ¿Estás de acuerdo?
              </Trans>
            ) : (
              t('PROSPECTS:SEND_EMAIL:MODAL:DESCRIPTION_CLIENT')
            )
          }
        />
        <Snackbar
          open={isSuccess}
          handleClose={() => setIsSuccess(false)}
          severity='success'
          message={t('PROSPECTS:SEND_EMAIL:SUCCESS')}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default ProspectSendEmail;
