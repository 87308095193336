import { useTranslation } from 'react-i18next';
import { Tooltip, Box } from '@mui/material';
import { Button } from '@atoms';
import { useExportAllAccountsFiltered } from '@hooks';
import styles from './styles';

interface TemplateLinkDispersionProps {
  companyId: number;
}
/**
 * React component for a prospect template link that triggers the download of a file.
 *
 * @function
 * @name TemplateLinkProspect
 * @returns {React.Component} A React component representing a link to download a file template.
 */
const TemplateLinkDispersion = ({ companyId }: TemplateLinkDispersionProps): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, exportAllAccountsFiltered, error } = useExportAllAccountsFiltered();
  const disableButton = Boolean(error || isLoading || !companyId);

  /** Handles the click event on the link. */
  const handlerClick = () => {
    exportAllAccountsFiltered(companyId, {});
  };

  return (
    <Box sx={{ ...styles.box }}>
      <Tooltip title={t('COMMON:TOOLTIP:PROSPECT')}>
        <Button
          onClick={handlerClick}
          disabled={disableButton}
          isLoading={isLoading}
          content={t('COMMON:ACCOUNT_LINK_TITLE')}
          variant='text'
          customStyle={styles.button}
        />
      </Tooltip>
    </Box>
  );
};

export default TemplateLinkDispersion;
