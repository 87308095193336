import { usePagination } from '@hooks';
import { useCompanyStore } from '@stores';

export type ConsumptionParams = {
  lastName?: string;
  firstName?: string;
  cuit?: string;
  fromDate?: string;
  toDate?: string;
  page?: string;
  limit?: string;
};

interface UseConsumptionListProps {
  params: ConsumptionParams;
  setURLparams: () => void;
  onError?: (error: { response: { status: number } }) => void;
}

/**
 * useConsumptionList hook
 * @description This hook is used to fetch the consumption list and handle pagination
 * @param params
 * @param setURLparams
 */
const useConsumptionList = ({ params, setURLparams, onError }: UseConsumptionListProps) => {
  const { selectedCompany } = useCompanyStore();

  const paginationProps = usePagination('/client/consumption', {
    params: selectedCompany ? { ...params, companyId: String(selectedCompany) } : [],
    setURLparams,
    paramKeys: [
      'companyId',
      'lastName',
      'firstName',
      'cuil',
      'fromDate',
      'toDate',
      'page',
      'limit',
    ],
    onError,
  });

  return paginationProps;
};

export default useConsumptionList;
