import { useCallback, useState } from 'react';

export interface UseBooleanReturn {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

/** Boolean state logic handler */
const useBoolean = (defaultValue = false): UseBooleanReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
};

export default useBoolean;
