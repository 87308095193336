export const styles = {
  chip: { minWidth: '100px' },
  rowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  nameMaxWidth: {
    maxWidth: '120px',
  },
};
