const handleMaxWidth = {
  xs: '0',
  sm: '42.5rem',
  md: '56.25rem',
  lg: '75rem',
  xl: '96rem',
};

const styles = {
  container: (topPadding) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    pt: topPadding ? '5rem' : '2rem',
  }),
  content: (width) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    alignItems: 'center',
    maxWidth: { md: handleMaxWidth[width] },
    width: '100%',
  }),
};

export default styles;
