import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, CardMedia, CircularProgress } from '@mui/material';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { Alert, Button } from '@atoms';
import { useCompanyReports } from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import styles from './styles';
/**
 *
 * @returns
 */
function ReportDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading, error } = useCompanyReports();
  const { state } = useLocation();
  const isSuccess = !isLoading && data;
  const isError = error || (!isLoading && !data);

  /** Redirects to the home page.*/
  const goToHome = () => navigate('/');

  /** Handle redirects to the back page.*/
  const handleGoToBack = () => navigate(state?.goBack ?? '/home');

  return (
    <MainMenuLayout>
      <FullWidthContent>
        {isLoading && (
          <Box sx={styles.container}>
            <CircularProgress />
          </Box>
        )}
        <BoxContainer sx={styles.mainBox}>
          {isSuccess && <CardMedia component='iframe' src={data} sx={styles.cardMedia} />}
          <Alert
            modalVisible={isError}
            handleClose={goToHome}
            variant='error'
            title={'COMMON:ERRORS:GENERIC_ERROR_TITLE'}
            description={'COMMON:ERRORS:DEFAULT_ERROR'}
          />
        </BoxContainer>
        <Button
          variant='outlined'
          onClick={handleGoToBack}
          startIcon={<IconArrowNarrowLeft />}
          text={t('COMMON:GO_BACK')}
        />
      </FullWidthContent>
    </MainMenuLayout>
  );
}
ReportDetails.propTypes = {
  paramito: PropTypes.string,
};
export default ReportDetails;
