import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Divider,
  Typography,
  Box,
  List,
  ListItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { Link } from '@atoms';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import ITEMS from './constants';
import styles from './styles';

/**
 * Help screen
 */
const HelpScreen = () => {
  const { t } = useTranslation();
  /**
   * @returns Link component
   */
  const CustomLink = ({ children }) => (
    <Link
      href={`mailto:${children}`}
      content={children}
      target='_blank'
      customStyle={styles.customStyleLink}
    />
  );
  CustomLink.propTypes = {
    children: PropTypes.node,
  };

  /**
   * Render sublist items
   */
  const renderSublist = (sublist) => {
    return (
      <List sx={styles.subList}>
        {sublist.rows.map((subitem, subidx) => (
          <ListItem key={subidx} sx={styles.subListItem}>
            <Box sx={styles.linkContainer}>
              <Typography variant='body1' sx={styles.subListText}>
                {t(subitem.text)}
              </Typography>
              <Link href={subitem.url} content={t(subitem.textLink)} target='_blank' />
            </Box>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <BoxContainer sx={styles.boxContainer}>
          {ITEMS.map((item, index) => {
            const showBullet = item.content.type === 'list';
            return (
              <>
                <Accordion key={index} sx={styles.accordion}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDown />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant='h2'>{t(item.title)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails id={`panel${index}-content`} sx={styles.accordionDetail}>
                    {item.body && <Typography variant='body1'>{t(item.body)}</Typography>}
                    <List
                      {...(showBullet && {
                        sx: styles.list,
                      })}
                    >
                      {item.content.rows.map((contentRow, rowIndex) => (
                        <>
                          <ListItem key={rowIndex} sx={styles.listItem(showBullet)}>
                            <Typography variant={contentRow.type || 'body1'}>
                              <Trans
                                i18nKey={contentRow.text}
                                components={{
                                  bold: <strong />,
                                  CustomLink: <CustomLink />,
                                }}
                              />
                            </Typography>
                            {contentRow.subtext && (
                              <Typography variant='body1'>{t(contentRow.subtext)}</Typography>
                            )}
                          </ListItem>
                          {contentRow.sublist && renderSublist(contentRow.sublist)}
                        </>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
                {index + 1 !== ITEMS.length && <Divider />}
              </>
            );
          })}
        </BoxContainer>
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default HelpScreen;
