/**
 *Takes two objects and returns a string containing a list of the properties that are different
 * @param {object} initialValues - the initial values e.g {a: 1, b: 2, c: 3}
 * @param {object} finalValues - the  final values e.g {a: 1, b: 3, c: 2 }
 * @returns {string} - a list of the different properties e.g 'b, c'
 */
function getDifferentProperties(initialValues, finalValues) {
  const differentProperties = [];

  for (const key in finalValues) {
    if (initialValues[key] instanceof Date) {
      if (initialValues[key]?.getTime() !== finalValues[key]?.getTime()) {
        differentProperties.push(key);
      }
      continue;
    }
    if (initialValues[key] !== finalValues[key]) {
      differentProperties.push(key);
    }
  }

  return differentProperties.join(', ');
}

export default getDifferentProperties;
