import { Logger, trackings } from '.';
import { EVENT_ERROR, EVENT_LOG, FIREBASE } from '../globalConstants';

const logger = new Logger('Crashlytics Exception');

/**
 *
 *
 * @export
 * @class CrashReporting
 */
export default class CrashReporting {
  /**
   * Reports Log to Crashlytics (now using Analytics as placeholder)
   *
   * @static
   *
   * @memberOf CrashReporting
   */
  static log(logMessage) {
    logger.log('Log reported', logMessage);
    trackings([
      {
        type: FIREBASE,
        name: EVENT_LOG,
        props: {
          log: logMessage,
          level: 'info',
          fatal: false,
          timestamp: new Date().getTime(),
        },
      },
    ]);
  }

  /**
   *
   *
   * @static
   * @param {any} exception
   *
   * @memberOf CrashReporting
   */
  static error(exception) {
    logger.error('Error reported', exception.stack);

    trackings([
      {
        type: FIREBASE,
        name: EVENT_ERROR,
        props: {
          stacktrace: exception.stack?.split('\n')?.[0],
          level: 'error',
          fatal: true,
          timestamp: new Date().getTime(),
        },
      },
    ]);
  }
}
