import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Alert } from '@atoms';
import { ErrorList } from '@molecules';
import { useCustomerStore } from '@stores';
import { getAlertInfo } from './constants';
import AssignProductModal from './Modal';

/**
 * Renders a modal to assign a product to users
 * @param {boolean} props.assignProductOpen if modal is or not visible
 * @param {array} props.selectedUsers array of selected users
 * @param {() => void} props.setAssignProductOpen function to close the modal
 */
const AssignNewProduct = ({ setAssignProductOpen, assignProductOpen, selectedUsers }) => {
  const { t } = useTranslation();
  const [assignProductData, setAssignProductData] = useState(null);
  const [alertInfo, setAlertInfo] = useState(null);
  const { selectedCustomer, setSelectedCustomer } = useCustomerStore();

  useEffect(() => {
    if (assignProductData) {
      const info = getAlertInfo(assignProductData, t);
      setAlertInfo(info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignProductData]);

  useEffect(() => {
    if (selectedUsers.length > 0) {
      setSelectedCustomer(selectedUsers[0]?.customerId);
    }
  }, [selectedUsers, setSelectedCustomer]);

  /**
   * @returns error message to display in ErrorList
   */
  const getUsersFailedMessages = (item) => {
    return item.message;
  };

  /**
   * @returns user's first and last name to display in ErrorList
   */
  const getUsersFailedCustomLine = (item) => {
    return `${item.first_name} ${item.last_name}`;
  };

  /**
   * Sets assignProductData and alertInfo to null when Alert is closed
   */
  const handleOnCloseAlert = () => {
    setAssignProductData(null);
    setAlertInfo(null);
  };

  return (
    <>
      <AssignProductModal
        isOpen={assignProductOpen}
        setAssignProductOpen={setAssignProductOpen}
        selectedUsers={selectedUsers}
        setAssignProductData={setAssignProductData}
        customer_id={selectedCustomer}
      />
      <Alert
        modalVisible={alertInfo?.modalVisible}
        handleClose={handleOnCloseAlert}
        variant={alertInfo?.variant}
        title={alertInfo?.title}
        description={alertInfo?.description}
        closeButtonText={t('COMMON:ACCEPT')}
        content={
          alertInfo?.showErrorList && (
            <ErrorList
              errors={alertInfo?.errors}
              getMessages={getUsersFailedMessages}
              customLine={true}
              getCustomLine={getUsersFailedCustomLine}
            />
          )
        }
      />
    </>
  );
};

AssignNewProduct.propTypes = {
  assignProductOpen: PropTypes.bool,
  setAssignProductOpen: PropTypes.func,
  selectedUsers: PropTypes.array,
};

export default AssignNewProduct;
