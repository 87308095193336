import useSWR from 'swr';
/**
 * Obtains a list of categories by company
 * @param allowRequest - Indicates whether the request to fetch categories should be made. If `false`, the request will not be executed.
 * @param companyId - id of the company for which categories will be fetched.
 */
const useCustomAndGenericCategoriesByCompany = ({
  allowRequest = true,
  companyId,
}: {
  allowRequest: boolean;
  companyId: string;
}) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && companyId ? `/partner/${companyId}/categories` : false),
    {
      revalidateOnFocus: false,
    }
  );
  return { data: data || [], isLoading, error };
};
export default useCustomAndGenericCategoriesByCompany;
