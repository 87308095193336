const styles = {
  list: {
    gap: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem',
    maxHeight: '50vh',
  },
  optionButton: {
    padding: '0.53125rem 0.5rem',
    '&[aria-selected="true"]': {
      backgroundColor: ({ palette }) => palette.primary.lightest,
      color: ({ palette }) => palette.primary.main,
    },
  },
};

export default styles;
