type optionsProp = { noSymbol?: boolean; isCents?: boolean };

/**
 * @param {number} number Amount to be parsed
 * @param {object=} options
 * @param {boolean} options.noSymbol If true, the returned value will have a money symbol '$' on the left
 * @param {boolean} options.isCents If true, the number will be interpreted as cents, otherwise as pesos
 * @returns Returns parsed amount
 */
const parseAmount = (number: number, options?: optionsProp) => {
  const centsOrPesos = options?.isCents ? number / 100 : number;

  const parsed = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  }).format(centsOrPesos);

  return options?.noSymbol ? parsed.slice(2) : parsed;
};

export default parseAmount;
