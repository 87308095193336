const styles = {
  rootStyle: {
    display: 'flex',
    flex: '1',
    width: '100%',
    flexDirection: 'column',
  },
  container: (responsiveGrid) => ({
    display: 'grid',
    gridTemplateColumns: responsiveGrid ? '1fr' : '264px 1fr',
    gridTemplateRows: '72px 1fr',
    gap: '0 0',
    gridTemplateAreas: responsiveGrid
      ? `
        'topHeader'
        'mainContainer'
      `
      : `
        'sidebarNav topHeader'
        'sidebarNav mainContainer'
      `,
  }),
  topNav: {
    gridArea: 'topHeader',
  },
  sideBar: {
    gridArea: 'sidebarNav',
  },
  sectionContainer: (basedGrid) => ({
    gridArea: 'mainContainer',
    maxWidth: basedGrid ? '1140px' : '1102px',
    width: '100%',
    margin: '0 auto',
  }),
};
export default styles;
