/**
 * Takes a key value string object and an array of keys.
 * Removes the empty or null keys from the object
 * Returns a filtered object, keeping only the specified keys
 *
 * @param {object} allParams Takes the object to evaluate/filter
 * @param {array} searchKeys An array of key strings to include in the final object
 * @returns The final filtered object with trimmed values
 */
function getSearchParams(allParams, searchKeys) {
  if (Object.keys(allParams).length === 0) return {};
  searchKeys.push('page', 'limit');
  const filteredParams = Object.entries(allParams)
    .filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return searchKeys.includes(key) && value != null && value !== '';
    })
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.join(',')];
      } else {
        return [key, value.trim()];
      }
    });
  if (filteredParams.length) return Object.fromEntries(filteredParams);
  return {};
}
export default getSearchParams;
