import { SxProps } from '@mui/material/styles';
import { NubiTheme } from '@theme';

type Variant = 'text' | 'contained' | 'outlined';

interface Styles {
  spinner: (theme: NubiTheme) => SxProps<NubiTheme>;
  icon: (theme: NubiTheme, disabled: boolean, variant: Variant) => SxProps<NubiTheme>;
  iconButton: (theme: NubiTheme, disabled: boolean, variant: Variant) => SxProps<NubiTheme>;
  span: SxProps<NubiTheme>;
}

export const STYLES: Styles = {
  spinner: (theme) => ({
    color: theme.palette.primary.main,
    height: '1.25rem',
  }),
  icon: (theme, disabled, variant) => {
    const dimensions = {
      width: '1.25rem',
      height: '1.25rem',
    };

    switch (variant) {
      case 'text':
      case 'outlined':
        return {
          ...dimensions,
          color: disabled ? theme.palette.action.disabled : theme.palette.primary.main,
        };

      case 'contained':
        return {
          ...dimensions,
          color: disabled ? theme.palette.common.white : theme.palette.common.white,
        };

      default:
        return {
          ...dimensions,
          color: theme.palette.primary.main,
        };
    }
  },

  iconButton: (theme, disabled, variant) => ({
    padding: '0.813rem 1rem',
    pointerEvents: disabled ? 'none' : 'auto',
    borderRadius: '0.5rem',
    '>*': { borderRadius: '0.5rem' },
    ...(variant === 'contained' && {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      ...(disabled && {
        backgroundColor: `${theme.palette.action.disabledBackground} !important`,
      }),
    }),
    ...(variant === 'outlined' && {
      border: `0.06rem solid ${theme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      ...(disabled && {
        borderColor: theme.palette.action.disabled,
      }),
    }),
    ...(variant === 'text' && {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      ...(disabled && {
        backgroundColor: 'transparent !important',
      }),
    }),
  }),
  span: { display: 'inline-block' },
};
