import { createTheme as createMuiTheme } from '@mui/material';
import { createComponents } from './create-components';
import { createShadows } from './create-shadows';
import { palette } from './palette';
import { typography } from './typography';

const shadows = createShadows();
const components = createComponents({ typography });

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  components,
  palette,
  shadows,
  shape: {
    borderRadius: 8,
  },
  typography,
});

export type NubiTheme = typeof theme;
