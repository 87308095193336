import useSWR from 'swr';

/**
 * Obtains a list of products by company
 */
const useProductsByCompany = ({ allowRequest = true, companyId }) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && companyId ? `/partner/products/${companyId}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data: data || [], isLoading, error };
};
export default useProductsByCompany;
