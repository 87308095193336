import PropTypes from 'prop-types';
import { Avatar, Box, Chip, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { BoxContainer } from '@layouts';
import { ThreeDotsMenu } from '@molecules';
import styles from './styles';

/**
 * Card header component
 */
const CardHeader = ({
  title,
  chipLabel,
  leftComponentOptions,
  rightComponentOptions,
  withMenu,
  subtitleOptions,
}) => {
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  /**
   * @returns the left component depending on the type
   */
  const renderLeftComponent = () => {
    switch (leftComponentOptions.type) {
      case 'avatar':
        return (
          <Avatar sx={styles.avatar}>
            <SvgIcon>{leftComponentOptions.icon}</SvgIcon>
          </Avatar>
        );
      case 'icon':
        return (
          <SvgIcon sx={leftComponentOptions.customStyle?.icon}>{leftComponentOptions.icon}</SvgIcon>
        );
      default:
        return null;
    }
  };

  /**
   * @returns the right component depending on the type
   */
  const renderRightComponent = (renderAll) => {
    if (
      renderAll &&
      rightComponentOptions &&
      rightComponentOptions.text &&
      rightComponentOptions.menuOptions
    ) {
      return (
        <Stack direction='row' spacing={1}>
          <Box sx={styles.rightTextContainer}>
            <Typography variant='h1'>{rightComponentOptions.text}</Typography>
            {rightComponentOptions.icon && (
              <Box onClick={rightComponentOptions.onClickIcon} sx={styles.iconContainer}>
                <SvgIcon>{rightComponentOptions.icon}</SvgIcon>
              </Box>
            )}
          </Box>
          <ThreeDotsMenu options={rightComponentOptions.menuOptions} />
        </Stack>
      );
    }

    switch (rightComponentOptions.type) {
      case 'threeDotsMenu':
        return <ThreeDotsMenu options={rightComponentOptions.menuOptions} />;
      case 'text':
        return (
          <Box sx={styles.rightTextContainer}>
            <Typography variant='h1'>{rightComponentOptions.text}</Typography>
            {rightComponentOptions.icon && (
              <Box onClick={rightComponentOptions.onClickIcon} sx={styles.iconContainer}>
                <SvgIcon>{rightComponentOptions.icon}</SvgIcon>
              </Box>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <BoxContainer>
      <Box sx={styles.container(responsive)}>
        <Box sx={styles.leftContainer}>
          {renderLeftComponent()}
          <Box>
            <Box sx={styles.titleContainer(responsive)}>
              <Typography variant='h2'>{title}</Typography>
              {chipLabel && <Chip label={chipLabel} size='small' />}
            </Box>
            <Box sx={styles.subtitleContainer}>
              {subtitleOptions?.icon && <SvgIcon>{subtitleOptions.icon}</SvgIcon>}
              <Typography variant='body' sx={styles.subtitle}>
                {subtitleOptions.text}
              </Typography>
            </Box>
          </Box>
        </Box>
        {renderRightComponent(withMenu)}
      </Box>
    </BoxContainer>
  );
};

CardHeader.propTypes = {
  title: PropTypes.string,
  chipLabel: PropTypes.string,
  leftComponentOptions: PropTypes.shape({
    type: PropTypes.string,
    icon: PropTypes.elementType,
    customStyle: PropTypes.shape({
      icon: PropTypes.object,
    }),
  }),
  rightComponentOptions: PropTypes.shape({
    type: PropTypes.string,
    menuOptions: PropTypes.object,
    text: PropTypes.text,
    icon: PropTypes.elementType,
    onClickIcon: PropTypes.func,
  }),
  withMenu: PropTypes.bool,
  subtitleOptions: PropTypes.shape({
    icon: PropTypes.elementType,
    text: PropTypes.string,
  }),
};
export default CardHeader;
