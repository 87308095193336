import useSWR from 'swr';

/**
 * Gets all benefits
 */
const useBenefits = (allowRequest = true) => {
  const props = useSWR(() => allowRequest && '/client/getAllBenefits', {
    revalidateOnFocus: false,
  });

  return props;
};

export default useBenefits;
