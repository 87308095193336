import { SxProps } from '@mui/material';
import { NubiTheme } from '@theme';

const styles: {
  productSkeleton: SxProps<NubiTheme>;
} = {
  productSkeleton: { height: '740px', borderRadius: '20px' },
};

export default styles;
