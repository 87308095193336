const styles = {
  container: (topPadding) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    width: '100%',
    pt: topPadding ? '2.5rem' : '1rem',
    padding: '2.5rem',
  }),
};

export default styles;
