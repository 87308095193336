import useSWR from 'swr';
import { apiClient } from 'config/api';

interface CustomerData {
  id: number;
  legalName: string;
  legalIdentification: string;
  fkCustomerGroup: number;
}

interface CompanyPostalAddress {
  customerId: number;
  street: string;
  number: number;
  floor?: number;
  apartment?: string;
  postalCode: string;
  cityName: string;
  stateName: string;
}

/** Fetch customer data by company id then fetch postal address by customer id  */
const fetchPostalAddressOfACompany = async (companyId: string): Promise<CompanyPostalAddress> => {
  const { data: customerData } = await apiClient.get<CustomerData>(
    `/partner/company/${companyId}/customer`
  );
  const { data: companyPostalAddress } = await apiClient.get(
    `/partner/address?customerId=${customerData.id}`
  );

  return companyPostalAddress;
};

/** Handle state of fetching postal address by company id */
export default function usePostalAddressOfACompany(companyId: string) {
  const props = useSWR(`partner-${companyId}-postal-address`, () =>
    fetchPostalAddressOfACompany(companyId)
  );

  return props;
}
