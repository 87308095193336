import axios from 'axios';
import { TIMEOUT_LIMIT } from '@globalConstants';
import { attachCancelToken } from '../interceptors';

const axiosConfig = {
  baseURL: process.env.REACT_APP_URL_PRISMA_PROXY,
  timeout: TIMEOUT_LIMIT,
};

const apiPrisma = axios.create(axiosConfig);

apiPrisma.interceptors.request.use(attachCancelToken(TIMEOUT_LIMIT));

export default apiPrisma;
