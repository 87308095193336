import { SxProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { NubiTheme } from '@theme';

export const styles: {
  buttonRetrieveBalance: SystemStyleObject<NubiTheme>;
  value: SystemStyleObject<NubiTheme>;
  padding0: { padding: 0 };
  cardContainer: SystemStyleObject<NubiTheme>;
  container: SystemStyleObject<NubiTheme>;
  productSkeleton: SxProps<NubiTheme>;
} = {
  buttonRetrieveBalance: { button: { padding: '0.5rem 1rem', fontSize: '0.8rem' } },
  value: { color: ({ palette }) => palette.gray[90] },
  padding0: { padding: 0 },
  cardContainer: { overflow: 'visible' },
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    gap: '0.5rem',
    padding: '1.5rem',
    borderRadius: '1rem',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  },
  productSkeleton: { height: '740px', borderRadius: '20px', display: 'flex', width: '328px' },
};

export const propertyListItemStyle = {
  textContainer: {
    flexDirection: 'column',
    gap: 0,
  },
};
