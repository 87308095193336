const styles = {
  iconAvatar: { color: ({ palette }) => palette.primary.main, width: '3rem', height: '3rem' },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerProductMovements: (responsive) => ({
    flexDirection: 'row',
    display: 'flex',
    gap: '0.5rem',
    flexWrap: responsive ? 'wrap' : 'nowrap',
  }),
  buttonToolbar: {
    button: {
      maxWidth: '200px',
    },
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

export default styles;
