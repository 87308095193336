import useSWR from 'swr';
import { useGlobalErrorStore } from '@stores';

/**
 * Fetches the user's companies (razones sociales)
 * @param {SWRConfiguration} config Object containing swr config
 * @returns {array} The companies the user can operate with
 */
const useUserCompanies = () => {
  const { setError, setShowError } = useGlobalErrorStore();
  /**
   * If companies request fails
   */
  function onError(error) {
    const response = error?.response;

    if (response?.status === 404 && response?.data?.errors?.[0]) {
      setError({
        title: 'DISPERSIONS:ERRORS:INVALID_COMPANIES_TITLE',
        description: 'DISPERSIONS:ERRORS:INVALID_COMPANIES_DESCRIPTION',
      });
    } else {
      setError({
        title: 'DISPERSIONS:ERRORS:UNABLE_TO_FETCH_COMPANIES_TITLE',
        description: 'DISPERSIONS:ERRORS:UNABLE_TO_FETCH_COMPANIES_DESCRIPTION',
      });
    }

    setShowError(true);
  }

  const props = useSWR('/partner/companies', { onError, revalidateOnFocus: false });

  return props;
};

export default useUserCompanies;
