import { useState, useEffect, useCallback } from 'react';
import { apiClient } from 'config/api';
import { PERMISSIONS } from '@globalConstants';
import { useCompanyStore } from '@stores';
import usePermissions from './usePermissions';

/** Timeout function */
function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/** Logic to check the registration status of a company in Prisma
 * @param hasAtLeastOneProductByPrisma condition to kwon if company has selected a product by Prisma
 */
const useCompanyNeedsRegistrationStatusInPrisma = (hasAtLeastOneProductByPrisma: boolean) => {
  const isAllowToRegisterInPrisma = usePermissions([PERMISSIONS.registerCompanyInPrisma]);
  const { selectedCompany } = useCompanyStore();
  const [companyNeedsToRegisterInPrisma, setCompanyNeedsToRegisterInPrisma] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const reset = useCallback(() => {
    setCompanyNeedsToRegisterInPrisma(false);
    setIsLoading(false);
    setError(undefined);
  }, []);

  useEffect(
    function checkIfCompanyNeedsToRegisterInPrisma() {
      if (!isAllowToRegisterInPrisma) {
        setCompanyNeedsToRegisterInPrisma(false);
        return;
      }

      if (!hasAtLeastOneProductByPrisma) {
        timeout(1000).then(() => {
          setCompanyNeedsToRegisterInPrisma(false);
        });

        return;
      }
      setIsLoading(true);
      setError(undefined);
      apiClient
        .get(`/partner/product/prisma-status/${selectedCompany}`)
        .then(({ data }) => {
          setCompanyNeedsToRegisterInPrisma(!data.prismaStatus);
        })
        .catch(() => {
          setError(new Error('Cannot get prisma status'));
        })
        .finally(() => setIsLoading(false));
    },
    [hasAtLeastOneProductByPrisma, selectedCompany, isAllowToRegisterInPrisma]
  );

  return { data: companyNeedsToRegisterInPrisma, isLoading, error, reset };
};

export default useCompanyNeedsRegistrationStatusInPrisma;
