import { usePagination } from '@hooks';
import { apiClient } from '@config/api/instances';

/**
 * Data returned by the hook
 * @typedef {object} CommerceProps
 * @property {object} data - The response data from the API.
 * @property {boolean} isLoading - Indicates whether the data is currently being loaded. (from SWR)
 * @property {Error} error - The error object, if an error occurred during the request. (from SWR)
 * @property {boolean} isValidating - Indicates whether the data is currently being revalidated. (from SWR)
 * @property {function} handleRowsPerPageChange - Function to handle the change of rows per page.
 * @property {function} handlePageChange - Function to handle the change of page.
 * @property {boolean} filtersOpen - Indicates whether the filters are open.
 * @property {function} setFiltersOpen - Function to update the state of filtersOpen.
 * @property {function} onFiltersFormSubmit - Function to handle the submission of the filters form.
 * @property {function} deleteCommerce - Function to delete a commerce
 * @property {function} createCommerce - Function to create a commerce
 */

/**
 *
 * A reusable SWR hook for paginated search.
 * @param {string} url - The API endpoint URL.
 * @param {object} options - Options for pagination or search.
 * @param {object} options.params - Key-value object containing filtering/data params of the request.
 * @param {function} options.setURLparams - Function to update the URL parameters.
 * @returns {PaginationHookResult} - An object containing the following properties and functions:
 */
const useCommerces = ({ params, setURLparams, onError }) => {
  const endpoint = '/commerce';

  const paginationProps = usePagination(endpoint, {
    params,
    setURLparams,
    paramKeys: ['commerceName'],
    shouldFetchWithoutParams: false,
    onError,
  });

  /**
   * Deletes a commerce
   * @param {string} commerceId - The id of the commerce
   */
  async function deleteCommerce(commerceId) {
    await apiClient.delete(`${endpoint}/${commerceId}`);
  }

  const staticProps = { active: true, paymentPlatform: true, commerceCode: '', type: 'comercio' };

  /**
   * Creates a commerce
   * @param {object} data - The id of the commerce
   */
  async function createCommerce(body) {
    await apiClient.post(endpoint, {
      ...body,
      ...staticProps,
    });
  }

  return { ...paginationProps, deleteCommerce, createCommerce };
};

export default useCommerces;
