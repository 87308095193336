import * as Yup from 'yup';
import { formatDate, parseAmount } from '@utils';
import DownloadConsumptionButton from './DownloadConsumptionButton';
import { Consumption } from './types';

export const DEFAULT_PAGE = 1;

export const DEFAULT_ROWS_PER_PAGE = 25;

interface TableColumn {
  title: string;
  content: (data: Consumption) => string | JSX.Element;
}
/**
 * Returns the table columns
 * @param download Function to download a receipt
 * @param isLoading Boolean to indicate if the download is in progress
 * @returns Array of table columns
 */
export const buildColumnsForConsumptionList = (
  onDownload: (transactionId: string) => Promise<void>
): TableColumn[] => [
  {
    title: 'COMMON:DATE',
    content: ({ createdAt }) => String(formatDate(createdAt, { dateOnly: true })),
  },
  { title: 'COMMON:NAME', content: ({ firstName }) => firstName },
  { title: 'COMMON:LAST_NAME', content: ({ lastName }) => lastName },
  { title: 'COMMON:CUIL', content: ({ cuil }) => cuil },
  { title: 'COMMON:COMMERCE', content: ({ commerceName }) => commerceName },
  { title: 'COMMON:AMOUNT', content: ({ amount }) => parseAmount(amount) },
  {
    title: '',
    content: ({ hasReceipt, transactionId }) => (
      <DownloadConsumptionButton
        disabled={!hasReceipt}
        onDownload={() => onDownload(transactionId)}
        tooltip={!hasReceipt ? 'CONSUMPTION:NOT_FOUND' : undefined}
      />
    ),
  },
];

export const PARAM_KEYS = ['companyId'];

const toDate = new Date();
const minDate = new Date('2024-08-01T03:00:00Z');
const fromDate = new Date(toDate.getTime() - 30 * 24 * 60 * 60 * 1000);

export const FIELDS_CONFIG = [
  {
    name: 'fromDate',
    label: 'COMMON:FROM_DATE',
    defaultValue: fromDate < minDate ? minDate.toISOString() : fromDate.toISOString(),
    type: 'datePicker',
    minDate,
  },
  {
    name: 'toDate',
    label: 'COMMON:TO_DATE',
    defaultValue: toDate.toISOString(),
    type: 'datePicker',
    minDate,
  },
  { name: 'firstName', label: 'COMMON:NAME', defaultValue: '', type: 'input' },
  { name: 'lastName', label: 'COMMON:LAST_NAME', defaultValue: '', type: 'input' },
  { name: 'cuil', label: 'COMMON:CUIL', defaultValue: '', type: 'input', isCollapsible: true },
];

export const INITIAL_VALUES = FIELDS_CONFIG.reduce(
  (acc, { name, defaultValue }) => ({ ...acc, [name]: defaultValue }),
  {}
);

export const validationSchema = Yup.object().shape({
  fromDate: Yup.date()
    .required()
    .test('is-greater', 'COMMON:VALIDATIONS:DATE_VALUE_INVALID', function (fromDate) {
      const { toDate } = this.parent;
      return fromDate && toDate ? new Date(toDate).getTime() >= new Date(fromDate).getTime() : true;
    }),
  toDate: Yup.date()
    .required()
    .test('is-greater', 'COMMON:VALIDATIONS:DATE_VALUE_INVALID', function (toDate) {
      const { fromDate } = this.parent;
      return fromDate && toDate ? new Date(toDate).getTime() >= new Date(fromDate).getTime() : true;
    }),
  firstName: Yup.string(),
  lastName: Yup.string(),
  cuil: Yup.string()
    .matches(/^\d+$/, 'COMMON:VALIDATIONS:INVALID_CUIL')
    .min(9, 'COMMON:VALIDATIONS:CUIL_INVALID_LENGTH')
    .max(11, 'COMMON:VALIDATIONS:CUIL_INVALID_LENGTH'),
});
