import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styles from './styles';

/**
 * A layout component for the base full width content
 * @param {React.ReactNode} children
 * @param {React.ReactNode} sx styles
 * @returns
 */
const BoxContainer = ({ children, sx }) => {
  return <Box sx={[styles.container, sx]}>{children}</Box>;
};

BoxContainer.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default BoxContainer;
