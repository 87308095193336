import { PropsWithChildren, ReactNode, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import { CustomStyles } from '@types';
import styles from './styles';

interface PropertyListItemProps extends PropsWithChildren {
  label: string;
  value: ReactNode;
  customStyle?: CustomStyles;
}

/**
 * Render a vertical or horizontal listItem
 * @param {object} props
 * @param  {string} props.align horizontal or vertical
 * @param  {React.ReactElement} props.children
 * @param  {string} props.label
 * @param  {string | string[]} props.value
 */
const PropertyListItem = (props: PropertyListItemProps) => {
  const { t } = useTranslation();
  const { children, label, value, customStyle = {} } = props;

  /** Render value view */
  const renderSecondary = (): ReactNode => {
    const isValueAnElement = isValidElement(value);

    if (children && !isValueAnElement) {
      return children;
    }

    if (isValueAnElement) {
      return value;
    }

    return (
      <Typography variant='body1' sx={styles.secondaryText}>
        {value}
      </Typography>
    );
  };

  return (
    <ListItem disablePadding>
      <ListItemText
        sx={[styles.textContainer, customStyle?.textContainer]}
        disableTypography
        primary={
          <Box>
            <Typography variant='body2' sx={styles.primaryText}>
              {t(label)}
            </Typography>
          </Box>
        }
        secondary={renderSecondary()}
      />
    </ListItem>
  );
};

export default PropertyListItem;
