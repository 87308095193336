const styles = {
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      '> input': {
        paddingY: '0.25rem !important',
      },
    },
  },
  inputLabel: { display: 'flex', alignItems: 'center', gap: '0.3rem' },
  skeletonBox: { borderRadius: 1, overflow: 'hidden' },
  skeleton: { height: '3.063rem' },
  menuItem: { padding: 0, marginBottom: '0.25rem' },
};

export default styles;
