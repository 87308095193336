import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@emotion/react';
import { IconSquare, IconSquareCheck } from '@tabler/icons-react';
import { Button, Alert } from '@atoms';
import {
  useSessionStorageState,
  useSelectedCompanyDetails,
  useUserSegments,
  useCreateProspect,
} from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { Table } from '@molecules';
import { ProspectsConfirmationModal } from '@organisms';
import { useSegmentStore, useCompanyStore } from '@stores';
import { INITIAL_VALUES, COLUMNS, COLUMNS_WITH_TOOLTIP } from './constants';
import QuickCreationModal from './QuickCreationModal';
import styles from './styles';
import useProspectCreationSelectors from '../prospectUpload/useProspectCreationSelectors';
/**
 * Prospect Creation Screen Component
 * @returns {JSX.Element} React component
 */
const ProspectCreationScreen = () => {
  const { t } = useTranslation();
  const [data, setData] = useSessionStorageState('ProspectCreationScreen', []);
  const [open, setOpen] = useState(true);
  const [sendEmail, setSendEmail] = useState(true);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { showSelectedSegmentInConfirmation } = useProspectCreationSelectors();
  const { selectedSegment, setSelectedSegment } = useSegmentStore();
  const { selectedCompany, setSelectedCompany } = useCompanyStore();
  const { data: companyDetails } = useSelectedCompanyDetails();
  const { data: SegmentsDetails } = useUserSegments();
  const { isLoading, createProspect, confirmCreateProspect } = useCreateProspect();
  const [confirmProspects, setConfirmProspects] = useState({
    openModal: false,
    id: null,
    total: null,
  });
  const { palette } = useTheme();
  const { shouldShowCompanySelector, shouldShowSegmentSelector } = useProspectCreationSelectors();
  const segmentName = SegmentsDetails?.find((item) => item.number === selectedSegment)?.name;
  const companyName = companyDetails?.description;

  useEffect(() => {
    if (!selectedSegment && data.length > 0 && shouldShowSegmentSelector)
      setSelectedSegment(data[0].segment.value);
    if (!selectedCompany && data.length > 0 && shouldShowCompanySelector)
      setSelectedCompany(data[0].company.value);
    if (data.length > 0) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Close the modal */
  const handleClose = () => setOpen(false);

  /** Open the modal */
  const handleOpen = () => setOpen(true);

  /**
   * Handle change event for form data
   * @param {object} values - Updated form values
   */
  const handleChange = (values) => {
    setData([
      ...data,
      {
        ...values,
        segment: { value: selectedSegment, name: segmentName },
        company: { value: selectedCompany, name: companyName },
      },
    ]);
    const { Producto, Rubro } = values;
    setInitialValues({ ...initialValues, Producto, Rubro });
  };

  /**
   * Remove data item from the list
   * @param {number} index - Index of the item to remove
   */
  const handleRemoveData = (index) => {
    setData(data.filter((_, i) => i !== index));
    data.length === 1 && handleOpen();
  };

  /**
   * Function for sending data when the service is ready, while mocked.
   * @returns {Promise<void>}
   */
  const handelSendData = async () => {
    const prospectsData = data.map((item) => ({
      ...item,
      Rubro: item.Rubro.join(', '),
    }));
    const { prospectId, total } = await createProspect({
      segment: segmentName,
      companyId: selectedCompany,
      prospectsData,
      notification: sendEmail,
    });

    if (prospectId && total) {
      setConfirmProspects({
        openModal: true,
        id: prospectId,
        total,
      });
    } else {
      setIsError(true);
    }
  };

  /**
   * Handles confirmation of prospect creation.
   * @param {boolean} confirm - Confirmation status.
   * @returns {Promise<void>}
   */
  const handleConfirmation = async (confirm) => {
    const data = await confirmCreateProspect({
      prospectId: confirmProspects.id,
      confirm,
    });
    if (data) {
      if (confirm) {
        setSendEmail(true);
        setIsSuccess(true);
        setData([]);
        setOpen(true);
      }
    } else {
      setIsError(true);
    }

    setConfirmProspects({ id: null, openModal: false, count: null });
  };

  const disabledCreateProspect = !selectedSegment || !selectedCompany;

  const disabledSendProspect = data.length === 0 || disabledCreateProspect;

  /**
   * handle on change of send email checkbox
   * @param {*} event Check on change event
   */
  const handleSendEmail = (event) => setSendEmail(event.target.checked);

  return (
    <MainMenuLayout>
      {open ? (
        <FullWidthContent>
          <QuickCreationModal
            data={data}
            open={open}
            onClose={handleClose}
            onChange={handleChange}
            initialValues={initialValues}
          />
        </FullWidthContent>
      ) : (
        <FullWidthContent>
          <Stack direction='row' justifyContent='flex-end'>
            <Button
              text='PROSPECTS:QUICK_CREATION_MODAL:TITLE'
              variant='contained'
              color='primary'
              onClick={handleOpen}
              size='large'
            />
          </Stack>
          <Table
            showTooltip={COLUMNS_WITH_TOOLTIP}
            disableControls
            items={data}
            columns={COLUMNS(shouldShowCompanySelector, shouldShowSegmentSelector)}
            onDeleteRow={handleRemoveData}
          />
          <BoxContainer sx={styles.checkboxContainer}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checkedIcon={<IconSquareCheck />}
                  icon={<IconSquare />}
                  style={{ color: palette.primary.main }}
                />
              }
              checked={sendEmail}
              onChange={handleSendEmail}
              label={t('PROSPECTS:ADD_PROSPECTS_SEND_EMAIL')}
            />
          </BoxContainer>
          <Stack direction='row' justifyContent='flex-end' paddingTop={2}>
            <Button
              variant='contained'
              onClick={handelSendData}
              content={t('PROSPECTS:SEND_DATA')}
              disabled={disabledSendProspect || isLoading}
              isLoading={isLoading}
            />
          </Stack>
        </FullWidthContent>
      )}
      <ProspectsConfirmationModal
        companyName={companyName}
        segmentName={segmentName}
        confirmProspects={confirmProspects}
        disableButton={isLoading}
        handleConfirmation={handleConfirmation}
        isLoading={isLoading}
        showSelectedSegmentInConfirmation={showSelectedSegmentInConfirmation}
      />
      <Alert
        modalVisible={isSuccess}
        handleClose={() => setIsSuccess(false)}
        variant='success'
        title={'PROSPECTS:SUCCESS_TITLE'}
        description={'PROSPECTS:SUCCESS_BODY'}
      />
      <Alert
        modalVisible={isError}
        handleClose={() => setIsError(false)}
        variant='error'
        title={'COMMON:ERRORS:GENERIC_ERROR_TITLE'}
        description={'COMMON:ERRORS:DEFAULT_ERROR'}
      />
    </MainMenuLayout>
  );
};

export default ProspectCreationScreen;
