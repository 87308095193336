import { useCallback, useState } from 'react';

export interface UseModalReturn<T> {
  open: boolean;
  openModal: (data: T) => void;
  closeModal: () => void;
  data?: T;
}
/** Modal state logic */
const useModal = <T = undefined>(): UseModalReturn<T> => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<T | undefined>();

  const openModal = useCallback((item: T) => {
    setOpen(true);
    setData(item);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    setData(undefined);
  }, []);

  return { closeModal, open, openModal, data };
};

export default useModal;
