import { blue } from 'theme/colors';

const styles = {
  paper: {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    // gap: '1rem',
    maxWidth: { lg: '40%' },
    marginY: '1rem',
    minWidth: '350px',
  },
  iconBox: {
    justifyContent: 'center',
    display: 'flex',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    backgroundColor: blue.main,
    width: 'fit-content',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    margin: { xs: '1rem', sm: '1.5rem', md: '2.5rem' },
  },
  balanceOnlyCard: {
    justifyContent: 'flex-start',
    gap: '1rem',
  },
};

export default styles;
