import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton as IconButtonMaterial,
  Box,
  CircularProgress,
  Tooltip,
  useTheme,
} from '@mui/material';
import { STYLES } from './styles';

interface IconButtonProps {
  disabled?: boolean;
  onClick: () => Promise<void> | void;
  tooltip?: string;
  IconComponent: React.ElementType;
  isLoading?: boolean;
  variant?: 'text' | 'contained' | 'outlined';
}

/**
 * IconButton
 * @description A button to download a receipt for a given transaction
 * @param props - The props for the IconButton component
 * @param props.disabled - Boolean to indicate if the download button should be disabled
 * @param props.onClick - Function to call when the download button is clicked
 * @param props.tooltip- Text to display in the tooltip
 * @param props.IconComponent - The icon component to display in the button
 * @param props.variant - The variant to use for the button
 * @returns {JSX.Element} - The IconButton component
 */
const IconButton: React.FC<IconButtonProps> = ({
  disabled = false,
  onClick,
  tooltip,
  IconComponent,
  isLoading = false,
  variant = 'text',
}): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  const iconStyle = STYLES.icon(theme, disabled, variant);
  const buttonStyles = STYLES.iconButton(theme, disabled, variant);
  const spinnerStyle = STYLES.spinner(theme);

  return (
    <Tooltip title={tooltip && t(tooltip)}>
      <Box component='span' sx={STYLES.span}>
        <IconButtonMaterial onClick={onClick} disabled={disabled || isLoading} sx={buttonStyles}>
          {isLoading ? (
            <CircularProgress size={20} sx={spinnerStyle} />
          ) : (
            <IconComponent style={iconStyle} />
          )}
        </IconButtonMaterial>
      </Box>
    </Tooltip>
  );
};

export default IconButton;
