/**
 * Converts a JavaScript Date object to an ISO 8601 date string.
 * @param {Date} date - The JavaScript Date object to convert.
 * @param {Object} [options] - Optional parameters.
 * @param {boolean} [options.onlyDate=true] - Specify whether to include only the date portion (YYYY-MM-DD) or the full ISO 8601 string.
 * @returns {string} The ISO 8601 formatted date string.
 */
export function dateToIsoString(date, { onlyDate = true } = {}) {
  let isoString = date.toISOString();

  if (onlyDate) {
    isoString = isoString.substring(0, 10);
  }

  return isoString;
}

/**
 * Converts a string in the format 'YYYY-MM-DD' to a JavaScript Date object.
 * @param {string} dateString - The string representing the date in the format 'YYYY-MM-DD'.
 * @returns {Date} The JavaScript Date object representing the input date string.
 */
export function isoStringToDate(dateString) {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  return date;
}
