import { MONTHS } from '@globalConstants';

interface Month {
  text: string;
  value: string;
}

/**
 * Creates a calendar with years and months.
 *
 * This function generates a Map of calendars where each key is a year
 * and the corresponding value is an array of months for that year.
 *
 * @param sinceYear The year from which to start
 * @param sinceMonth The month of since year from which to start
 */
function createYearsAndMonthsCalendar(
  sinceYear?: number,
  sinceMonth?: string
): { calendar: Map<number, Month[]>; years: number[] } {
  const now = new Date();
  const currentYear = now.getFullYear();

  const calendar = new Map();

  if (!sinceYear || sinceYear > currentYear) {
    sinceYear = currentYear;
  }

  for (let year = sinceYear; year <= currentYear; year++) {
    const months: Month[] = [];
    const currentMonth = now.getMonth() + 1;

    if (year === sinceYear && sinceMonth !== undefined) {
      const sinceMonthIndex = MONTHS.findIndex(
        (month) => Number(month.value) === parseInt(sinceMonth, 10)
      );
      const maxMonth = year === currentYear ? currentMonth : MONTHS.length;

      for (let i = sinceMonthIndex; i < maxMonth; i++) {
        months.push(MONTHS[i]);
      }
    } else {
      const maxMonth = year === currentYear ? currentMonth : undefined;

      months.push(...MONTHS.slice(0, maxMonth));
    }

    calendar.set(year, months);
  }

  return { calendar, years: Array.from(calendar.keys()) };
}

export default createYearsAndMonthsCalendar;
