import { Box } from '@mui/material';
import { useUser } from '@hooks';
import { MainMenuLayout } from '@layouts';
import ExternalUserHome from './ExternalUserHome';
import InternalUserHome from './InternalUserHome';
import styles from './styles';
/**
 * Home screen
 */
const HomeScreen = () => {
  const { user } = useUser();

  return (
    <MainMenuLayout>
      <Box sx={styles.container}>
        {user.isInternal ? <InternalUserHome /> : <ExternalUserHome />}
      </Box>
    </MainMenuLayout>
  );
};

export default HomeScreen;
