import propTypes from 'prop-types';
import { SWRConfig } from 'swr';
import fetcher from './fetcher';

/**
 * @param {React.ReactNode} children The app which will use the global SWR config
 * @returns
 */
const SWR = ({ children }) => {
  /**
   * @param {any} error
   */
  function shouldRetryOnError(error) {
    const statusCode = error?.response.status;

    if (statusCode >= 400 && statusCode < 500) {
      return false;
    }

    return true;
  }

  return <SWRConfig value={{ fetcher, shouldRetryOnError }}>{children}</SWRConfig>;
};

SWR.propTypes = {
  children: propTypes.node,
};

export default SWR;
