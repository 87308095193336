import PropTypes from 'prop-types';
import { useRoles } from '@hooks';

/**
 * Component to conditionally render its children based on user roles.
 * @param {object} props - Component props.
 * @param {ReactNode} props.children - The children components to render.
 * @param {string|array} props.includeRoles - The roles to include for rendering.
 * @param {string|array} props.excludeRoles - The roles to exclude for rendering.
 * @returns {ReactNode|null} - Rendered children or null if conditions are not met.
 */
function RenderByRole({ children, includeRoles, excludeRoles }) {
  const { validateRoles } = useRoles();

  if (validateRoles(includeRoles, excludeRoles)) {
    return children ? children : null;
  }

  return null;
}

RenderByRole.propTypes = {
  children: PropTypes.node,
  includeRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  excludeRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default RenderByRole;
