const styles = {
  filterDrawerStack: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    display: 'flex',
  },
  tabGrid: { display: 'flex', flexDirection: 'column', gap: '1rem' },
  containerRowFilter: (responsive) => ({
    flexDirection: responsive ? 'column' : 'row',
    gap: '0.5rem',
    display: 'flex',
  }),
  containerInputs: {
    width: '100%',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '0.5rem',
    padding: '1.5rem 1rem',
    borderRadius: '1rem',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  },
  actionsContainer: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    height: '49px',
    justifyContent: responsive && 'flex-end',
    alignItems: 'center',
  }),
  clearButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      fontSize: '0.75rem',
      padding: '1rem 0.5rem',
    },
  },
  filterButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      minWidth: '46px',
      '& > .MuiButton-startIcon': {
        margin: '0',
      },
      padding: '1rem 0.5rem',
    },
  },
};

export default styles;
