import { gray } from 'theme/colors';

const styles = {
  menuList: { gap: '0.25rem', display: 'flex', flexDirection: 'column' },
  select: ({ typography }) => ({
    ...typography.h4,
    color: gray[90],
    padding: '0.75rem 1rem',
    minHeight: 'initial',
  }),
  placeholderColor: ({ isFocused }) => (isFocused ? gray[90] : gray[70]),
  menuItem: { padding: 0 },
};

export default styles;
