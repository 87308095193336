import { useSelectedCompanyCustomAndGenericCategories, useSelectedCompanyProducts } from '@hooks';
import i18n from '@i18n';
import { Yup } from '@validations';

/**
 * Generates an array of column configurations for a table.
 * @param {object} data - Data used for populating select fields.
 * @param {boolean} showCompanyColumn - Should show company column
 * @param {boolean} showSegmentColumn - Should show segment column
 * @returns {Array} Array of column configurations.
 */
export const COLUMNS = (showCompanyColumn, showSegmentColumn) => {
  const aux = [
    { title: 'PROSPECTS:QUICK_CREATION_TABLE:NAME', content: ({ Nombre }) => Nombre },
    { title: 'PROSPECTS:QUICK_CREATION_TABLE:LASTNAME', content: ({ Apellido }) => Apellido },
    { title: 'PROSPECTS:QUICK_CREATION_TABLE:CUIL', content: ({ CUIL }) => CUIL },
    { title: 'PROSPECTS:QUICK_CREATION_TABLE:EMAIL', content: ({ Mail }) => Mail },
    {
      title: 'PROSPECTS:QUICK_CREATION_TABLE:PRODUCT',
      content: ({ Producto }) => Producto,
    },
    {
      title: 'PROSPECTS:QUICK_CREATION_TABLE:CATEGORY',
      content: ({ Rubro }) => Rubro.map((category) => i18n.t(`CATEGORIES:${category}`)).join(', '),
    },
  ];
  showSegmentColumn &&
    aux.push({
      title: 'COMMON:SEGMENT',
      content: ({ segment }) => segment.name,
    });
  showCompanyColumn &&
    aux.push({
      title: 'COMMON:COMPANY',
      content: ({ company }) => company.name,
    });
  return aux;
};

/**
 * Columns that will show tooltip
 */
export const COLUMNS_WITH_TOOLTIP = [
  'Email',
  'Nombre',
  'Apellido',
  'CUIL',
  'Producto',
  'Rubro',
  'Segmento',
  'Razón social',
];
/**
 * Generates an array of field configurations based on the provided data.
 * @param {Array} data - The data used to validate uniqueness for certain fields.
 * @returns {Array} - An array of field configurations.
 */
export const FIELDS_CONFIG = (data = []) => [
  {
    name: 'Nombre',
    label: 'NAME_LABEL',
    type: 'input',
    validation: Yup.string()
      .nameAndLastName('ERRORS:INVALID_NAME')
      .required('ERRORS:FIELD_REQUIRED'),
    defaultValue: '',
  },
  {
    name: 'Apellido',
    label: 'LASTNAME_LABEL',
    type: 'input',
    validation: Yup.string()
      .nameAndLastName('ERRORS:INVALID_LASTNAME')
      .required('ERRORS:FIELD_REQUIRED'),
    defaultValue: '',
  },
  {
    name: 'CUIL',
    label: 'CUIL_LABEL',
    type: 'input',
    validation: Yup.string()
      .matches(/^(?!0*$)\d+$/, 'ERRORS:INVALID_CUIL')
      .min(9, 'ERRORS:INVALID_CUIL')
      .max(12, 'ERRORS:INVALID_CUIL')
      .required('ERRORS:FIELD_REQUIRED')
      .test('is-unique', 'ERRORS:CUIL_EXIST', (value) => {
        const existingEmails = data.map((obj) => obj.CUIL);
        return !existingEmails.includes(value);
      }),
    defaultValue: '',
  },
  {
    name: 'Mail',
    label: 'EMAIL_LABEL',
    type: 'input',
    validation: Yup.string()
      .required('ERRORS:FIELD_REQUIRED')
      .email('ERRORS:INVALID_EMAIL')
      .test('is-unique', 'ERRORS:EMAIL_EXIST', (value) => {
        const existingEmails = data.map((obj) => obj.Mail);
        return !existingEmails.includes(value);
      }),
    defaultValue: '',
  },
  {
    name: 'Producto',
    label: 'PRODUCT_LABEL',
    type: 'select',
    validation: Yup.string().required('ERRORS:FIELD_REQUIRED'),
    defaultValue: '',
    getOptions: useSelectedCompanyProducts,
    parseOption: (value) => ({ text: value, value }),
  },
  {
    name: 'Rubro',
    label: 'CATEGORY_LABEL',
    type: 'select',
    validation: Yup.array().min(1, 'ERRORS:FIELD_REQUIRED'),
    defaultValue: [],
    multiple: true,
    getOptions: useSelectedCompanyCustomAndGenericCategories,
    parseOption: (name) => ({ text: `CATEGORIES:${name}`, value: name }),
  },
];

export const INITIAL_VALUES = FIELDS_CONFIG().reduce((fields, { name, defaultValue }) => {
  fields[name] = defaultValue;
  return fields;
}, {});
