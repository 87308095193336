import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { Button } from '@atoms';
import { GO_BACK_TEXT } from './constants';
import styles from './styles';

/**
 * GoBackButton component.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.text - The text content of the button.
 * @param {()=>void} props.backFunction - function to trigger when clicking back button
 * @param {String} props.variant - the type of the button
 * @returns {React.ReactNode} The GoBackButton component.
 */
const GoBackButton = (props) => {
  const navigate = useNavigate();

  /**
   * Default goBack function
   */
  function goBack() {
    navigate(-1);
  }

  const { backFunction = goBack, text = GO_BACK_TEXT, variant = 'text' } = props;

  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <Button
        onClick={backFunction}
        customStyle={{ text: styles.button }}
        color='primary'
        startIcon={<IconArrowNarrowLeft />}
        variant={variant}
        content={
          <>
            <Typography variant='h4'>{t(text)}</Typography>
          </>
        }
      />
    </Box>
  );
};

GoBackButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.node,
  backFunction: PropTypes.func,
  variant: PropTypes.string,
};

export default GoBackButton;
