import * as Yup from 'yup';
import { FIELDS_CONFIG } from './constants';

/**
 * Generates a Yup validation schema based on the provided data.
 * @param {Object} data - The data used to generate the validation schema.
 * @returns {Yup.ObjectSchema} The Yup validation schema.
 */
const validationSchema = (data) =>
  Yup.object().shape(
    FIELDS_CONFIG(data).reduce((accumulator, { name, validation }) => {
      accumulator[name] = validation;
      return accumulator;
    }, {})
  );

export default validationSchema;
