const styles = {
  spinnerBox: { display: 'flex', width: '100%', justifyContent: 'center', py: '10rem' },
  mainBox: { display: 'flex', flexDirection: 'column', gap: 'inherit' },
  buttonsBox: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
};

export default styles;
