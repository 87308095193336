import { useState } from 'react';
import { apiClient } from 'config/api';
import { PERMISSIONS } from '@globalConstants';
import { usePermissions } from '@hooks';

export interface ChangeRetrieveBalanceBody {
  amount: number;
  fullRecovery: boolean;
  userAccountNumber: string;
}

/** Retrive balance logic */
const useRetriveBalance = () => {
  const isAllowedToRetrieveBalance = usePermissions([PERMISSIONS.recoveryBalance]);

  const [retrieveBalance, setRetrieveBalance] = useState({
    updatedBalance: false,
    loading: false,
    error: false,
  });

  /**
   * Retrieves the balance
   * @param body - Contains
   * @param body.amount - The amount of balance to change.
   * @param body.fullRecovery - Indicates whether a full recovery should be performed.
   * @param body.userAccountNumber - User's account number.
   */
  async function postRetrieveBalance(body: ChangeRetrieveBalanceBody) {
    setRetrieveBalance((current) => ({
      ...current,
      updatedBalance: false,
      error: false,
      loading: true,
    }));
    try {
      await apiClient.post('/client/retrieve-balance', {
        ...body,
      });

      setRetrieveBalance((current) => ({ ...current, updatedBalance: true }));
    } catch (error) {
      setRetrieveBalance((current) => ({ ...current, error: true }));
    } finally {
      setRetrieveBalance((current) => ({ ...current, loading: false }));
    }
  }

  return { retrieveBalance, postRetrieveBalance, isAllowedToRetrieveBalance };
};

export default useRetriveBalance;
