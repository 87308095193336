import { useAuth0 } from '@auth0/auth0-react';

/**
 * Gets the current user with its corresponding role, and auth props
 * @returns The user object and auth props
 */
function useUser() {
  const { user, ...rest } = useAuth0();

  if (user) {
    const namespace = process.env.REACT_APP_NAMESPACE_AUTH0;

    const roles = user[`${namespace}/roles`];
    const metadata = user[`${namespace}/app_metadata`];
    const permissions = user[`${namespace}/permissions`];

    user.isInternal = permissions?.includes('view_all_companies');
    user.roles = roles;
    user.permissions = permissions;
    user.metadata = metadata;
  }

  return { user, ...rest };
}

export default useUser;
