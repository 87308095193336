import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, CircularProgress } from '@mui/material';
import { Alert, Button, Input } from '@atoms';
import { BoxContainer } from '@layouts';
import { useCompanyStore } from '@stores';
import styles from './styles';
import useSegmentCreation from './useSegmentCreations';

/** Use form value state */
const useTextState = () => {
  const [value, setValue] = useState('');
  /**
   * Updates the value of the text input.
   */
  const onChange = (newValue: string) => {
    setValue(newValue);
  };
  /**
   * Resets the value of the text input to an empty string.
   */
  const resetInput = () => {
    setValue('');
  };

  return { value, onChange, resetInput };
};

/** Render add segment component */
const AddSegment = () => {
  const { t } = useTranslation();
  const { value, onChange, resetInput } = useTextState();
  const { selectedCompany } = useCompanyStore();
  const { createSegment, isLoading, alertMessage, isError, isSuccess, setIsError, setIsSuccess } =
    useSegmentCreation();

  /** Handle submit */
  const handleSubmit = () => {
    createSegment(selectedCompany, value.trim());
    resetInput();
  };
  return (
    <BoxContainer>
      <Typography variant='h4'>{t('COMMON:ADD_SEGMENT')}</Typography>
      {isLoading ? (
        <Box sx={styles.spinnerBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={styles.mainBox}>
            <Input
              placeholder={t('COMMON:SEGMENT')}
              value={value}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
            />
            <Button
              customStyle={{ box: styles.buttonsBox }}
              text={'COMMON:CONFIRM'}
              variant='contained'
              onClick={handleSubmit}
              disabled={!(selectedCompany && value.trim())}
            />
          </Box>
        </>
      )}
      <Alert
        modalVisible={isError || isSuccess}
        handleClose={() => {
          setIsError(false);
          setIsSuccess(false);
        }}
        {...alertMessage}
      />
    </BoxContainer>
  );
};

export default AddSegment;
