import { useMemo } from 'react';
import { useSearchParams, type URLSearchParamsInit } from 'react-router-dom';
import { paramsToObject } from '@utils'; // assuming you have the utility functions

/**
 * Custom hook to handle URL params.
 *
 * @returns - An object containing the current params and a function to update them.
 */
function useURLparams(defaultValue?: URLSearchParamsInit) {
  const [searchParams, setSearchParams] = useSearchParams(defaultValue);
  const params = useMemo(() => paramsToObject(searchParams), [searchParams]);

  return {
    params,
    setURLparams: setSearchParams,
  };
}

export default useURLparams;
