const styles = {
  buttonBox: {
    position: 'relative',
    alignSelf: 'flex-start',
  },
  spinner: {
    color: 'inherit',
  },
  spinnerSize: (buttonSize) => {
    switch (buttonSize) {
      case 'small':
        return 16;
      case 'large':
        return 24;
      default:
        return 20;
    }
  },
};

export default styles;
