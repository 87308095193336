interface HelpLoginConstants {
  title: string;
  intro: string;
  links: Array<{
    text: string;
    link: string;
    textLink: string;
  }>;
  steps: string[];
}
const helpLoginConstants: HelpLoginConstants = {
  title: 'FAQ:HELP_DIALOG:TITLE',
  intro: 'FAQ:HELP_DIALOG:INTRO',
  links: [
    {
      text: 'FAQ:HELP_DIALOG:LINKS:GOOGLE_PLAY:TEXT',
      link: 'FAQ:HELP_DIALOG:LINKS:GOOGLE_PLAY:LINK',
      textLink: 'FAQ:HELP_DIALOG:LINKS:GOOGLE_PLAY:TEXTLINK',
    },
    {
      text: 'FAQ:HELP_DIALOG:LINKS:APP_STORE:TEXT',
      link: 'FAQ:HELP_DIALOG:LINKS:APP_STORE:LINK',
      textLink: 'FAQ:HELP_DIALOG:LINKS:APP_STORE:TEXTLINK',
    },
  ],
  steps: [
    'FAQ:HELP_DIALOG:STEPS:MAIL',
    'FAQ:HELP_DIALOG:STEPS:QR',
    'FAQ:HELP_DIALOG:STEPS:CAMERA',
    'FAQ:HELP_DIALOG:STEPS:SCAN',
    'FAQ:HELP_DIALOG:STEPS:DIGITS',
    'FAQ:HELP_DIALOG:STEPS:PASSWORD',
  ],
};
export default helpLoginConstants;
