import useSWR from 'swr';

export type CardProvider = 'PRISMA' | 'GP';

export interface CustomerProduct {
  id: string;
  provider: CardProvider;
  name: string;
  displayName: string;
  cardType: string;
}

/**
 * Obtains a list of Products
 */
const useCustomerProduct = (allowRequest = true) => {
  const { data, isLoading, error } = useSWR<CustomerProduct[]>(
    () => (allowRequest ? '/partner/products' : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error };
};

export default useCustomerProduct;
