import { useState } from 'react';
import { IconEyeDown } from '@tabler/icons-react';
import { IconButton } from '@atoms';

interface DownloadConsumptionButtonProps {
  disabled?: boolean;
  onDownload: () => Promise<void> | void;
  tooltip?: string;
}

/**
 * DownloadConsumptionButton
 * @description A React component that handles the download process for consumption data
 * @param {DownloadConsumptionButtonProps} props - The props for the DownloadConsumptionButton component
 * @param {boolean} props.disabled - Boolean to indicate if the download button should be disabled
 * @param {() => Promise<void>} props.onDownload - Function to call when the download button is clicked
 * @param {string} props.tooltip - Text to display in the tooltip
 * @returns {JSX.Element} - The rendered component
 */
const DownloadConsumptionButton = ({
  disabled,
  onDownload,
  tooltip,
}: DownloadConsumptionButtonProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * handleDownload
   * @description Handles the download process by calling the download function and managing the loading state
   * @returns {Promise<void>} - A promise that resolves when the download is complete
   */
  const handleDownload = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await onDownload();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IconButton
      IconComponent={IconEyeDown}
      disabled={disabled}
      onClick={handleDownload}
      tooltip={tooltip}
      isLoading={isLoading}
    />
  );
};

export default DownloadConsumptionButton;
