const styles = {
  button: ({ fullWidth, error }) => ({
    width: fullWidth ? '100%' : 'auto',
    textWrap: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    gap: '0.5rem',
    padding: '0.75rem 1rem',
    borderRadius: '0.5rem',
    border: '1px solid',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderColor: ({ palette }) => (error ? palette.error.main : palette.gray[50]),
    '&:hover': {
      borderColor: ({ palette }) => palette.primary.main,
    },
    position: 'relative',
  }),
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
  },
  inputLabelCustom: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '-10px',
    left: '6px',
    padding: '0 0.375rem',
    fontSize: '0.875rem',
  },
  helperText: (error) => ({
    color: ({ palette }) => (error ? palette.error.main : palette.gray[90]),
    fontSize: '0.75rem',
    marginTop: '0.188rem',
    marginX: '0.9rem',
  }),
};

export default styles;
