import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Box, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { IconSearch } from '@tabler/icons-react';
import { Button, DateTimePicker, Select } from '@atoms';
import { isoStringToDate } from '@utils';
import { DATE_PICKERS, DATE_PICKER_PROPS, FILTER_FIELDS, validationSchema } from './constants';
import styles from './styles';

/**
 * Displays filters
 * @param {object} props
 * @param {object} props.params
 */
const SearchBoxClientsMoves = ({ isLoading, params, onSubmit }) => {
  const { t } = useTranslation();
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const now = new Date();
  const monthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

  const fromDate = params?.fromDate ? isoStringToDate(params?.fromDate) : monthAgo;
  const toDate = params?.toDate ? isoStringToDate(params?.toDate) : now;

  const initialValues = {
    fromDate,
    toDate,
    customerTransactionType: params?.customerTransactionType || '',
  };

  const { handleChange, setFieldValue, handleSubmit, isValid, errors, values, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  /**
   * Clears the filters
   */
  function handleClearFilters() {
    resetForm();
  }

  /**
   * change date field
   */
  function handleDateChange(fieldName, date) {
    setFieldValue(fieldName, date);
  }

  const disableButton = isLoading || !isValid;

  return (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.header}>
        <Typography variant='h4'>{t('MOVEMENTS:FILTERS_TITLE')}</Typography>
      </Box>
      <Box style={styles.content(responsive)}>
        <Box style={styles.firstLine(responsive)}>
          <Box style={styles.inputsContainer(responsive)}>
            {DATE_PICKERS.map(({ name, label }) => (
              <DateTimePicker
                key={name}
                name={name}
                value={values[name]}
                handleChange={(date) => handleDateChange(name, date)}
                error={errors[name]}
                inputLabel={label}
                {...DATE_PICKER_PROPS}
              />
            ))}
            {FILTER_FIELDS.map(({ name, label, items, parseOption }) => (
              <Select
                key={name}
                onChange={handleChange(name)}
                value={values[name]}
                error={errors[name]}
                {...{ name, label, items, parseOption }}
              />
            ))}
          </Box>
        </Box>
        <Box style={styles.actionsContainer(responsive)}>
          <Button
            customStyle={styles.clearButton}
            text={t('COMMON:CLEAR_FILTER')}
            color='primary'
            variant='link'
            onClick={handleClearFilters}
          />
          <Button
            customStyle={styles.searchButton}
            startIcon={
              <SvgIcon>
                <IconSearch />
              </SvgIcon>
            }
            variant='contained'
            color='primary'
            type='submit'
            disabled={disableButton}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

SearchBoxClientsMoves.propTypes = {
  filtersOpen: PropTypes.bool,
  setFiltersOpen: PropTypes.func,
  isLoading: PropTypes.bool,
  params: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SearchBoxClientsMoves;
