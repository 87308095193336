import { PropTypes } from 'prop-types';
import { Typography } from '@mui/material';

/**
 * Strong text shown in modal
 * @param {node} children
 */
const StrongModalText = ({ children }) => (
  <Typography variant='body' component='span' fontWeight={600}>
    {children}
  </Typography>
);

StrongModalText.propTypes = {
  children: PropTypes.node,
};

export default StrongModalText;
