import useSWR from 'swr';

/**
 * Obtains a list of categories by company
 */
const useCategoriesByCompany = ({ allowRequest = true, companyId }) => {
  const { data, isLoading, error } = useSWR(
    () => (allowRequest && companyId ? `/partner/categories/${companyId}` : false),
    {
      revalidateOnFocus: false,
    }
  );
  return { data: data || [], isLoading, error };
};
export default useCategoriesByCompany;
