import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { es } from 'date-fns/locale';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useErrorSnackbar } from '@hooks';
import { GlobalErrorAlert } from '@molecules';
import { options } from '@utils';
import { addErrorInterceptor, removeErrorInterceptor } from '@api/interceptors';
import AuthProvider from '@config/auth';
import SWR from '@config/swr';
import '@config/i18n';
import { AppRouter } from './navigation';
import { theme } from './theme';
import './App.css';

/**
 * Main component for the app.
 * @returns
 */
function App() {
  const { showErrorSnackbar, ErrorSnackbar } = useErrorSnackbar();
  useEffect(() => {
    addErrorInterceptor(showErrorSnackbar);

    return () => {
      removeErrorInterceptor();
    };
  }, [showErrorSnackbar]);

  return (
    <SWR>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <ToastContainer {...options} />
            <AppRouter />
            <ErrorSnackbar />
            <GlobalErrorAlert />
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </SWR>
  );
}
export default App;
