import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Client } from 'helpers/hooks/useEmployeeDetails';
import { KeyedMutator } from 'swr';
import { Alert, Skeleton, Snackbar, Stack, Typography } from '@mui/material';
import { Snackbar as CustomSnackbar } from '@atoms';
import { useModal, useSnackbar } from '@hooks';
import { SNACKBAR_DURATION } from './constants';
import ProductCard from './ProductCard';
import RetrieveBalanceModal from './RetrieveBalanceModal';
import styles from './styles';
import { Product } from './useProducts';
import useRetriveBalance, { ChangeRetrieveBalanceBody } from './useRetriveBalance';

interface ProductsTabProps {
  client: Client;
  product: Product;
  isProductsLoading: boolean;
  productsMutate: KeyedMutator<Product[]>;
}

/** Component of all user products with their account  */
const ProductsTab = ({ client, product, isProductsLoading, productsMutate }: ProductsTabProps) => {
  const { t } = useTranslation();

  const { snackbarProps, showSnackbar } = useSnackbar();

  const {
    closeModal: closeRetrieveBalanceModal,
    data: productSelectedToRetrive,
    open: isRetrieveBalanceModalOpen,
    openModal: openRetrieveBalanceModal,
  } = useModal<Product>();

  const { retrieveBalance, postRetrieveBalance, isAllowedToRetrieveBalance } = useRetriveBalance();
  const { error: errorRetrieve, loading: loadingRetrieve, updatedBalance } = retrieveBalance;

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  /** Handle calling of post retrieve balance  */
  const handlePostRetrieveBalance = (body: ChangeRetrieveBalanceBody) =>
    postRetrieveBalance(body).then(() => {
      productsMutate();
    });

  useEffect(() => {
    if (updatedBalance && !errorRetrieve) {
      closeRetrieveBalanceModal();
      setShowSuccessAlert(true);
    }

    if (errorRetrieve) {
      closeRetrieveBalanceModal();
      setShowFailAlert(true);
    }
  }, [updatedBalance, errorRetrieve, closeRetrieveBalanceModal]);

  return (
    <>
      <Stack spacing={3}>
        {isProductsLoading ? (
          <Skeleton sx={styles.productSkeleton} variant='rectangular' />
        ) : (
          <ProductCard
            isAllowedToRetrieveBalance={isAllowedToRetrieveBalance}
            key={product.account_number}
            product={product}
            productsMutate={productsMutate}
            onPressRetrieveBalance={openRetrieveBalanceModal}
            showSnackbar={showSnackbar}
            client={client}
          />
        )}
      </Stack>
      {productSelectedToRetrive && (
        <RetrieveBalanceModal
          product={productSelectedToRetrive}
          postRetrieveBalance={handlePostRetrieveBalance}
          isOpen={isRetrieveBalanceModalOpen}
          onClose={closeRetrieveBalanceModal}
          isLoading={loadingRetrieve}
        />
      )}
      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={() => setShowSuccessAlert(false)}
      >
        <Alert variant='filled' severity='success' onClose={() => setShowSuccessAlert(false)}>
          {t('EMPLOYEES:DETAIL:RETRIEVE_BALANCE_SUCCESS')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showFailAlert}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={() => setShowFailAlert(false)}
      >
        <Alert variant='filled' severity='error' onClose={() => setShowFailAlert(false)}>
          <Typography variant='body2'>
            {t('EMPLOYEES:DETAIL:ERRORS:RETRIEVE_BALANCE_TITTLE')}
          </Typography>
          <Typography variant='body2'>
            {t('EMPLOYEES:DETAIL:ERRORS:RETRIEVE_BALANCE_CONTENT')}
          </Typography>
        </Alert>
      </Snackbar>
      <CustomSnackbar {...snackbarProps} />
    </>
  );
};

export default ProductsTab;
