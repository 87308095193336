import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, useFormik } from 'formik';
import { Box, Grid } from '@mui/material';
import { IconFileExport, IconSearch, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Button, DateTimePicker, IconButton, Input } from '@atoms';
import { useDownloadReceiptByFilters } from '@hooks';
import { BoxContainer } from '@layouts';
import { FIELDS_CONFIG, INITIAL_VALUES, validationSchema } from './constants';
import styles from './styles';
import { Consumption, ConsumptionKeys, ConsumptionParams } from './types';

interface FilterCardProps {
  params: ConsumptionParams;
  dataLength: number;
  onSubmit: (values: Record<string, string>) => Promise<void>;
}

/**
 * FilterCard component
 * This component is responsible for rendering a filter card
 * with various filter options such as price range, category, rating, etc.
 * It should allow users to apply filters and update the product list accordingly.
 * @param {FilterCardProps} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const FilterCard = ({ params, dataLength, onSubmit }: FilterCardProps): JSX.Element => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const { values, setFieldValue, handleChange, handleSubmit, resetForm, errors } =
    useFormik<ConsumptionParams>({
      initialValues: { ...INITIAL_VALUES, ...params },
      validationSchema,
      onSubmit,
    });
  const { isLoading, downloadReceipt } = useDownloadReceiptByFilters();

  /**
   * Toggle the expansion of the filter card
   * @returns void
   */
  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  /**
   * Handle the reset and submit actions
   */
  const handleResetAndSubmit = () => {
    resetForm();
    handleSubmit();
  };

  /**
   * Handle the submit and download actions
   */
  const handleSubmitAndDownload = async () => {
    await handleSubmit();
    downloadReceipt(params);
  };

  return (
    <BoxContainer>
      <Box sx={styles.container}>
        <Grid container spacing={2}>
          {FIELDS_CONFIG.map(({ name, type, label, minDate, isCollapsible }) => {
            //TODO: Cast the typing to avoid build errors.
            const value = (values as Consumption)[name as ConsumptionKeys];
            const error = (errors as FormikErrors<ConsumptionParams>)[
              name as keyof ConsumptionParams
            ];
            const helperText = error ? error : undefined;
            return (
              <Grid item xs={6} md={3} key={name}>
                {type === 'datePicker' ? (
                  <DateTimePicker
                    name={name}
                    value={value}
                    error={Boolean(error)}
                    helperText={helperText}
                    handleChange={(date: string) => setFieldValue(name, date)}
                    placeholder={t(label)}
                    disablePast={false}
                    calendarViews={['year', 'month', 'day']}
                    dateOnly={true}
                    fullWidth
                    inputLabel={t(label)}
                    minDate={minDate}
                  />
                ) : (
                  (!isCollapsible || isExpanded) && (
                    <Input
                      name={name}
                      label={t(label)}
                      value={value}
                      error={Boolean(error)}
                      helperText={helperText}
                      placeholder={t(label)}
                      onChange={handleChange}
                    />
                  )
                )}
              </Grid>
            );
          })}
        </Grid>
        <Box sx={styles.buttonsBox}>
          <Button
            aria-label={t('COMMON:CLEAR_FILTER')}
            text={'COMMON:CLEAR_FILTER'}
            color='primary'
            variant='link'
            onClick={handleResetAndSubmit}
            customStyle={styles.clearFilter}
          />
          <IconButton
            aria-label={t(isExpanded ? 'COMMON:COLLAPSE_FILTERS' : 'COMMON:EXPAND_FILTERS')}
            tooltip={isExpanded ? 'COMMON:COLLAPSE_FILTERS' : 'COMMON:EXPAND_FILTERS'}
            onClick={handleToggle}
            IconComponent={isExpanded ? IconChevronUp : IconChevronDown}
          />
          <IconButton
            aria-label={t('CONSUMPTION:DOWNLOAD')}
            tooltip='CONSUMPTION:DOWNLOAD'
            onClick={handleSubmitAndDownload}
            IconComponent={IconFileExport}
            isLoading={isLoading}
            disabled={!dataLength}
          />
          <IconButton
            aria-label={t('COMMON:SEARCH')}
            tooltip='COMMON:SEARCH'
            onClick={handleSubmit}
            IconComponent={IconSearch}
            variant='contained'
          />
        </Box>
      </Box>
    </BoxContainer>
  );
};

export default FilterCard;
