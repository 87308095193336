import useSWR from 'swr';
import { useCustomerStore } from '@stores';

/**
 * Obtains a list of products by customer_id
 */
const useProductsByCompany = (allowRequest = true) => {
  const { selectedCustomer } = useCustomerStore();
  const { data, isLoading, error } = useSWR(
    () => (allowRequest ? `/partner/products/customer/${selectedCustomer}` : false),
    {
      revalidateOnFocus: false,
    }
  );

  return { data, isLoading, error };
};

export default useProductsByCompany;
