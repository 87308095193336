import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { palette } from 'theme/palette';
import { Box, CircularProgress, Typography } from '@mui/material';
import { IconBuildingStore, IconEye, IconEyeClosed } from '@tabler/icons-react';
import { OPERATIONS } from '@globalConstants';
import { useCompanyDetails, useRoles, useURLparams } from '@hooks';
import { FullWidthContent, BoxContainer, MainMenuLayout } from '@layouts';
import { CardHeader, GoBackButton } from '@molecules';
import { ClientMovementsTable, SelectCompany } from '@organisms';
import { useCompanyStore } from '@stores';
import { parseAmount } from '@utils';
import CompanyDetails from './CompanyDetails';
import styles from './styles';

/**
 * Clients (partners) screen for internal users
 */
const ClientsScreen = () => {
  const { t } = useTranslation();
  const { validateRoles } = useRoles();
  const isOperationRole = validateRoles([OPERATIONS]);

  const navigate = useNavigate();
  const { params, setURLparams } = useURLparams();
  const { setSelectedCompany } = useCompanyStore();
  const { data, isLoading } = useCompanyDetails(params.company);

  const [showBalance, setShowBalance] = useState(false);

  const clientMenu = [
    {
      text: t('EMPLOYEES:DETAIL:VIEW_DETAIL'),
      canViewOption: params.tab !== 'details',
      onClick: () => {
        setURLparams({ ...params, tab: 'details' });
      },
    },
    {
      text: t('COMMON:SEE_REPORTS'),
      canViewOption: params.tab !== 'details',
      onClick: () => {
        navigate('/reportes-operaciones', { state: { goBack: -1 } });
      },
    },
  ];

  /** */
  const onChangeBalance = () => {
    setShowBalance(!showBalance);
  };

  /**
   * Changes the company
   * @param {object} e
   * @param {object} newValue
   */
  function handelChangeCompany(e, newValue) {
    setURLparams({ ...params, company: newValue.id });
    setSelectedCompany(newValue.id);
  }
  const balance = data?.balance ? parseAmount(data.balance) : '-';
  const leftComponentOptions = {
    type: 'avatar',
    icon: <IconBuildingStore color={palette.primary.main} />,
  };
  const rightComponentOptions = {
    type: 'text',
    text: !showBalance ? balance : '$ *****',
    icon: !showBalance ? <IconEye /> : <IconEyeClosed />,
    onClickIcon: onChangeBalance,
    menuOptions: clientMenu,
  };

  const subtitleOptions = {
    text: data?.cuil,
  };

  /** Render content depending of "tab" selected */
  const renderContent = (tabSelected) => {
    switch (tabSelected) {
      case 'details': {
        return (
          <>
            <CompanyDetails isOperationRole={isOperationRole} />
            <GoBackButton variant='outlined' />
          </>
        );
      }
      default: {
        return (
          <>
            <ClientMovementsTable client={data} />
            <GoBackButton backFunction={() => setURLparams({})} variant='outlined' />
          </>
        );
      }
    }
  };

  return (
    <MainMenuLayout>
      <FullWidthContent>
        {params.company ? (
          isLoading ? (
            <Box sx={styles.spinner}>
              <CircularProgress />
            </Box>
          ) : (
            data && (
              <>
                <CardHeader
                  withMenu={isOperationRole && params.tab !== 'details'}
                  title={data?.description}
                  leftComponentOptions={leftComponentOptions}
                  rightComponentOptions={rightComponentOptions}
                  subtitleOptions={subtitleOptions}
                />
                {renderContent(params.tab)}
              </>
            )
          )
        ) : (
          <>
            <BoxContainer>
              <Typography variant='h4'>{t('COMMON:COMPANY')}</Typography>
              <SelectCompany onChange={handelChangeCompany} value={params.company || ''} />
            </BoxContainer>
          </>
        )}
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default ClientsScreen;
