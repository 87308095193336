const styles = {
  companiesMenuItems: { display: 'flex', justifyContent: 'space-between' },
  paperProps: {
    sx: {
      padding: '0.5rem',
      width: '20rem',
    },
  },
  menuList: { display: 'flex', flexDirection: 'column', gap: '0.5rem' },
};

export default styles;
