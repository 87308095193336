import useSWR from 'swr';
import { useCompanyStore } from '@stores';

/**
 * Gets the details of the company (razón social) selected by the user
 */
const useSelectedCompanyDetails = () => {
  const { selectedCompany } = useCompanyStore();

  /**
   * Generates the swr key for the request, or cancels it if the user has no company selected
   * @returns The key string or false
   */
  const key = () => (selectedCompany ? `/partner/company/${selectedCompany}` : false);

  const props = useSWR(key);

  return props;
};

export default useSelectedCompanyDetails;
