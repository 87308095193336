import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Button } from '@atoms';

/**
 * Renders a modal with confirm/reject buttons, using material ui Dialog component
 */
const ConfirmModal = ({
  title = '',
  description = '',
  modalVisible,
  setModalVisible,
  handleClose,
  handleSubmit,
  closeButtonText = 'COMMON:CANCEL',
  submitButtonText = 'COMMON:CONFIRM',
}) => {
  /**
   * Function to close modal by default when press hardware back or backdrop
   */
  const defaultCloseModal = () => setModalVisible((value) => !value);
  const { t } = useTranslation();

  return (
    <Dialog
      open={modalVisible}
      onClose={handleClose ?? defaultCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          onClick={handleClose ?? defaultCloseModal}
          text={t(closeButtonText)}
        />
        <Button onClick={handleSubmit} text={t(submitButtonText)} />
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  closeButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

export default ConfirmModal;
