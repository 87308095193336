import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { StrongModalText, Alert } from '@atoms';

/**
 * A modal component for confirming prospects.
 * @param {object} props - The props object.
 * @param {object} props.confirmProspects - Object containing confirmation details.
 * @param {boolean} props.confirmProspects.openModal - Flag indicating whether the modal is open or not.
 * @param {number} props.confirmProspects.total - Total number of prospects to confirm.
 * @param {function} props.handleConfirmation - Function to handle confirmation.
 * @param {boolean} props.isLoading - Flag indicating whether the component is in a loading state or not.
 * @param {boolean} props.disableButton - Flag indicating whether the confirmation button should be disabled or not.
 * @param {boolean} props.showSelectedSegmentInConfirmation - Flag indicating whether to show selected segment in confirmation message or not.
 * @param {string} props.companyName - Name of the company.
 * @param {string} props.segmentName - Name of the segment.
 * @returns {JSX.Element} A React component representing the ProspectsConfirmationModal.
 */
const ProspectsConfirmationModal = ({
  confirmProspects,
  handleConfirmation,
  isLoading,
  disableButton,
  showSelectedSegmentInConfirmation,
  companyName,
  segmentName,
}) => (
  <Alert
    variant='warning'
    modalVisible={confirmProspects.openModal}
    handleClose={() => handleConfirmation(false)}
    handleSubmit={() => handleConfirmation(true)}
    confirmButtonText='COMMON:ACCEPT'
    closeButtonText='COMMON:CANCEL'
    title={'PROSPECTS:MODAL_TITLE'}
    content={
      <Typography color='gray.90'>
        {showSelectedSegmentInConfirmation ? (
          <Trans count={confirmProspects.total} i18nKey='PROSPECTS:MODAL_MESSAGE'>
            Estás generando la pre-alta de
            <StrongModalText>{{ total: confirmProspects.total }}</StrongModalText>
            colaboradores del segmento
            <StrongModalText>{{ segment: segmentName }}</StrongModalText> y razón social
            <StrongModalText>{{ company: companyName }}</StrongModalText>.
          </Trans>
        ) : (
          <Trans count={confirmProspects.total} i18nKey='PROSPECTS:MODAL_MESSAGE_SIMPLE_CLIENTS'>
            Estás generando la pre-alta de
            <StrongModalText>{{ total: confirmProspects.total }}</StrongModalText>
            colaboradores de la razón social
            <StrongModalText>{{ company: companyName }}</StrongModalText>.
          </Trans>
        )}
      </Typography>
    }
    submitLoading={isLoading}
    disabled={disableButton}
  />
);

ProspectsConfirmationModal.propTypes = {
  confirmProspects: PropTypes.shape({
    openModal: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disableButton: PropTypes.bool.isRequired,
  showSelectedSegmentInConfirmation: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
  segmentName: PropTypes.string.isRequired,
};

export default ProspectsConfirmationModal;
