import { SystemStyleObject } from '@mui/system';
import { NubiTheme } from '@theme';

type CustomStyle =
  | SystemStyleObject<NubiTheme>
  | ((theme: NubiTheme) => SystemStyleObject<NubiTheme>);

interface TemplateLinkProspect {
  button: CustomStyle;
  box: CustomStyle;
}

const styles: TemplateLinkProspect = {
  button: {
    button: {
      background: 'transparent !important',
      color: ({ palette }) => palette.primary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer !important',
      },
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignSelf: 'flex-start',
  },
};

export default styles;
