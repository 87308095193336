import { useUser } from '@hooks';
/**
 * Verifies if a user's token contains the required permissions
 * @param {string[]} requiredPermissions - The array of permissions to check against.
 * @returns {boolean} - Returns true if all required permissions are present, otherwise false.
 */
function usePermissions(requiredPermissions) {
  const { user } = useUser();
  const canAccess = requiredPermissions.every((value) => user.permissions.includes(value));
  return canAccess;
}
export default usePermissions;
