import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link as MaterialLink, CircularProgress, Box } from '@mui/material';
import styles from './styles';

/**
 * @param {object} props
 * @param {string} [props.text] i18n alias
 * @param {string} [props.href] The URL to navigate to (for Link)
 * @param {boolean} [props.disabled = false] - Whether the link is disabled or not
 * @param {object} [props.customStyle = {}] - customStyle
 * @param {boolean} [props.isLoading = false] - isLoading
 * @param {string} [props.color = ''] - color
 * @param {React.ReactNode} [props.content = false] -content JSX to show as content instead of an i18n alias
 * @param {string} [props.download = ''] - download
 * @param {string} [props.target = ''] - The target attribute for the link
 * @returns {JSX.Element} Custom Link
 */
const Link = ({
  text,
  href,
  disabled = false,
  customStyle = {},
  isLoading = false,
  color,
  content = false,
  download,
  target,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...styles.box, ...customStyle.box }}>
      {isLoading && <CircularProgress size={16} sx={styles.spinner} />}
      <MaterialLink
        href={href}
        disabled={disabled}
        sx={{ ...styles.link, ...customStyle.link }}
        color={color}
        download={download}
        target={target}
        {...(target === '_blank' && { rel: 'noopener noreferrer' })}
      >
        <span style={customStyle.text}>{content ? content : t(text)}</span>
      </MaterialLink>
    </Box>
  );
};

Link.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  customStyle: PropTypes.shape({
    text: PropTypes.object,
    link: PropTypes.object,
    box: PropTypes.object,
  }),
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  content: PropTypes.node,
  download: PropTypes.string,
  target: PropTypes.string,
};

export default Link;
