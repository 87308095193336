import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useSelectedCompanyDetails, useUser } from '@hooks';
import { BoxContainer } from '@layouts';
import { SelectCompany } from '@organisms';

/**
 * SelectedCompany
 * @param {string} [disabled] property to disinhabit the Select company when isLoading is true
 * @param {'xs' | 'sm' | 'md' | 'lg'} [maxWidth] - The custom Dialog width ('xs', 'sm', 'md' o 'lg').
 * */
const SelectCompanyCard = ({ disabled = false, disableHeader = false }) => {
  const { t } = useTranslation();
  const { data } = useSelectedCompanyDetails();
  const { user } = useUser();
  return (
    <>
      {user.isInternal ? (
        <BoxContainer>
          <Typography variant='h4'>{t('COMMON:COMPANY')}</Typography>
          <SelectCompany disabled={disabled} />
        </BoxContainer>
      ) : (
        <>
          {disableHeader ? null : (
            <BoxContainer>
              <Typography variant='h4'>
                {data?.description
                  ? t('DISPERSIONS:HEADING', { name: data.description })
                  : t('COMMON:HELLO')}
              </Typography>
            </BoxContainer>
          )}
        </>
      )}
    </>
  );
};

SelectCompanyCard.propTypes = {
  disabled: PropTypes.boolean,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  disableHeader: PropTypes.boolean,
};

export default SelectCompanyCard;
