const styles = {
  linkContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2rem',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '1.5rem',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  listItem: (showBullet) => ({
    display: 'list-item',
    listStyleType: showBullet ? 'disc' : 'none',
    paddingLeft: 0,
    paddingBottom: '4px',
    paddingTop: '4px',
  }),
  customStyleLink: {
    box: {
      display: 'inline',
    },
  },
  subList: {
    padding: 0,
  },
  subListItem: {
    display: 'list-item',
    paddingBottom: '4px',
    paddingTop: '4px',
    paddingLeft: '0px',
  },
  subListText: {
    marginRight: '5px',
  },
  accordionDetail: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  boxContainer: {
    gap: 0.5,
  },
  accordion: {
    boxShadow: 'none',
  },
};

export default styles;
