import useSWR from 'swr';
import { useCompanyStore } from '@stores';

/**
 * Custom hook for fetching reports links for a specific segment
 * *  @returns {Object} - The response object from the SWR fetch.
 */
const useCompanyReports = () => {
  const { selectedCompany } = useCompanyStore();
  const props = useSWR(`/partner/reportsV2/${selectedCompany}`);
  return props;
};

export default useCompanyReports;
