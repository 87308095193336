const styles = {
  buttonBase: (active, isSubItem) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    padding: '0.75rem',
    gap: '0.5rem',
    width: isSubItem ? '13.5rem' : '14.5rem',
    borderRadius: '0.5rem',
    backgroundColor: ({ palette }) =>
      active && !isSubItem ? palette.primary.lightest : 'transparent',
    '&:hover': {
      backgroundColor: ({ palette }) =>
        active && isSubItem ? 'transparent' : !active && palette.primary.lightest,
      color: ({ palette }) => palette.primary.dark,
    },
    '::before': ({ palette }) => ({
      ...(active && isSubItem
        ? {
            content: '""',
            position: 'absolute',
            top: '1.3rem',
            left: '-0.2rem',
            height: '6px',
            width: '6px',
            background: palette.primary.main,
            borderRadius: '50%',
            zIndex: -1,
          }
        : {}),
    }),
  }),
  boxText: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chip: {
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: '10px',
    padding: '0',
    height: '14px',
    '> span': { px: '4px', py: '2px' },
    cursor: 'pointer',
  },
};

export default styles;
