import { useTranslation } from 'react-i18next';
import { addDays, format } from 'date-fns';
import {
  Alert,
  AlertTitle,
  FormControlLabel,
  Snackbar,
  Typography,
  InputAdornment,
  Box,
  Checkbox,
} from '@mui/material';
import { Button, DateTimePicker, Input } from '@atoms';
import { useSaveExchangeRates } from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { Table } from '@molecules';
import styles from './styles';

/**
 * Exchange screen
 */
const ExchangeRates = () => {
  const {
    exchangeRate,
    checked,
    scheduledDay,
    setScheduledDay,
    openAlert,
    saveExchangeRates,
    handleChange,
    handleChangeCalendar,
    isValidateSubmit,
    handleCloseAlert,
  } = useSaveExchangeRates();
  const today = new Date();
  const { t } = useTranslation();
  const isExchangeRateEmpty = Object.keys(exchangeRate).length === 0;

  const COLUMNS = [
    {
      title: t('EXCHANGE:TABLE_DATE_TITLE'),
      content: (e) => {
        const fecha = e.starts;
        const fechaObjeto = new Date(fecha);
        const fechaFormateada = format(fechaObjeto, 'dd-MM-yyyy');
        return <Box>{fechaFormateada.slice(0, 10)}</Box>;
      },
    },
    {
      title: t('EXCHANGE:TABLE_START_TITLE'),
      content: (e) => {
        const fecha = e.starts;
        const startTime = new Date(fecha);
        const difference = 3 * 60 * 60 * 1000;
        const localStartTime = new Date(startTime.getTime() - difference);
        const regex = /(?<time>\d{2}:\d{2})/;
        const timeRegex = regex.exec(localStartTime);
        return <Box>{timeRegex.groups.time}</Box>;
      },
    },
    {
      title: t('EXCHANGE:TABLE_EXCHANGE_TITLE'),
      content: (e) => {
        return <Box>{e.rate.toString()}</Box>;
      },
    },
  ];

  return (
    <MainMenuLayout>
      <FullWidthContent>
        <BoxContainer>
          <Typography variant='h4'>{t('EXCHANGE:TITLE')}</Typography>
          <Box style={styles.inputsContainer}>
            <Input
              id='dailyExchangeRate'
              label={t('EXCHANGE:INPUT_PLACEHOLDER')}
              variant='outlined'
              onChange={handleChange}
              type='number'
              onKeyPress={(event) => {
                if (event.key === '-' || event.key === '+') {
                  event.preventDefault();
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                inputMode: 'numeric',
                pattern: '[0-9]*',
                inputProps: {
                  min: 0,
                },
              }}
              fullWidth={false}
            />
            {checked && (
              <Box>
                <DateTimePicker
                  hideToolbar
                  value={scheduledDay}
                  handleChange={(newValue) => {
                    setScheduledDay(newValue);
                  }}
                  inputLabel={t('EXCHANGE:INPUT_PLACEHOLDER_SCHEDULE')}
                  minDateTime={addDays(today, 1)}
                  maxDate={addDays(today, 2)}
                  calendarViews={['day']}
                />
              </Box>
            )}
            <Button
              text={t('EXCHANGE:BUTTON_SAVE_TEXT')}
              variant='contained'
              color='primary'
              onClick={saveExchangeRates}
              disabled={isValidateSubmit()}
            />
          </Box>
          <Box style={styles.checkboxContainer}>
            <FormControlLabel
              label={t('EXCHANGE:SCHEDULED_SWITCH_MESSAGE')}
              control={<Checkbox checked={checked} onChange={handleChangeCalendar} />}
            />
            {checked && <Alert severity='info'>{t('EXCHANGE:SCHEDULED_MESSAGE')}</Alert>}
          </Box>
        </BoxContainer>
        <Typography variant='h4'>{t('EXCHANGE:SUBTITLE')}</Typography>
        <Table
          items={Object.values(exchangeRate)}
          columns={COLUMNS}
          isLoading={isExchangeRateEmpty}
        />
        {openAlert && openAlert.show && (
          <Snackbar
            open={true}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              severity={openAlert.status}
              sx={{ width: '100%' }}
              onClose={handleCloseAlert}
              variant='filled'
            >
              <AlertTitle>{openAlert.title}</AlertTitle>
              {openAlert.message}
            </Alert>
          </Snackbar>
        )}
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default ExchangeRates;
