import { formatDate, parseAmount } from '@utils';

/**
 *Add spaces to JSON string in order for the text to wrap correctly in small screens
 */
function parseAditionalData(string) {
  if (!string) return null;

  try {
    return JSON.stringify(JSON.parse(string), null, 1);
  } catch (error) {
    return string;
  }
}

export const ITEMS = [
  { label: 'MOVEMENTS:MOVEMENT_ID', getText: (movement) => movement.id },
  { label: 'MOVEMENTS:TRANSACTION_ID', getText: (movement) => movement.transactionId },
  { label: 'MOVEMENTS:ACCOUNT_NUMBER', getText: (movement) => movement.accountNumber },
  { label: 'MOVEMENTS:CONCEPT', getText: (movement) => movement.transactionType },
  { label: 'MOVEMENTS:TRACE_NUMBER', getText: (movement) => movement.traceNumber },
  { label: 'MOVEMENTS:USER_FROM', getText: (movement) => movement.userFrom },
  {
    label: 'MOVEMENTS:USER_FROM_ADDITIONAL_DATA',
    getText: (movement) => parseAditionalData(movement.userFromAdditionalData),
  },
  {
    label: 'MOVEMENTS:ACCOUNT_TO',
    getText: (movement) => movement.accountTo,
  },
  { label: 'MOVEMENTS:USER_TO', getText: (movement) => movement.userTo },
  {
    label: 'MOVEMENTS:USER_TO_ADDITIONAL_DATA',
    getText: (movement) => parseAditionalData(movement.userToAdditionalData),
  },
  { label: 'COMMON:CURRENCY', getText: (movement) => movement.currencyCode },
  { label: 'COMMON:AMOUNT', getText: (movement) => parseAmount(movement.amount) },
  { label: 'MOVEMENTS:RESULT_CODE', getText: (movement) => movement.resultCode },
  {
    label: 'COMMON:ADDITIONAL_DATA',
    getText: (movement) => parseAditionalData(movement.additionalData),
  },
  { label: 'COMMON:CREATED_AT', getText: (movement) => formatDate(movement.createdAt) },
  { label: 'MOVEMENTS:BUSINESS_DATE', getText: (movement) => movement.businessDate },
  { label: 'COMMON:STATUS', getText: (movement) => movement.transactionStatus },
  { label: 'MOVEMENTS:STATUS_DESCRIPTION', getText: (movement) => movement.statusDescription },
  { label: 'MOVEMENTS:CONCILIATION_STATUS', getText: (movement) => movement.conciliationStatus },
];
