import useSWR from 'swr';
import { useGlobalErrorStore } from '@stores';

/**
 * Get the account numbers from user
 * @param {SWRConfiguration} config Object containing swr config
 * @returns {array} The account numbers from user can operate with
 */
const useUserAccountNumbers = (userId) => {
  const { setError, setShowError } = useGlobalErrorStore();

  /**
   * If account numbers request fails
   */
  function onError() {
    setError({
      title: 'USERS:ERRORS:UNABLE_TO_FETCH_ACCOUNT_NUMBERS_TITLE',
      description: 'USERS:ERRORS:UNABLE_TO_FETCH_ACCOUNT_NUMBERS_DESCRIPTION',
    });

    setShowError(true);
  }

  const { data, ...props } = useSWR(`/users/${userId}/accounts/numbers`, {
    onError,
    revalidateOnFocus: false,
  });

  return { data: data?.accountNumbers, ...props };
};

export default useUserAccountNumbers;
