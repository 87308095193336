import { create } from 'zustand';

/**
 * Handles a global Segment selection
 * The value is expected to be the 'type' (numeric segment value)
 */
const useSegmentStore = create((set) => ({
  selectedSegment: null,
  setSelectedSegment: (segment) => set({ selectedSegment: segment }),
}));

export default useSegmentStore;
