import { TOP_NAV_HEIGHT } from '@globalConstants';

const styles = {
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: (theme) => theme.palette.primary.main,
    height: TOP_NAV_HEIGHT,
    width: '100%',
    maxWidth: '264px',
    zIndex: 1,
    position: 'fixed',
  },
  img: {
    width: '149px',
    height: '40px',
  },
  icon: { color: 'neutral.400', fontSize: '32px' },
  navContainer: {
    display: 'flex',
    py: '1.5rem',
    px: '1rem',
    mt: '5rem',
  },
  stackContainer: {
    listStyle: 'none',
    p: 0,
    m: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  subNavContainer: {
    display: 'flex',
    pl: '1rem',
  },
  subStackContainer: (isSubItem = false) => ({
    listStyle: 'none',
    p: 0,
    m: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: !isSubItem ? '0.5rem' : '0.1rem',
  }),
  paperProps: (isLg) => ({
    border: isLg ? 'none' : 'inherit',
  }),
  mdDrawer: { zIndex: (theme) => theme.zIndex.appBar + 100 },
  faqContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '100%',
  },
  faqButtonStyle: {
    text: {
      fontSize: '0.75rem',
    },
    box: {
      alignSelf: 'flex-end',
    },
  },
};

export default styles;
