import { Trans } from 'react-i18next';
import { Box, IconButton, Tooltip } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { Chip } from '@molecules';
import { formatDate, parseAmount } from '@utils';
import { styles } from './styles';

export const DISPERSION_STATUSES = {
  pending: {
    label: 'COMMON:PENDING',
    tooltip: 'DISPERSIONS:STATUS_DESCRIPTION_PENDING',
  },
  processing: {
    label: 'COMMON:PROCESSING',
    color: 'primary',
  },
  processed: { label: 'COMMON:COMPLETED', color: 'success' },
  failed: {
    label: 'COMMON:FAILED',
    color: 'error',
    tooltip: 'DISPERSIONS:STATUS_DESCRIPTION_FAILED',
  },
  incomplete: {
    label: 'COMMON:INCOMPLETE',
    color: 'warning',
    tooltip: 'DISPERSIONS:STATUS_DESCRIPTION_INCOMPLETE',
  },
};

/**
 * The columns of the table for DispersionsList
 * @param {Function} onClick - optional function to be called when the delete button is clicked
 * @returns {Array}
 * @constructor
 */
export const COLUMNS = (onClick = () => null) => [
  {
    title: 'DISPERSIONS:UPLOAD_DATE',
    content: (dispersion) => formatDate(dispersion.createdAt, { noSeconds: true }),
  },
  {
    title: 'COMMON:USER',
    content: (dispersion) => {
      const isMaxCharacter = dispersion.executedBy.length > 25;
      return (
        <Tooltip title={isMaxCharacter && dispersion.executedBy} arrow>
          <Box sx={[styles.rowEllipsis, styles.nameMaxWidth]}>{dispersion.executedBy}</Box>
        </Tooltip>
      );
    },
  },
  {
    title: 'DISPERSIONS:DISPERSION_DATE',
    content: (dispersion) =>
      dispersion.scheduled ? (
        formatDate(dispersion.scheduledDate, { noSeconds: true })
      ) : (
        <Trans i18nKey='DISPERSIONS:INSTANT' />
      ),
  },
  {
    title: 'DISPERSIONS:ATTEMPTED_AMOUNT',
    content: (dispersion) => parseAmount(dispersion.attemptedAmount),
  },
  {
    title: 'DISPERSIONS:DISPERSED_AMOUNT',
    content: (dispersion) => parseAmount(dispersion.dispersedAmount) || '-',
  },
  {
    title: 'COMMON:STATUS',
    content: (dispersion) => {
      let statusProps;
      const incomplete = Number(dispersion.dispersedAmount) !== Number(dispersion.attemptedAmount);
      if (incomplete && dispersion.status === 'processed') {
        statusProps = DISPERSION_STATUSES['incomplete'];
      } else {
        statusProps = DISPERSION_STATUSES[dispersion.status];
      }
      return <Chip {...statusProps} variant='outlined' sx={styles.chip} />;
    },
  },
  {
    content: ({ id, status, scheduled, scheduledDate }) =>
      status === 'pending' &&
      scheduled && (
        <IconButton onClick={() => onClick(id, formatDate(scheduledDate, { noSeconds: true }))}>
          <IconTrash />
        </IconButton>
      ),
  },
];

export const DEFAULT_PAGE_LIMIT = 25;
