const styles = {
  boxMain: { flexGrow: 1, py: '4rem' },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '3rem',
    gap: '1.5rem',
    my: '2rem',
  },
  checkboxContainer: { padding: '0.5rem 1.5rem' },
};
export default styles;
