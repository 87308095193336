export const Rubros = {
  rubros: [
    { Key: 1, Value: 'ALMUERZO' },
    { Key: 2, Value: 'SUPERMERCADO' },
    { Key: 3, Value: 'ENTRETENIMIENTO' },
    { Key: 4, Value: 'VUELTA_AL_COLE' },
    { Key: 5, Value: 'COMBUSTIBLE' },
    { Key: 6, Value: 'MASCOTAS' },
    { Key: 7, Value: 'GIMNASIOS' },
    { Key: 8, Value: 'FARMACIA' },
    { Key: 9, Value: 'ALMUERZO_AA2000' },
    { Key: 10, Value: 'ALMUERZO_CORPAM' },
    { Key: 11, Value: 'INDUMENTARIA' },
    { Key: 12, Value: 'FALABELLA' },
    { Key: 13, Value: 'CGC' },
    { Key: 14, Value: 'ALMUERZO_VERITRAN' },
  ],
};
export default Rubros;
