import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { IconEdit } from '@tabler/icons-react';
import { Button, ConfirmModal } from '@atoms';
import { CONFIRM_MODAL_ACCEPT, CONFIRM_MODAL_DECLINE } from '@globalConstants';
import { CANCEL_EDIT_BUTTON_TEXT, EDIT_BUTTON_TEXT, SAVE_CHANGES_BUTTON_TEXT } from './constants';
import styles from './styles';

/**
 * Provides editing functionality for a form or data.
 * Renders action buttons: open edit mode / save changes and exit edit mode.
 * Supports displaying a custom confirmation modal upon clicking the save button.
 * Supports displaying custom success and fail alert snackbars
 * @component
 * @param {React.Ref} formRef - Reference to the editing form node
 * @param {Object} isEditingState - Editing state object
 * @param {boolean} isEditingState.isEditing - Flag indicating if editing is enabled
 * @param {Function} isEditingState.setEditing - Function to set the editing state
 * @param {boolean} isLoading - Flag indicating if the editing action is currently being loaded
 * @param {Object} formProps - Form properties object
 * @param {boolean} formProps.isValid - Flag indicating if the form is valid
 * @param {boolean} formProps.dirty - Flag indicating if the form has been modified
 * @param {Function} formProps.handleSubmit - Function to handle form submission
 * @param {Function} formProps.resetForm - Function to reset the form
 * @param {Object} confirmationModal - Confirmation modal object
 * @param {Object} confirmationModal.modalState - Success state object
 * @param {boolean} confirmationModal.modalState.confirmModalVisible - Flag indicating if confirm modal is visible
 * @param {Function} confirmationModal.modalState.setConfirmModalVisible - Function to set the modal visibility state
 * @param {string} confirmationModal.title - Title of the confirmation modal
 * @param {string} confirmationModal.description - Description of the confirmation modal
 * @param {Function} handleSetEdit - Custom function to execute on edit button
 * @param {boolean} disabledEditButton - if true, disable the edit button
 * @param {Function} handleCancel - Custom function to execute on cancel button
 * @returns {JSX.Element} EditActionButtons component
 */
const EditActionButtons = ({
  formRef,
  isEditingState: { isEditing, setEditing },
  isLoading,
  formProps: { isValid, dirty, handleSubmit, resetForm },
  confirmationModal,
  handleSetEdit,
  disabledEditButton,
  handleCancel,
  hideCancelButton = false,
}) => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const disabledSave = isLoading || !(isValid && dirty);

  /** Handle calling of edit mode */
  const handleClickEdit = () => {
    if (handleSetEdit) {
      handleSetEdit();
    }
    setEditing(true);
  };

  useEffect(() => {
    const form = formRef.current;
    /**
     * Manually handles the Enter keydown event when the user is editing
     * Prevents the default behavior (default submit - page reoad)
     * If there is a confirmation modal, then open it.
     * If there isn't a confirmation modal, execute the handleSubmit
     * @param {React.FormEvent} - The submit event
     */
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (isEditing && !disabledSave) {
          if (confirmationModal) {
            setConfirmModalVisible(true);
          } else {
            handleSubmit();
          }
        }
      }
    };

    if (isEditing) {
      form.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (isEditing) {
        form.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [formRef, isEditing, confirmationModal, handleSubmit, disabledSave]);

  return (
    <>
      {isEditing ? (
        <Box sx={styles.editingButtonsGroup}>
          <Button
            disabled={disabledSave}
            isLoading={isLoading}
            variant='contained'
            text={SAVE_CHANGES_BUTTON_TEXT}
            type={confirmationModal ? 'button' : 'submit'}
            onClick={() => {
              if (confirmationModal) {
                setConfirmModalVisible(true);
              } else {
                handleSubmit();
              }
            }}
          />

          {hideCancelButton && (
            <Button
              variant='outlined'
              text={CANCEL_EDIT_BUTTON_TEXT}
              type='button'
              onClick={() => {
                setEditing(false);
                resetForm();
                if (handleCancel) {
                  handleCancel();
                }
              }}
            />
          )}
        </Box>
      ) : (
        <Button
          color='primary'
          startIcon={<IconEdit />}
          text={EDIT_BUTTON_TEXT}
          type='button'
          onClick={handleClickEdit}
          disabled={disabledEditButton}
        />
      )}
      {confirmationModal && (
        <ConfirmModal
          title={confirmationModal.title}
          description={confirmationModal.description}
          modalVisible={confirmModalVisible}
          setModalVisible={setConfirmModalVisible}
          handleSubmit={() => {
            handleSubmit();
            setConfirmModalVisible(false);
          }}
          closeButtonText={CONFIRM_MODAL_DECLINE}
          submitButtonText={CONFIRM_MODAL_ACCEPT}
        />
      )}
    </>
  );
};

EditActionButtons.propTypes = {
  formRef: PropTypes.object.isRequired,
  isEditingState: PropTypes.shape({
    isEditing: PropTypes.bool.isRequired,
    setEditing: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  formProps: PropTypes.shape({
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  }),
  confirmationModal: PropTypes.shape({
    modalState: PropTypes.shape({
      confirmModalVisible: PropTypes.bool.isRequired,
      setConfirmModalVisible: PropTypes.func.isRequired,
    }),
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  handleSetEdit: PropTypes.func,
  disabledEditButton: PropTypes.bool,
  handleCancel: PropTypes.func,
  hideCancelButton: PropTypes.bool,
};

export default EditActionButtons;
