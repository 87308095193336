import { useEffect } from 'react';
import { usePagination } from '@hooks';
import useURLparams from '../useURLparams';

/**
 * Obtains a list of prospect values .
 * @param {company} company id seleted
 * @param {boolean} allowRequest
 * @returns {Object}
 */
const useProspectList = (company, setSelectedItems) => {
  useEffect(() => {
    setSelectedItems([]);
  }, [company, setSelectedItems]);

  const { params, setURLparams } = useURLparams();
  const paginationProps = usePagination(company && '/client/dispersion/search', {
    shouldFetchWithoutParams: true,
    setURLparams,
    params: { ...params, limit: params.limit || '25', companyId: String(company) },
    paramKeys: ['product', 'categories', 'companyId', 'firstName', 'lastName', 'cuil'],
  });

  /**
   * Sets parameters for URL.
   * @param {Object} obj - Object containing parameters to be set.
   */
  const setParams = (obj) => {
    const filteredParams = Object.fromEntries(
      Object.entries({ ...params, ...obj }).filter(([, value]) => value !== '')
    );
    setURLparams(filteredParams);
  };

  return { ...paginationProps, params, setParams };
};

export default useProspectList;
