import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, SvgIcon, useMediaQuery } from '@mui/material';
import { IconSearch } from '@tabler/icons-react';
import { Button, Input, Select } from '@atoms';
import { useFilterInternalFeatures } from '@hooks';
import { clearObject } from '@utils';
import styles from './styles';

/**
 * Renders a modal with the inputs fields and search clients
 * @param {object} props
 * @param  {boolean} props.isOpen is modal is or not visible
 * @param  {() => void | () => Promise<void>} props.onClose function to execuete after modal close
 * @param  {boolean} props.isLoading flag indicating whether the request is loading
 * @param  {object} props.params the current URL params
 * @param  {() => void | () => Promise<void>} props.onFiltersFormSubmit function to set clients data
 */
const FormDispersion = ({
  onSubmit,
  configFields,
  validationSchema,
  initialValues,
  clearFilters,
  disabledFilters,
  selectProductlabel,
  selectProductvalue,
  selectProductdisabled,
  selectProductonChange,
  selectProductitems,
  selectProductparseOption,
  selectCategorylabel,
  selectCategoryvalue,
  selectCategorydisabled,
  selectCategoryonChange,
  selectCategoryitems,
  selectCategoryparseOption,
}) => {
  const { t } = useTranslation();
  const fields = useFilterInternalFeatures(configFields);
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openFilters, setOpenFilters] = useState(false);

  const {
    handleChange: formikHandleChange,
    handleSubmit,
    handleBlur,
    errors,
    values,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
    },
  });

  /**
   *  Open filters in searchBox
   */
  const filtersOnChange = () => {
    setOpenFilters(!openFilters);
  };

  /**
   * Clears the filters
   */
  const handleClearFilters = () => {
    clearFilters();
    const newValues = clearObject(values);
    setValues(newValues);
  };

  /**
   * Handles the form change
   * Trims whitespaces at the beginning and end if the event is a clipboard paste
   */
  function handleChange(name) {
    const handler = formikHandleChange(name);
    return (event) => {
      if (event.type === 'paste') {
        event.preventDefault();
        handler(event.clipboardData.getData('text/plain').trim());
      } else {
        handler(event.target.value);
      }
    };
  }

  return (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.header}>
        <Typography variant='h4'>{t('DISPERSIONS:FAST_DISPERSION:TITLE')}</Typography>
      </Box>
      <Box style={styles.content}>
        <Box style={styles.firstLine(responsive)}>
          <Box style={styles.inputContainer(responsive)}>
            <Select
              label={selectProductlabel}
              value={selectProductvalue}
              disabled={selectProductdisabled}
              onChange={selectProductonChange}
              items={selectProductitems}
              parseOption={selectProductparseOption}
              customStyle={{ maxWidth: !responsive ? '220px' : '100%' }}
            />
            <Select
              label={selectCategorylabel}
              value={selectCategoryvalue}
              disabled={selectCategorydisabled}
              onChange={selectCategoryonChange}
              items={selectCategoryitems}
              parseOption={selectCategoryparseOption}
              customStyle={{ maxWidth: !responsive ? '220px' : '100%' }}
              multiple
            />
          </Box>
          <Box style={styles.actionsContainer(responsive)}>
            <Button
              customStyle={styles.filterButton}
              startIcon={<SvgIcon>{openFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}</SvgIcon>}
              color='primary'
              variant='link'
              onClick={filtersOnChange}
            />
            <Button
              customStyle={styles.clearButton}
              text={t('COMMON:CLEAR_FILTER')}
              color='primary'
              variant='link'
              onClick={handleClearFilters}
              disabled={disabledFilters}
            />
            <Button
              customStyle={styles.searchButton}
              startIcon={
                <SvgIcon>
                  <IconSearch />
                </SvgIcon>
              }
              variant='contained'
              color='primary'
              onClick={handleSubmit}
            />
          </Box>
        </Box>
        {openFilters && (
          <Box style={styles.secondLine(responsive)}>
            {fields.map(({ name, label }) => (
              <Input
                key={name}
                label={label}
                variant='outlined'
                size='small'
                name={name}
                value={values[name]}
                onChange={handleChange(name)}
                inputProps={{ onPaste: handleChange(name) }}
                onBlur={handleBlur(name)}
                helperText={errors[name]}
                error={errors[name]}
                sx={{ maxWidth: !responsive ? '220px' : '100%' }}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FormDispersion;

FormDispersion.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  configFields: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  showClearFilter: PropTypes.bool,
  textButtonCancel: PropTypes.string,
  textButtonConfirm: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  clearFilters: PropTypes.object,
  disabledFilters: PropTypes.object,
  selectProductlabel: PropTypes.object,
  selectProductvalue: PropTypes.object,
  selectProductdisabled: PropTypes.object,
  selectProductonChange: PropTypes.object,
  selectProductitems: PropTypes.object,
  selectProductparseOption: PropTypes.object,
  selectCategorylabel: PropTypes.object,
  selectCategoryvalue: PropTypes.object,
  selectCategorydisabled: PropTypes.object,
  selectCategoryonChange: PropTypes.object,
  selectCategoryitems: PropTypes.object,
  selectCategoryparseOption: PropTypes.object,
};
