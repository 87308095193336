const styles = {
  box: {
    backgroundColor: ({ palette }) => palette.primary[600],
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    maxHeight: '10.3rem',
    overflow: 'auto',
  },
};

export default styles;
