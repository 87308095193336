import Logger from './logger';

const logger = new Logger('checkInternetConnection');
/**
 * Check with NetInfo if the device is connected to a network, and check via google endpoint if there is internet access
 */
export default async function checkInternetConnection() {
  const isConnected = window.navigator.onLine;
  if (isConnected) {
    try {
      // Used fetch instead of axios to avoid circular dependencies
      await fetch('https://clients1.google.com/generate_204', { mode: 'no-cors' });
      return true;
    } catch (error) {
      logger.error('An error ocurred while checking internet connection', error);
      return false;
    }
  } else {
    logger.error('The browser does not seems to be online');
    return false;
  }
}
