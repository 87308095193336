import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { TFunction } from 'i18next';
import { RowItem } from 'screens/employeeDetails/tabs/productsTab/ProductCard/constants';
import * as Yup from 'yup';
import { getDifferentProperties } from '@utils';

/**
 * Custom hook for generating a form state object from a list of items/fields.
 * @param {Array} items - An array of items representing list fields (each item may or may not be editable).
 * @param {object} initialData - Data that will map to initial values for their corresponding field.
 * @param {function} onSubmit - The submit callback function for the form.
 * @returns {object} - Form props and functionality, formFields array, and changedValues string.
 */
const useItemListToForm = <T extends object, O = unknown>(
  items: RowItem<T, O>[],
  initialData: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: Partial<T>, formikHelpers: FormikHelpers<Partial<T>>) => void | Promise<any>,
  t?: TFunction
) => {
  const [changedValues, setChangedValues] = useState<string | null>(null);

  const formFields = items.filter((item) => {
    if (item.shouldHideEdit) {
      const hide = item.shouldHideEdit(initialData);

      if (hide) return false;
    }

    return Boolean(item.inputName);
  });

  const initialValues = formFields.reduce<Partial<T>>((object: Partial<T>, item: RowItem<T, O>) => {
    const fieldName = item.inputName;

    if (fieldName && item.getInitialValue) {
      object = {
        ...object,
        [fieldName]: item.getInitialValue(initialData, t),
      };
      // object[fieldName] = item.getInitialValue(initialData);
    }
    return object;
  }, {});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validationSchema = formFields.reduce<any>((schema, item: RowItem<T, O>) => {
    const fieldName = item.inputName;

    if (fieldName) {
      const validation = item.inputValidation;
      return schema.shape({ [fieldName]: validation });
    }
  }, Yup.object());

  const formikProps = useFormik<Partial<T>>({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  /**
   * Custom handleChange function that:
   * Updates the form's state
   * Updates a string containing the fields that have been edited
   * @param {React.ChangeEvent} event The input event object
   * @param  inputName The 'name' of the field in the form
   */
  function handleChange(event: React.ChangeEvent<HTMLInputElement>, inputName: keyof T) {
    const value = event.target.value;
    formikProps.setFieldValue(inputName as string, value);
    const newValues = { ...formikProps.values, [inputName]: value };
    const changedValues = getDifferentProperties(initialValues, newValues);
    setChangedValues(changedValues);
  }

  return {
    formProps: { ...formikProps, handleChange } as const,
    formFields,
    changedValues,
  };
};

export default useItemListToForm;
