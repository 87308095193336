import { apiClient } from 'config/api';

interface RegisterCompanyInPrismaBody {
  email: string;
  legalName: string;
  tradeName: string;
  identificationType: string;
}

/** Service for register company in Prisma
 * @param selectedCompanyId the company id for register in prisma
 * @param body
 * @param body.email company contact email address
 * @param body.legalName company legal name
 * @param body.tradeName company fantasy name
 * @param body.identificationType argentine company type ex: SRL, SA, etc
 */
export default async function registerCompanyInPrisma(
  selectedCompanyId: number,
  body: RegisterCompanyInPrismaBody
): Promise<void> {
  return await apiClient.patch(`/partner/product/prisma-register/${selectedCompanyId}`, body);
}
