const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    borderRadius: '1rem',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  },
  itemsBox: { p: '1.5rem' },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 2,
    justifyContent: 'space-between',
    width: '100%',
  },
  editInput: (disabled) => ({
    color: ({ palette }) => (disabled ? palette.gray[60] : palette.secondary),
  }),
};

export default styles;
