import useSWR from 'swr';

/**
 * Custom hook for fetching client/company account data
 * @param {string} company - The ID of the company.
 *  @returns {Object} - The response object from the SWR fetch.
 */
const useCompanyDetails = (company) => {
  const props = useSWR(() => company && `/partner/company/${company}`);

  return props;
};

export default useCompanyDetails;
