import PropTypes from 'prop-types';
import { Autocomplete } from '@atoms';
import { useUserCompanies } from '@hooks';
import { useCompanyStore } from '@stores';

/**
 * SelectCompany Component
 *
 * This component renders a company selection Autocomplete using the `Autocomplete` component.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {boolean} [props.disabled = false] - Indicates whether the component is in a disabled state.
 * @param {Function} [props.onChange = undefined] - Callback function triggered on value change.
 * @param {Object} [props.value = undefined] - The selected value.
 * @returns {JSX.Element} - The SelectCompany component.
 */
const SelectCompany = (props) => {
  const { setSelectedCompany, selectedCompany } = useCompanyStore();
  const { data, isLoading } = useUserCompanies();

  /**
   * @param {Event} _event The change event
   * @param {object} selectedOption The selected option object
   */
  const handleGlobalChange = (_event, selectedOption) => {
    if (selectedOption) {
      setSelectedCompany(selectedOption.id);
    }
  };

  const { onChange = handleGlobalChange, value = selectedCompany, disabled } = props;

  return (
    <Autocomplete
      disabled={disabled}
      isLoading={isLoading}
      onChange={onChange}
      value={value}
      items={data}
      placeholder={props.placeholder ?? 'CLIENTS:SELECT_COMPANY'}
      getOptionLabel={(value) =>
        typeof value === 'object'
          ? value.label ?? ''
          : data?.find((item) => item.id === Number(value))?.description || ''
      }
      isOptionEqualToValue={(option, value) => option.id === value}
      parseOption={(option) => ({ id: option.id, label: option.description })}
    />
  );
};

/**
 * PropTypes for SelectCompany component.
 *
 * @static
 * @memberof SelectCompany
 * @property {boolean} isLoading - Indicates whether the component is in a loading state.
 * @property {Function} onChange - Callback function triggered on value change.
 * @property {Object} value - The selected value.
 */
SelectCompany.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectCompany;
