const styles = {
  link: {
    color: ({ palette }) => palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignSelf: 'flex-start',
    alignItems: 'center',
    gap: '8px',
  },
};

export default styles;
