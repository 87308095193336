import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';
import { Button, Input } from '@atoms';
import { parseAmount } from '@utils';
import styles from './styles';
import { BALANCE_RETRIEVE_VALIDATION } from '../constants';
import { Product } from '../useProducts';
import { ChangeRetrieveBalanceBody } from '../useRetriveBalance';

interface RetrieveBalanceModalProps {
  product: Product;
  postRetrieveBalance: (data: ChangeRetrieveBalanceBody) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
}
/**
 * Renders a modal with the input fields and recover balance
 * @param {RetrieveBalanceModalProps} props
 * @param  props.isOpen is modal is or not visible
 * @param  props.onClose function to execuete after modal close
 * @param  props.isLoading flag indicating whether the request is loading
 * @param  props.handleSubmit function to set clients data
 */
const RetrieveBalanceModal = ({
  postRetrieveBalance,
  isOpen,
  onClose,
  isLoading,
  product,
}: RetrieveBalanceModalProps) => {
  const [fullRecovery, setFullRecovery] = useState(true);

  const totalBalance = product.balance_available;

  const { t } = useTranslation();

  /**
   * Call the function that updates the balance.
   */
  const onSubmit = () => {
    postRetrieveBalance({
      amount: fullRecovery ? product.balance_available : Number.parseFloat(values.amount),
      fullRecovery,
      userAccountNumber: product.account_number,
    });
  };

  const { handleSubmit, errors, values, handleChange, setErrors, resetForm } = useFormik({
    initialValues: { amount: '' },
    validationSchema: BALANCE_RETRIEVE_VALIDATION({ totalBalance, fullRecovery }),
    onSubmit,
  });

  /**
   * Handle the change of the partialRecovery switch
   * @param  isPartial
   */
  const handlePartialRecoveryChange = (isPartial: boolean) => {
    const isFullRecovery = !isPartial;

    if (isFullRecovery) {
      setErrors({});
      resetForm();
    }

    setFullRecovery(isFullRecovery);
  };

  /**
   * Handles a change in the value
   * @param {ChangeEvent} event
   */
  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    value = value.replace(/[^0-9.]/g, '');

    handleChange('amount')(value);
  };

  const invalidAmount = fullRecovery ? false : Boolean(errors.amount);
  const disableButton = isLoading || invalidAmount;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t('EMPLOYEES:DETAIL:RETRIEVE_BALANCE_TITLE')}</DialogTitle>
      <DialogContent>
        <Box sx={styles.modalContent} textAlign='center'>
          <FormControlLabel
            sx={styles.formSwitch}
            label={t('EMPLOYEES:DETAIL:RETRIEVE_PARTIAL_AMOUNT')}
            control={
              <Switch
                checked={!fullRecovery}
                onChange={(e, isPartial) => handlePartialRecoveryChange(isPartial)}
              />
            }
          />
          <Input
            variant='outlined'
            size='small'
            inputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              placeholder: fullRecovery
                ? totalBalance
                : t('EMPLOYEES:DETAIL:RETRIEVE_BALANCE_AMOUNT_PLACEHOLDER'),
              type: 'text',
            }}
            error={Boolean(errors.amount)}
            value={values.amount}
            onChange={handleAmountChange}
            disabled={fullRecovery}
            helperText={
              fullRecovery
                ? t('EMPLOYEES:DETAIL:RETRIEVE_BALANCE_TOTAL_HELPER', {
                    balance: parseAmount(totalBalance),
                  })
                : errors.amount
            }
          />
          <DialogActions>
            <Button text={t('COMMON:CANCEL')} color='error' onClick={onClose} />
            <Button
              variant='contained'
              color='primary'
              type='submit'
              isLoading={isLoading}
              disabled={disableButton}
              onClick={handleSubmit}
              text={t('COMMON:CONFIRM')}
            />
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RetrieveBalanceModal;
