import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { addHours, addYears, format } from 'date-fns';
import PropTypes from 'prop-types';
import { Box, Switch, Typography } from '@mui/material';
import { apiClient } from 'config/api';
import { Alert, Button, DateTimePicker, StrongModalText } from '@atoms';
import { CSV_LINE_ERRORS_MESSAGES } from '@globalConstants';
import { useCompanyDetails, useUser, useUserCompanies } from '@hooks';
import { BoxContainer } from '@layouts';
import { Dropzone, ErrorList } from '@molecules';
import { TemplateLinkDispersion } from '@organisms';
import { useCompanyStore } from '@stores';
import { parseAmount } from '@utils';
import styles from './styles';

/**
 * File dispersion tab (original content of the screen)
 */
const FileDispersionTab = ({ isLoading, setLoading, data }) => {
  const [dispersionDay, setdispersionDay] = useState(null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [dispersionSuccess, setDispersionSuccess] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, setError] = useState({ title: '', description: '' });
  const [dispersionFiles, setDispersionFiles] = useState([]);
  const [fileErrorDetails, setFileErrorDetails] = useState([]);
  const { selectedCompany } = useCompanyStore();
  const { data: companyData } = useCompanyDetails(selectedCompany);
  const companyName = companyData?.description;
  const [confirmDispersion, setConfirmDispersion] = useState({
    openModal: false,
    dispersionId: null,
    total: null,
    count: null,
  });

  const { user } = useUser();

  const { isLoading: isCompaniesLoading, error: companiesError } = useUserCompanies();

  const { t } = useTranslation();

  const today = new Date();

  const formatDay = dispersionDay && format(dispersionDay, 'dd/MM/yyyy');
  const formatTime = dispersionDay && format(dispersionDay, 'HH:mm');

  const hasSelectedCompany = Boolean(selectedCompany);
  const hasSelectedFile = dispersionFiles.length > 0;
  const hasFileLineErrors = fileErrorDetails.length > 0;

  /**
   * Handle the selection of a file
   */
  const handleSelectFiles = useCallback(
    (files) => {
      if (hasFileLineErrors) {
        setFileErrorDetails([]);
      }

      setDispersionFiles(files);
    },
    [hasFileLineErrors]
  );

  /**
   * validation to disable or not dispersion send file button
   */
  const disableButton = () => {
    if (isLoading || hasFileLineErrors || isCompaniesLoading || companiesError) return true;

    if (user.isInternal) {
      if (!hasSelectedCompany) return true;
    } else {
      if (!data) return true;
    }

    if (isScheduled) {
      return !(hasSelectedFile && dispersionDay);
    } else {
      return !hasSelectedFile;
    }
  };

  /**
   * Handle disperison confirmation
   * @param {boolean} confirm Whether the user confirms of cancels the dispersion
   */
  const handleConfirmation = async (confirm) => {
    setLoading(true);
    try {
      await apiClient.put('/s3/dispersion', {
        dispersionId: confirmDispersion.dispersionId,
        confirm,
      });
      if (confirm) {
        setDispersionSuccess(true);
        setdispersionDay(null);
        setDispersionFiles([]);
      }
    } catch (error) {
      const response = error.response;
      if (confirm) {
        if (response?.status === 404 && response.data?.errors?.[0]?.message === 'Key not found') {
          setError({
            title: 'DISPERSIONS:ERRORS:EXPIRED_TITLE',
            description: 'DISPERSIONS:ERRORS:EXPIRED_DESCRIPTION',
          });
        } else {
          setError({
            title: 'DISPERSIONS:ERRORS:CONFIRMATION_ERROR_TITLE',
            description: 'DISPERSIONS:ERRORS:CONFIRMATION_ERROR_DESCRIPTION',
          });
        }
        setErrorOpen(true);
      }
    }
    setConfirmDispersion({ dispersionId: null, openModal: false, total: null, count: null });
    setLoading(false);
  };

  /**
   * send file to be
   * TODO: Move to services.js
   */
  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', dispersionFiles[0]);

    formData.append('companyId', selectedCompany);

    dispersionDay && formData.append('date', format(dispersionDay, 'yyyyMMddHH'));

    const url = '/s3/dispersion';
    const body = formData;

    try {
      const {
        data: { dispersionId, total, count },
      } = await apiClient.post(url, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setConfirmDispersion({ openModal: true, dispersionId, total, count });
    } catch (error) {
      const response = error.response;
      if (response?.status === 400) {
        setErrorOpen(true);
        if (
          response?.data.code === 'csv_line_errors' ||
          response?.data.code === 'file_line_errors'
        ) {
          const fileErrors = response.data.data;
          setFileErrorDetails(fileErrors);
          setError({
            title: 'DISPERSIONS:ERRORS:FILE_CONTAINS_ERRORS_TITLE',
            description: 'DISPERSIONS:ERRORS:FILE_CONTAINS_ERRORS_DESCRIPTION',
          });
        } else if (response.data.code === 'csv_record_inconsistent_columns') {
          setError({
            title: 'COMMON:VALIDATIONS:INCONSISTENT_COLUMNS_TITLE',
            description: t('COMMON:VALIDATIONS:INCONSISTENT_COLUMNS_DESCRIPTION', {
              line: response.data.data.line,
            }),
          });
        } else {
          const code = response.data?.errors[0]?.message;
          switch (code) {
            case 'Filename is not accepted.':
              setError({
                title: 'DISPERSIONS:ERRORS:INVALID_FILE_NAME_TITLE',
                description: 'DISPERSIONS:ERRORS:INVALID_FILE_NAME_DESCRIPTION',
              });
              break;
            case 'Account balance is less than dispersion amount.':
              setError({
                title: 'DISPERSIONS:ERRORS:INSUFFICIENT_BALANCE_TITLE',
                description: 'DISPERSIONS:ERRORS:INSUFFICIENT_BALANCE_DESCRIPTION',
              });
              break;
            default:
              setError({
                title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
                description: 'DISPERSIONS:ERRORS:DEFAULT_BAD_REQUEST_ERROR',
              });
              break;
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <BoxContainer>
      <Box sx={styles.paperHead}>
        <Typography variant='h4'>{t('DISPERSIONS:UPLOAD_FILE')}</Typography>
        <TemplateLinkDispersion companyId={selectedCompany} />
      </Box>
      <Dropzone
        files={dispersionFiles}
        setFiles={setDispersionFiles}
        disabled={isLoading}
        handleFilesSelection={handleSelectFiles}
      />
      <Box sx={styles.scheduleContainer}>
        <Box sx={styles.scheduleText}>
          <Typography variant='h4'>{t('DISPERSIONS:SCHEDULE_TITLE')}</Typography>
          <Typography variant='body2' color='gray.90'>
            {t('DISPERSIONS:SCHEDULE_TEXT')}
          </Typography>
        </Box>
        <Switch checked={isScheduled} onChange={() => setIsScheduled(!isScheduled)} />

        {isScheduled && (
          <DateTimePicker
            hideToolbar
            value={dispersionDay}
            handleChange={(newValue) => {
              setdispersionDay(newValue);
            }}
            inputLabel={'DISPERSIONS:INPUT_LABEL_DATE'}
            minDateTime={addHours(today, 1)}
            maxDate={addYears(today, 3)}
            calendarViews={['year', 'month', 'day', 'hours']}
          />
        )}
      </Box>
      <Button
        text={'COMMON:FILE_UPLOADS:SEND_FILE_BUTTON'}
        variant='contained'
        color='primary'
        onClick={handleSubmit}
        disabled={disableButton()}
        isLoading={isLoading}
        size='large'
      />
      {/* TODO - Replace Alert by DispersionConfirmationModal componnet orgnisms*/}
      <Alert
        variant='warning'
        modalVisible={confirmDispersion.openModal}
        handleClose={() => handleConfirmation(false)}
        handleSubmit={() => handleConfirmation(true)}
        closeButtonText='COMMON:CANCEL'
        title={t('DISPERSIONS:MODAL_TITLE')}
        submitLoading={isLoading}
        disabled={disableButton()}
        content={
          isScheduled ? (
            <Typography color='gray.90'>
              <Trans
                i18nKey='DISPERSIONS:MODAL_MESSAGE_SCHEDULED'
                count={confirmDispersion.count}
                formatDay={formatDay}
                formatTime={formatTime}
              >
                Estás programando una dispersión de
                <StrongModalText>{{ total: parseAmount(confirmDispersion.total) }}</StrongModalText>
                entre
                <StrongModalText>{confirmDispersion.count}</StrongModalText>
                colaboradores de <StrongModalText>{{ companyName }}</StrongModalText>
                para que se ejecute el día
                <StrongModalText>{{ formatDay }}</StrongModalText>a las
                <StrongModalText>{{ formatTime }}</StrongModalText>.
              </Trans>
            </Typography>
          ) : (
            <Typography color='gray.90'>
              <Trans
                i18nKey='DISPERSIONS:MODAL_MESSAGE_INSTANT'
                count={confirmDispersion.count}
                formatDay={formatDay}
                formatTime={formatTime}
              >
                Estás a punto de dispersar
                <StrongModalText>{{ total: parseAmount(confirmDispersion.total) }}</StrongModalText>
                entre
                <StrongModalText>{confirmDispersion.count}</StrongModalText> colaboradores de
                <StrongModalText>{{ companyName }}</StrongModalText>.
              </Trans>
            </Typography>
          )
        }
      />
      <Alert
        modalVisible={dispersionSuccess}
        handleClose={() => setDispersionSuccess(false)}
        variant='success'
        title={
          isScheduled
            ? 'DISPERSIONS:DISPERSIONS_SUCCESS_TITLE_SCHEDULED'
            : 'DISPERSIONS:DISPERSIONS_SUCCESS_TITLE_INSTANT'
        }
        description={
          isScheduled
            ? 'DISPERSIONS:DISPERSIONS_SUCCESS_BODY_SCHEDULED'
            : 'DISPERSIONS:DISPERSIONS_SUCCESS_BODY_INSTANT'
        }
      />
      <Alert
        modalVisible={errorOpen}
        handleClose={() => setErrorOpen(false)}
        description={error.description}
        title={error.title}
        widePaper={hasFileLineErrors}
        content={
          hasFileLineErrors && (
            <ErrorList
              errors={fileErrorDetails}
              title={'DISPERSIONS:CSV_ERRORS_TITLE'}
              getMessages={CSV_LINE_ERRORS_MESSAGES}
            />
          )
        }
      />
    </BoxContainer>
  );
};

FileDispersionTab.propTypes = {
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
  data: PropTypes.object,
  selectedCompany: PropTypes.string,
  setSelectedCompany: PropTypes.func,
};

export default FileDispersionTab;
