import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

/**
 * Autocomplete component with complex functionality.
 * @param {Object} props - Component props.
 * @param {Array} props.items - Options data.
 */
const AutocompleteComplex = ({
  label,
  margin = 'normal',
  value,
  size = 'medium',
  error,
  onChange,
  onBlur,
  helperText,
  variant = 'outlined',
  inputProps = {},
  sx = {},
  disabled = false,
  name,
  items,
  placeholder = 'COMMON:SELECT_PLACEHOLDER',
  freeSolo = false,
  parseOption,
  multiple = false,
}) => {
  const { t } = useTranslation();

  const selectOptions = items && (parseOption ? items.map((item) => parseOption(item)) : items);

  /**
   * Handles change event.
   * @param {Object} event - Event object.
   * @param {Array} value - New value.
   */
  const handleChange = (_event, value) => {
    onChange({ target: { value } });
  };

  return (
    <Autocomplete
      freeSolo={freeSolo}
      multiple={multiple}
      options={selectOptions}
      getOptionLabel={(option) => option.title || option}
      onChange={handleChange}
      onInputChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      name={name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={t(label)}
          placeholder={t(placeholder)}
          margin={margin}
          fullWidth
          helperText={helperText}
          error={error}
          size={size}
          sx={sx}
          value={value}
          {...inputProps}
        />
      )}
    />
  );
};

AutocompleteComplex.propTypes = {
  label: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  password: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  sx: PropTypes.object,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  freeSolo: PropTypes.bool,
  placeholder: PropTypes.string,
  items: PropTypes.array.isRequired,
  parseOption: PropTypes.func,
  multiple: PropTypes.bool,
};

export default AutocompleteComplex;
