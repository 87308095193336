import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, List, ListItem, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { Link } from '@atoms';
import helpTexts from './constants';
import styles from './styles';
import Dialog from '../Dialog';

interface LoginInstructionsProps {
  intro: string;
  links: Array<StoreLink>;
  steps: string[];
}
type StoreLink = {
  text: string;
  link: string;
  textLink: string;
};
/**
 * Renders the instructions for the login process, including an introduction,
 * a list of links, and steps to follow. The instructions are displayed as a bulleted list.
 */
const LoginInstructions: React.FC<LoginInstructionsProps> = ({ intro, links, steps }) => (
  <Box>
    <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
      <ListItem sx={{ display: 'list-item' }}>{intro}</ListItem>

      {links.map(({ text, textLink, link }: StoreLink, index) => (
        <ListItem key={`link-${index}`}>
          <Box sx={styles.linkContainer}>
            <Typography variant='body1'>{text}</Typography>
            <Link href={link} target='_blank' text={textLink} />
          </Box>
        </ListItem>
      ))}

      {steps.map((step, index) => (
        <ListItem key={`step-${index}`} sx={{ display: 'list-item' }}>
          {step}
        </ListItem>
      ))}
    </List>
  </Box>
);

interface LoginHelpDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}
/**
 * Renders a dialog with help instructions for the login process. The dialog includes a title,
 * an introduction, and detailed steps with optional links, all of which are localized using i18next.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Indicates whether the dialog is open.
 * @param {function} props.handleClose - Function to handle the closing of the dialog.
 * @returns {JSX.Element} The rendered component.
 */
const LoginHelpDialog: React.FC<LoginHelpDialogProps> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const { title, intro, links, steps } = helpTexts;

  const translatedLinks = links.map(({ text, link, textLink }) => ({
    text: t(text),
    link: t(link),
    textLink: t(textLink),
  }));

  const translatedSteps = steps.map((step) => t(step));

  return (
    <Dialog
      isOpen={isOpen}
      dialogTitle={
        <>
          <Typography variant='h6'>{t(title)}</Typography>
          <IconButton onClick={handleClose} sx={styles.closeButton}>
            <IconX />
          </IconButton>
        </>
      }
      dialogContent={
        <LoginInstructions intro={t(intro)} links={translatedLinks} steps={translatedSteps} />
      }
      onClose={handleClose}
    />
  );
};

export default LoginHelpDialog;
