import { FullWidthContent, MainMenuLayout } from '@layouts';
import DispersionsList from './DispersionsList';
import SelectCompanyCard from '../SelectCompanyCard';

/** My dispersions screen */
const MyDispersionsScreen = () => {
  return (
    <MainMenuLayout>
      <FullWidthContent>
        <SelectCompanyCard disableHeader />
        <DispersionsList />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default MyDispersionsScreen;
