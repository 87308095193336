import { useCompanyStore } from '@stores';
import useCustomAndGenericCategoriesByCompany from './useCustomAndGenericCategoriesByCompany';

/**
 * Obtains a list of categories by company
 * @param allowRequest - Indicates whether the request to fetch categories should be made. If `false`, the request will not be executed
 */
const useSelectedCompanyCustomAndGenericCategories = (allowRequest = true) => {
  const { selectedCompany } = useCompanyStore();
  const props = useCustomAndGenericCategoriesByCompany({
    allowRequest,
    companyId: selectedCompany,
  });

  return props;
};

export default useSelectedCompanyCustomAndGenericCategories;
