import { useCompanyStore } from '@stores';
import useCategoriesByCompany from './useCategoriesByCompany';

/**
 * Obtains a list of categories by company
 */
const useSelectedCompanyCategories = (allowRequest = true) => {
  const { selectedCompany } = useCompanyStore();
  const props = useCategoriesByCompany({ allowRequest, companyId: selectedCompany });

  return props;
};

export default useSelectedCompanyCategories;
