import { useState } from 'react';
import { FIELDS } from 'screens/employeesList/searchBoxEmployees/constants';
import { usePagination, useUser } from '@hooks';
import { useCompanyStore } from '@stores';

/**
 * Data returned by the hook
 * @typedef {object} PaginationHookResult
 * @property {object} data - The response data from the API.
 * @property {boolean} isLoading - Indicates whether the data is currently being loaded. (from SWR)
 * @property {Error} error - The error object, if an error occurred during the request. (from SWR)
 * @property {boolean} isValidating - Indicates whether the data is currently being revalidated. (from SWR)
 * @property {function} handleRowsPerPageChange - Function to handle the change of rows per page.
 * @property {function} handlePageChange - Function to handle the change of page.
 * @property {boolean} filtersOpen - Indicates whether the filters are open.
 * @property {Array} selectedItems - List of checked items in the table
 * @property {function} setFiltersOpen - Function to update the state of filtersOpen.
 * @property {function} onFiltersFormSubmit - Function to handle the submission of the filters form.
 */

/**
 *
 * A reusable SWR hook for paginated search.
 * @param {string} url - The API endpoint URL.
 * @param {object} options - Options for pagination or search.
 * @param {object} options.params - Key-value object containing filtering/data params of the request.
 * @param {function} options.setURLparams - Function to update the URL parameters.
 * @param {function} options.onError - Cb function to be executed when SWR request fails
 * @returns {PaginationHookResult} - An object containing the following properties and functions:
 */
const useEmployees = ({ params, setURLparams, onError }) => {
  const { user } = useUser();
  const { selectedCompany } = useCompanyStore();

  const [assignProductOpen, setAssignProductOpen] = useState(false);
  const { onFiltersFormSubmit, ...paginationProps } = usePagination('/client/search-account', {
    params,
    setURLparams,
    onError,
    paramKeys: [...FIELDS.map((field) => field.name), 'product', 'categories', 'companyId'],
  });

  /**
   * Override the form submit function to include the customer Id if the user is not internal and won't select it in the form
   * @param {object} values Form values
   */
  function customSubmit(values) {
    if (!user.isInternal) {
      values.companyId = String(selectedCompany);
    }

    onFiltersFormSubmit(values);
  }

  return {
    ...paginationProps,
    assignProductOpen,
    setAssignProductOpen,
    onFiltersFormSubmit: customSubmit,
  };
};

export default useEmployees;
