const styles = {
  leftImage: { height: '100vh', objectFit: 'cover', display: { xs: 'none', md: 'block' } },
  display: { xs: 'none', md: 'block' },
  rightContent: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingX: { xs: 5, md: 10 },
    height: '50vh',
  },
  container: {
    backgroundColor: 'white',
    height: '100vh',
  },
  formContainer: { display: 'flex', justifyContent: 'center', gap: 10 },
};

export default styles;
