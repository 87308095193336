import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { attachCurrentToken } from 'config/api/interceptors';
import { useUser } from '@hooks';
import { useCompanyStore, useSegmentStore } from '@stores';
import { METADATA_SCHEMA } from '@validations';

/**
 * Auth provider and config
 * @param {React.ReactNode} children - The App
 * @returns
 */
const AuthProvider = ({ children }) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: process.env.REACT_APP_URL,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }}
  >
    {children}
  </Auth0Provider>
);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * Waits for auth0 to finish loading. If user session exists, then obtain the idToken and attach it to axios interceptor
 * @returns {boolean} - Whether the app is ready to load, considering if the user has loaded (if session exists) and if the token is attached and ready.
 */
export const useIsAuthReady = () => {
  const [tokenReady, setTokenReady] = useState(false);
  const { isLoading, user, getAccessTokenSilently, logout } = useUser();
  const { setSelectedCompany } = useCompanyStore();
  const { setSelectedSegment } = useSegmentStore();

  useEffect(() => {
    /**
     * Gets and sets the current token
     */
    async function getAndAttachCurrentToken() {
      try {
        const token = await getAccessTokenSilently();
        attachCurrentToken(token);
        setTokenReady(true);
      } catch (error) {
        console.error('An error occurred when trying to attach the token to instance.', error);
      }
    }

    /**
     * User has no roles
     */
    async function handleNoRolesAssigned() {
      await logout({
        logoutParams: { returnTo: `${process.env.REACT_APP_URL}/login?noRoles=true` },
      });
    }

    /**
     * User is a client missing required partner data
     */
    async function handleMissingPartnerDataAssigned() {
      await logout({
        logoutParams: { returnTo: `${process.env.REACT_APP_URL}/login?noPartnerData=true` },
      });
    }

    if (!isLoading && user) {
      /**
       * Here: everything needed between establishing the auth0 session and the start of the app.
       */
      if (!user.roles?.length) {
        return handleNoRolesAssigned();
      }

      if (!user.isInternal) {
        const isMetadataValid = METADATA_SCHEMA.isValidSync(user.metadata);

        if (!isMetadataValid) {
          return handleMissingPartnerDataAssigned();
        }

        if (user.metadata.companies.length === 1) {
          setSelectedCompany(user.metadata.companies[0]);
        }

        if (user.metadata.types.length === 1) {
          setSelectedSegment(user.metadata.types[0]);
        }
      }

      getAndAttachCurrentToken();
    }
  }, [getAccessTokenSilently, user, isLoading, logout, setSelectedCompany, setSelectedSegment]);

  if (isLoading) {
    return false;
  } else {
    return user ? tokenReady : true;
  }
};

export default AuthProvider;
