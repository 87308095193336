const styles = {
  paperHead: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  scheduleContainer: {
    display: 'flex',
    gap: '1.5rem',
    alignItems: 'center',
  },
  scheduleText: { display: 'flex', flexDirection: 'column', gap: '0.5rem', maxWidth: '18.9rem' },
  tabSelector: { pt: '1rem' },
  topContent: { display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' },
};
export default styles;
