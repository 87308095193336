import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Box, IconButton, useMediaQuery } from '@mui/material';
import { usePopover } from '@hooks';
import styles from './styles';
import { AccountPopover } from '../index';

/**
 * TopNav Component
 * @param  {object} props
 * @param {() => void | () => Promise<void>} props.onNavOpen
 * @returns {React.Component} Top navigation Component
 */
const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const accountPopover = usePopover();

  return (
    <AppBar sx={styles.navbar} position='sticky'>
      <Box sx={styles.navLinksContainer}>
        {!lgUp && (
          <IconButton onClick={onNavOpen}>
            <MenuIcon />
          </IconButton>
        )}
      </Box>
      <Box sx={styles.profileDropdownContainer}>
        <Avatar
          onClick={accountPopover.handleOpen}
          ref={accountPopover.anchorRef}
          sx={styles.avatar}
        />
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </AppBar>
  );
};

export default TopNav;

TopNav.propTypes = {
  onNavOpen: PropTypes.func,
};
