import { SxProps } from '@mui/material/styles';
import { NubiTheme } from '@theme';

interface Styles {
  container: SxProps<NubiTheme>;
  formBox: SxProps<NubiTheme>;
  buttonsBox: SxProps<NubiTheme>;
  clearFilter: Record<string, SxProps<NubiTheme>>;
}

const styles: Styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '1rem',
    flexDirection: { xs: 'column', sm: 'row' },
    transition: 'max-height 0.5s ease-out, overflow 0.5s ease-out',
  },
  formBox: { display: 'flex', gap: '0.5rem' },
  buttonsBox: { display: 'flex', gap: '0.5rem' },
  clearFilter: {
    button: { whiteSpace: 'nowrap', padding: '1rem', fontSize: '0.75rem' },
  },
};

export default styles;
