import useSWR from 'swr';

/**
 * Gets all customer groups
 */
const useCustomerGroups = (allowRequest = true) => {
  const props = useSWR(() => allowRequest && '/partner/customer/groups', {
    revalidateOnFocus: false,
  });

  return props;
};

export default useCustomerGroups;
