import PropTypes from 'prop-types';
import { Box, Card, Typography } from '@mui/material';
import styles from './styles';

/**
 * CardWithIcon component displays a card with an optional icon, title, subtitle, and footer.
 * @param {Object} props - The properties of the CardWithIcon component.
 * @param {string=} props.title - The title of the card.
 * @param {string} props.subtitle - The subtitle or additional information for the card.
 * @param {React.ReactNode=} props.footer - The footer content of the card.
 * @param {React.ReactNode} props.icon - The icon to be displayed on the card.
 * @param {React.ReactNode} props.children - The content to be displayed within the card body.
 * @param {CustomStyle | boolean} props.cardStyle - The custom styles to be applied to the card.
 * @returns {JSX.Element} - The CardWithIcon component.*/
function CardWithIcon({ title, subtitle, footer, icon, children, cardStyle = {} }) {
  return (
    <>
      <Card sx={[styles.cardContainer, cardStyle]}>
        <Box sx={styles.cardHeader}>
          <Box sx={styles.title}>
            {icon && icon}
            {title && <Typography variant='h1'>{title}</Typography>}
          </Box>
          <Box>{subtitle && <Typography>{subtitle}</Typography>}</Box>
        </Box>
        <Box>{children}</Box>
        {footer && footer}
      </Card>
    </>
  );
}
CardWithIcon.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.node,
  icon: PropTypes.node,
  cardStyle: PropTypes.object,
};

export default CardWithIcon;
