const styles = {
  container: {
    padding: '1.5rem',
    borderRadius: '1rem',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    boxShadow: '0px 4px 12px rgba(10, 16, 68, 0.08)',
  },
};

export default styles;
