interface ObjectWithWalletId {
  walletId: string;
}

/** Remove items with duplicate walletId   */
export default function filterDuplicateWalletIds<T extends ObjectWithWalletId = ObjectWithWalletId>(
  items: T[]
) {
  return items.filter(
    (itemA, index, array) => array.findIndex((itemB) => itemB.walletId === itemA.walletId) === index
  );
}
