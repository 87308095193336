import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Image from '@assets/img/login_side_image.jpg';
import styles from './styles';

/**
 * LoginSideImage
 */
const LoginSideImage = ({ children }) => {
  return (
    <Grid container sx={styles.container}>
      <Grid component='img' item md={6} sx={styles.leftImage} src={Image} alt='nubiz' />
      <Grid item sm={12} md={6} sx={styles.rightContent}>
        {children}
      </Grid>
    </Grid>
  );
};

LoginSideImage.propTypes = {
  children: PropTypes.node,
};

export default LoginSideImage;
