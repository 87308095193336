import useSWR from 'swr';

/**
 * Fetch the details of a specific transaction
 *
 * @param {string} id - The id of the transaction
 * @returns {object} - SWR props
 */
function useMovementDetails(id, acctUserId) {
  const props = useSWR(
    `/transactions/userMovementDetail/${id}?${new URLSearchParams({ acctUserId }).toString()}`
  );

  return props;
}

export default useMovementDetails;
