import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styles from './styles';

/**
 * FieldList component for rendering a list of fields.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {ReactNode} props.children - The content to be rendered inside the FieldList.
 * @returns {JSX.Element} - The rendered FieldList component.
 */
const FieldList = ({ children }) => {
  return <Box sx={styles.list}>{children}</Box>;
};

FieldList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldList;
