const styles = {
  paperHead: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  selectBox: { display: 'flex', width: '50%' },
  mainBox: { flexGrow: 1, py: 8 },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  titleSelectedCompany: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    maxWidth: '70%',
  },
  switchSectionButton: { whiteSpace: 'noWrap' },
};
export default styles;
