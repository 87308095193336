/**
 * Converts URLSearchParams to a plain object.
 *
 * @param {URLSearchParams} searchParams - The URLSearchParams object to convert.
 * @returns {Object} The plain object representation of the search parameters.
 */
export function paramsToObject(searchParams) {
  const searchObject = Object.fromEntries(searchParams.entries());
  return searchObject;
}

/**
 * Converts a plain object to URLSearchParams.
 *
 * @param {Object} object - The plain object to convert.
 * @returns {URLSearchParams} The URLSearchParams object representing the search parameters.
 */
export function objectToParams(object) {
  const searchParams = new URLSearchParams(object);
  return searchParams;
}
