const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: ({ palette }) => palette.primary.main,
  },
  spinner: { color: 'white' },
};

export default styles;
