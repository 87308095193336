import SelectCompanyCard from 'screens/dispersions/SelectCompanyCard';
import { FullWidthContent, MainMenuLayout } from '@layouts';
import AddSegment from './AddSegment';

/** Add segments screen */
const AddSegments = () => {
  return (
    <MainMenuLayout>
      <FullWidthContent>
        <SelectCompanyCard />
        <AddSegment />
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default AddSegments;
