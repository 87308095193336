import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { apiClient } from 'config/api';
import i18n from '@i18n';

interface AlertMessage {
  title: string;
  description: string;
  variant: string;
  details: string[];
}

interface Status {
  loading: boolean;
  alertMessage: AlertMessage;
  success: boolean;
  error: boolean;
}

const ERROR_MESSAGE_GENERIC: AlertMessage = {
  title: 'COMMON:ERRORS:GENERIC_ERROR_TITLE',
  description: 'COMMON:ERRORS:DEFAULT_ERROR',
  variant: 'error',
  details: [],
};

/**
 * Custom hook to create a segment for a company.
 * @returns {object} The current status and the function to create the segment.
 */
function useSegmentCreation() {
  const [status, setStatus] = useState<Status>({
    loading: false,
    alertMessage: ERROR_MESSAGE_GENERIC,
    success: false,
    error: false,
  });
  /**
   * Function to set the error state.
   * @param {boolean} error - The error state.
   */
  const setIsError = (error: boolean) => {
    setStatus((prevState) => ({ ...prevState, error }));
  };
  /**
   * Function to set the sucess state.
   * @param {boolean} success - The success state.
   */
  const setIsSuccess = (success: boolean) => {
    setStatus((prevState) => ({ ...prevState, success }));
  };

  /**
   * Generates an alert message based on the API response.
   * @param {AxiosResponse} response - The API response.
   * @returns {AlertMessage} The generated alert message.
   */
  const getAlertMessage = (response: AxiosResponse): AlertMessage => {
    const t = i18n.t;
    const { data } = response;
    const segmentCreated = data.segmentResult.success;
    const segmentDisplay =
      segmentCreated && data.segmentResult.description.name?.replace(/^\d+_/, '');
    const successDescriptionMessage = t(
      'CLIENTS:CREATE_CLIENT:ADD_SEGMENT_MODAL:SUCCESS_SEGMENT_CREATION',
      {
        segmentDisplay,
        number: data.segmentResult.description.number,
      }
    );
    if (response.status === 202) {
      const accumulatedErrors: string[] = [];

      for (const key in data) {
        if (data[key].success === false) {
          switch (key) {
            case 'segmentResult':
              accumulatedErrors.push('CLIENTS:CREATE_CLIENT:ERROR_DETAILS:GENERIC_SEGMENT_RESULT');
              break;
            case 's3Folders':
              accumulatedErrors.push('CLIENTS:CREATE_CLIENT:ERROR_DETAILS:GENERIC_S3_FOLDERS');
              break;
            case 'limit':
              accumulatedErrors.push('CLIENTS:CREATE_CLIENT:ADD_SEGMENT_MODAL:LIMITS_ERROR');
              break;
            case 'prospectAddress':
              accumulatedErrors.push('CLIENTS:CREATE_CLIENT:ERROR_DETAILS:GENERIC_PROSPECT_ADRESS');
              break;
            case 'customerSegmentCreate':
              accumulatedErrors.push(
                'CLIENTS:CREATE_CLIENT:ADD_SEGMENT_MODAL:CUSTOMER_SEGMENT_CREATE_ERROR'
              );
              break;
            default:
              break;
          }
        }
      }
      return {
        title: 'CLIENTS:CREATE_CLIENT:ADD_SEGMENT_MODAL:INCOMPLETE_PROCESS:TITLE',
        description: segmentCreated
          ? successDescriptionMessage
          : 'CLIENTS:CREATE_CLIENT:ADD_SEGMENT_MODAL:INCOMPLETE_PROCESS:DESCRIPTION',
        details: accumulatedErrors,
        variant: 'success',
      };
    } else {
      return {
        title: 'CLIENTS:CREATE_CLIENT:ADD_SEGMENT_MODAL:SUCCESS_TITLE',
        description: successDescriptionMessage,
        variant: 'success',
        details: [],
      };
    }
  };

  /**
   * Creates a new segment for the company.
   * @returns {Promise<void>}
   */
  const createSegment = async (companyId: string, segment: string): Promise<void> => {
    setStatus((prevState) => ({ ...prevState, loading: true }));

    try {
      const response = await apiClient.post(
        `/partner/customer/segment/${companyId}?segmentName=${segment}`
      );
      setIsSuccess(true);
      setStatus((prevState) => ({
        ...prevState,
        loading: false,
        alertMessage: getAlertMessage(response),
      }));
    } catch (error: unknown) {
      setIsError(true);
      let alertMessage = ERROR_MESSAGE_GENERIC;
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          alertMessage = {
            title: 'CLIENTS:CREATE_CLIENT:ADD_SEGMENT_MODAL:BAD_REQUEST_TITLE',
            description: 'CLIENTS:CREATE_CLIENT:ADD_SEGMENT_MODAL:BAD_REQUEST_DESCRIPTION',
            variant: 'error',
            details: [],
          };
          setStatus((prevState) => ({ ...prevState, loading: false, alertMessage }));
        }
      }
      setStatus((prevState) => ({ ...prevState, loading: false, alertMessage }));
    }
  };
  const { loading: isLoading, alertMessage, error: isError, success: isSuccess } = status;
  return { isLoading, alertMessage, createSegment, setIsError, setIsSuccess, isError, isSuccess };
}

export default useSegmentCreation;
