import { useTranslation } from 'react-i18next';
import SelectCustomerProduct from 'components/organisms/SelectCustomerProduct';
import { useFormik } from 'formik';
import { CustomerProduct } from 'helpers/hooks/useCustomerProduct';
import { Alert as MUIAlert, Box, CircularProgress, Typography } from '@mui/material';
import SelectCompanyCard from 'screens/dispersions/SelectCompanyCard';
import { Alert, Button } from '@atoms';
import {
  useCompanyNeedsRegistrationStatusInPrisma,
  useAssignPartnerProducts,
  useSelectedCompanyDetails,
} from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { AddCustomerProductSchema, addCustomerProductSchema } from './addCustomerProduct.schema';
import RegisterCompanyInPrisma from './RegisterCompanyInPrisma';
import registerCompanyInPrisma from './services/registerCompanyInPrisma';
import styles from './styles';

const REGISTER_COMPANY_INITIAL_VALUES = {
  companyType: undefined,
  contactEmail: undefined,
  fantasyName: undefined,
};

/**
 * AddCustomerProduct component is responsible for rendering the interface to select a company and assign customer products to a company.
 * It manages the state of the selected company and customer products, and handles the submission of the selected customer products.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
function AddCustomerProduct(): JSX.Element {
  const { t } = useTranslation();
  const { data: selectedCompany } = useSelectedCompanyDetails();

  const formikProps = useFormik<AddCustomerProductSchema>({
    initialValues: {
      companyId: selectedCompany?.id,
      customerProducts: [],
      registerCompanyInPrisma: null,
    },
    validateOnChange: true,
    validationSchema: addCustomerProductSchema,
    enableReinitialize: true,
    /** Handles the submit event for assigning `the selected products to the selected company.
     * Resets the products state after submission.
     */
    onSubmit: async function (data, formikHelpers) {
      const customerProductsIds = data.customerProducts.map((product) => product.id);

      if (
        !hasAtLeastOneProductByPrisma ||
        (hasAtLeastOneProductByPrisma &&
          !isCheckPrismaStatusLoading &&
          !companyNeedsToRegisterInPrismaError &&
          !companyNeedsToRegisterInPrisma)
      ) {
        await assignProduct(selectedCompany.id, {
          products: customerProductsIds,
        });
        formikHelpers.resetForm();
        return;
      }

      if (!data.registerCompanyInPrisma) {
        return formikHelpers.setFieldValue(
          'registerCompanyInPrisma',
          REGISTER_COMPANY_INITIAL_VALUES
        );
      }

      await registerCompanyInPrisma(selectedCompany.id, {
        email: data.registerCompanyInPrisma.contactEmail,
        legalName: selectedCompany.description,
        tradeName: data.registerCompanyInPrisma.fantasyName,
        identificationType: data.registerCompanyInPrisma.companyType,
      });

      await assignProduct(selectedCompany.id, {
        products: customerProductsIds,
      });
      formikHelpers.resetForm();
    },
    onReset: (values, formikHelpers) => {
      formikHelpers.setFieldValue('customerProducts', []);
      resetCompanyNeedsToRegisterInPrisma();
    },
  });
  const hasAtLeastOneProductByPrisma = formikProps.values.customerProducts.some(
    (customerProduct) => customerProduct.provider === 'PRISMA'
  );

  const {
    data: companyNeedsToRegisterInPrisma,
    isLoading: isCheckPrismaStatusLoading,
    error: companyNeedsToRegisterInPrismaError,
    reset: resetCompanyNeedsToRegisterInPrisma,
  } = useCompanyNeedsRegistrationStatusInPrisma(hasAtLeastOneProductByPrisma);

  const {
    isLoading,
    isSuccess,
    isError,
    isFailed,
    alertMessage,
    setIsError,
    setIsSuccess,
    setIsFailed,
    assignProduct,
  } = useAssignPartnerProducts();
  const completeForm = selectedCompany && formikProps.values.customerProducts.length > 0;
  const disableButton =
    !completeForm ||
    (hasAtLeastOneProductByPrisma && Boolean(companyNeedsToRegisterInPrismaError)) ||
    (hasAtLeastOneProductByPrisma &&
      companyNeedsToRegisterInPrisma &&
      (Boolean(formikProps.errors.registerCompanyInPrisma) ||
        !formikProps.values.registerCompanyInPrisma));

  /**
   * Handles the change event for the SelectProduct component, updating the products state.
   */
  function handleCustomerProductsChange(customerProductsSelected: CustomerProduct[]) {
    formikProps.setFieldValue('customerProducts', customerProductsSelected);
  }

  return (
    <>
      <MainMenuLayout>
        <FullWidthContent>
          <SelectCompanyCard />
          {formikProps.isSubmitting ? (
            <Box sx={styles.spinnerBox}>
              <CircularProgress />
            </Box>
          ) : selectedCompany ? (
            <BoxContainer>
              <Typography variant='h4'>{t('COMMON:ADD_PRODUCT')}</Typography>

              <SelectCustomerProduct
                customerProducts={formikProps.values.customerProducts}
                onChange={handleCustomerProductsChange}
              />
              <Box>
                {companyNeedsToRegisterInPrisma ? (
                  <RegisterCompanyInPrisma formik={formikProps} />
                ) : null}
              </Box>

              {hasAtLeastOneProductByPrisma && Boolean(companyNeedsToRegisterInPrismaError) && (
                <MUIAlert severity='error'>
                  {t('CLIENTS:ERRORS.REGISTER_COMPANY_IN_PRISMA')}
                </MUIAlert>
              )}
              <Box sx={styles.buttonBox}>
                <Button
                  customStyle={styles.button}
                  color='primary'
                  disabled={disableButton}
                  isLoading={isLoading || isCheckPrismaStatusLoading}
                  text={t('COMMON:ADD')}
                  type='submit'
                  variant='contained'
                  onClick={formikProps.handleSubmit}
                />
              </Box>
            </BoxContainer>
          ) : null}
        </FullWidthContent>
        <Alert
          description={alertMessage.description}
          details={alertMessage.details}
          modalVisible={isError || isFailed || isSuccess}
          title={alertMessage.title}
          variant={alertMessage.variant}
          handleClose={() => {
            setIsError(false);
            setIsFailed(false);
            setIsSuccess(false);
          }}
        />
      </MainMenuLayout>
    </>
  );
}

export default AddCustomerProduct;
